import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { deleteDocumentById, deleteMultipleDocuments, getDocumentsById, syncDocumentById } from './functions'
import { AxiosError } from 'axios'
import { DocumentDeleteSyncPromise, DocumentsList } from './types'
import { toast } from 'react-toastify'
import i18n from '../../i18n'
import { invalidateQueryInDocumentTableList, invalidateQueryInTableDocuments } from '../helpers'
import { DeleteMultipleResponse } from '../globalTypes'

const documents = {
    GetDocuments: {
        useQuery: (
            id: number,
            page: number,
            limit: number,
            module: string,
            collection: string,
            refreshToken: string,
            options?: UseQueryOptions<DocumentsList, AxiosError>
        ) =>
            useQuery({
                queryKey: [id, page, limit, module, collection, refreshToken],
                queryFn: () => getDocumentsById(id, page, limit, module, collection),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    DeleteDocument: {
        useMutation: (
            invalidateData: { current: { id: number; module: string }[] },
            limit: number,
            collection: string,
            options?: UseMutationOptions<DocumentDeleteSyncPromise, AxiosError>
        ) =>
            useMutation({
                ...options,
                mutationFn: (id) => deleteDocumentById(Number(id)),
                onMutate: (id) => {
                    toast.loading(i18n.t('toast.loading.delete') as string, { toastId: Number(id) })
                },
                onSuccess: (data) => {
                    const document = data as unknown as DocumentDeleteSyncPromise

                    if (document.isError === true) {
                        toast.update(document.id, {
                            render: i18n.t('toast.update.delete.error') as string,
                            type: 'error',
                            autoClose: 3000,
                            isLoading: false,
                        })
                    } else {
                        toast.update(Number(document.id), {
                            render: i18n.t('toast.update.delete.success') as string,
                            type: 'success',
                            autoClose: 3000,
                            isLoading: false,
                        })
                    }

                    invalidateQueryInTableDocuments(invalidateData.current, limit, collection)
                    invalidateQueryInDocumentTableList()
                },
            }),
    },
    DeleteMultipleDocuments: {
        useMutation: (
            invalidateData: { current: { id: number; module: string }[] },
            limit: number,
            collection: string,
            options?: UseMutationOptions<DeleteMultipleResponse, AxiosError, number[]>
        ) =>
            useMutation({
                ...options,
                mutationFn: (arrayId) => deleteMultipleDocuments(arrayId),
                onMutate: (idsArray) => {
                    const requestsToBeDeletedCount = idsArray.length
                    toast.loading(i18n.t('toast.loading.delete.document.number', { requestsToBeDeletedCount }) as string, {
                        toastId: idsArray[0].toString(),
                    })
                },
                onSuccess: (data, idsArray) => {
                    const requestsToBeDeletedCount = idsArray?.length
                    const failedCount = data?.notFoundIds?.length
                    const successCount = data?.successfulDelete?.length

                    if (failedCount > 0) {
                        toast.update(idsArray[0].toString(), {
                            render: i18n.t('toast.loading.delete.document.number.error', { failedCount, requestsToBeDeletedCount }) as string,
                            type: 'error',
                            isLoading: false,
                            autoClose: 3000,
                        })
                    }

                    if (successCount > 0) {
                        toast.update(idsArray[0].toString(), {
                            render: i18n.t('toast.loading.delete.document.number.success', { successCount, requestsToBeDeletedCount }) as string,
                            type: 'success',
                            isLoading: false,
                            autoClose: 3000,
                        })
                        invalidateQueryInTableDocuments(invalidateData.current, limit, collection)
                        invalidateQueryInDocumentTableList()
                    }
                },
                onError: (error, idsArray) => {
                    toast.update(idsArray[0].toString(), {
                        render: i18n.t('toast.update.delete.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            }),
    },
    SyncDocument: {
        useMutation: (
            invalidateData: { current: { id: number; module: string }[] },
            limit: number,
            collection: string,
            options?: UseMutationOptions<DocumentDeleteSyncPromise, AxiosError>
        ) =>
            useMutation({
                ...options,
                mutationFn: (id) => syncDocumentById(Number(id)),
                onMutate: (id) => {
                    toast.loading(i18n.t('toast.loading') as string, { toastId: Number(id) })
                },
                onSuccess: (data) => {
                    toast.update(Number(data?.id), {
                        render: i18n.t('toast.update.success') as string,
                        type: 'success',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    invalidateQueryInDocumentTableList()
                    invalidateQueryInTableDocuments(invalidateData.current, limit, collection)
                },
                onError: () =>
                    toast.update(invalidateData.current[0].id, {
                        render: i18n.t('toast.update.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    }),
            }),
    },
}

export default documents
