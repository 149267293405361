import { FormikProvider, useFormik } from 'formik'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { handleImagesPayload, handleShouldImagesUpdate } from '../../api/images/helpers'
import images from '../../api/images/hooks'
import projects from '../../api/projects/hooks'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import DocumentFormTab from '../../Components/DocumentFormTab'
import GroundPlansFormTab from '../../Components/GroundPlanFormTab'
import {
    useMapFormConfig,
    useProjectDescriptionFormConfig,
    useProjectGeneralFormConfig,
    useProjectGeneralFormSelectOptions,
    useRealEstateMaterials3DVideoFormConfig,
} from '../../Components/Hooks/FormHooks'
import { useProjectFormTabs } from '../../Components/Hooks/useProjectTabs'
import MapFormTab, { MapFormTabMain, MapFormTabSide } from '../../Components/MapFormTab/MapFormTab'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import PhotosFormTab from '../../Components/PhotosFormTab'
import ProjectFormDescriptionTab, {
    ProjectFormDescriptionTabMain,
    ProjectFormDescriptionTabSide,
} from '../../Components/ProjectFormDescriptionTab/ProjectFormDescriptionTab'
import ProjectFormGeneralTab, {
    ProjectFormGeneralTabMain,
    ProjectFormGeneralTabSide,
} from '../../Components/ProjectFormGeneralTab/ProjectFormGeneralTab'
import PropertyFormMaterialsTab from '../../Components/PropertyFormMaterialsTab/PropertyFormMaterialsTab'
import PropertyFormMaterialsVirtualWalkVideoTab, {
    PropertyFormMaterialsVirtualWalkVideoTabMain,
    PropertyFormMaterialsVirtualWalkVideoTabSide,
} from '../../Components/PropertyFormMaterialsTab/Tabs/PropertyFormMaterialsVirtualWalkVideoTab/PropertyFormMaterialsVirtualWalkVideoTab'
import { changeTranslationObject } from '../../utils/general'

const ProjectEdit = () => {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const { t } = useTranslation()
    const module = 'project'

    const [activeTab, setActiveTab] = useState(null)
    const [activeMaterialsTab, setActiveMaterialsTab] = useState(null)
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const currentLang = localStorage.getItem('i18nextLng')
    const refreshToken = localStorage.getItem('refresh_token')

    const { isLoading: fetchingProject, data: projectData } = projects.GetProject.useQuery(id, refreshToken)
    const { mutate: updateProject } = projects.CreateUpdateProject.useMutation()

    const imagesParams = { module, parent: id, refreshToken }
    const { isLoading: fetchingPhotos, data: photosData } = images.GetImages.useQuery({ ...imagesParams, collection: 'photo' })
    const { isLoading: fetchingGroundPlans, data: groundPlansData } = images.GetImages.useQuery({ ...imagesParams, collection: 'ground_plan' })
    const { mutate: updateImages } = images.UpdateImages.useMutation()

    const project = !fetchingProject ? projectData?.item : null
    const photos = !fetchingPhotos ? photosData : []
    const groundPlans = !fetchingGroundPlans ? groundPlansData : []

    const entityData = {
        parent: id,
        city: project?.city,
        widerArea: project?.widerArea,
    }

    const tabs = useProjectFormTabs(true)
    const { generalInputs, generalValidationConfig } = useProjectGeneralFormConfig(project)
    const { descriptionInputs, descriptionValidationConfig } = useProjectDescriptionFormConfig(project)
    const { mapInputs, mapValidationConfig } = useMapFormConfig(project)
    const { materials3DVideoInputs, materials3DVideoValidationConfig } = useRealEstateMaterials3DVideoFormConfig(project)
    const formSelectOptions = useProjectGeneralFormSelectOptions(project)

    useEffect(() => {
        if (tabs.length > 0) {
            if (location?.state?.activeTab) {
                setActiveTab(location?.state?.activeTab)
            } else {
                setActiveTab(tabs[0].name)
            }
        }
    }, [tabs, location])

    useEffect(() => {
        if (activeTab === 'materials') {
            setActiveMaterialsTab(tabs.find((x) => x.name === 'materials')?.children?.[0].name)
        }
    }, [activeTab, tabs])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig.initialValues ?? {},
            description: descriptionValidationConfig.initialValues ?? {},
            map: mapValidationConfig.initialValues ?? {},
            virtualWalkVideo: materials3DVideoValidationConfig.initialValues ?? {},
        },
        validationSchema: Yup.object({
            general: generalValidationConfig.validationSchema ?? Yup.object({}),
            description: descriptionValidationConfig.validationSchema ?? Yup.object({}),
            map: mapValidationConfig.validationSchema ?? Yup.object({}),
            virtualWalkVideo: materials3DVideoValidationConfig.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values, { setSubmitting }) => {
            const generalValues = generalValidationConfig.prepareModelForSubmit(values.general)
            const translations = changeTranslationObject(
                values.description,
                'project_title',
                'project_description',
                'project_offer_description'
            )

            const submitValues = {
                ...generalValues,
                ...values.map,
                ...values.virtualWalkVideo,
                translations,
            }

            if (!isEqual(formik.initialValues, formik.values)) {
                updateProject({ data: submitValues, isEdit: true })
            }

            const images = handleImagesPayload(module, id)
            const shouldImagesUpdate = handleShouldImagesUpdate(images)

            shouldImagesUpdate && updateImages(images)
            setSubmitting(false)
        },
    })

    const { isValid, isSubmitting, isValidating } = formik

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(`/projects/${id}`)} />

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    const handleMaterialsTabChange = (tab) => {
        if (activeMaterialsTab !== tab) {
            setActiveMaterialsTab(tab)
        }
    }

    if (fetchingProject) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={formik}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            formik.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title={`${t('page.editProject')} ${project?.translations[currentLang]?.projectTitle}`}>
                            {renderFormButtons()}
                        </PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <ProjectFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={formik}
                                    selectOptions={formSelectOptions}
                                    module={location}
                                >
                                    <ProjectFormGeneralTabSide />
                                    <ProjectFormGeneralTabMain />
                                </ProjectFormGeneralTab>
                            </TabPane>
                            <TabPane tabId="materials">
                                <PropertyFormMaterialsTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    activeMaterialsTab={activeMaterialsTab}
                                    materialsTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                    handleMaterialsTabChange={handleMaterialsTabChange}
                                    propertyId={id}
                                >
                                    <TabContent activeTab={activeMaterialsTab} className="text-muted">
                                        <TabPane tabId="photos">
                                            <PhotosFormTab
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                handleTabChange={handleTabChange}
                                                activeChildTab={activeMaterialsTab}
                                                childTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                                handleChildTabChange={handleMaterialsTabChange}
                                                module={module}
                                                isFetching={fetchingPhotos}
                                                isSubmitting={isSubmitting}
                                                files={photos}
                                                entityData={entityData}
                                                url={`${module}/photo`}
                                                collection={'photo'}
                                            />
                                        </TabPane>
                                        <TabPane tabId="groundPlans">
                                            <GroundPlansFormTab
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                handleTabChange={handleTabChange}
                                                activeChildTab={activeMaterialsTab}
                                                childTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                                handleChildTabChange={handleMaterialsTabChange}
                                                module={module}
                                                isFetching={fetchingGroundPlans}
                                                isSubmitting={isSubmitting}
                                                files={groundPlans}
                                                entityData={entityData}
                                                url={`${module}/ground_plan`}
                                                collection={'ground_plan'}
                                            />
                                        </TabPane>
                                        <TabPane tabId="3dVideo">
                                            <PropertyFormMaterialsVirtualWalkVideoTab
                                                inputs={materials3DVideoInputs}
                                                subform="virtualWalkVideo"
                                                formik={formik}
                                            >
                                                <PropertyFormMaterialsVirtualWalkVideoTabSide />
                                                <PropertyFormMaterialsVirtualWalkVideoTabMain />
                                            </PropertyFormMaterialsVirtualWalkVideoTab>
                                        </TabPane>
                                    </TabContent>
                                </PropertyFormMaterialsTab>
                            </TabPane>
                            <TabPane tabId="map">
                                <MapFormTab tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} inputs={mapInputs} formik={formik}>
                                    <MapFormTabSide />
                                    <MapFormTabMain />
                                </MapFormTab>
                            </TabPane>
                            <TabPane tabId="description">
                                <ProjectFormDescriptionTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={descriptionInputs}
                                    subform="description"
                                    formik={formik}
                                >
                                    <ProjectFormDescriptionTabSide />
                                    <ProjectFormDescriptionTabMain />
                                </ProjectFormDescriptionTab>
                            </TabPane>
                        </TabContent>
                    </Form>

                    {/* documents are saved via dropzone */}
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <DocumentFormTab
                                tabs={tabs}
                                activeTab={activeTab}
                                handleTabChange={handleTabChange}
                                documentModule={module}
                                parentId={id}
                            />
                        </TabPane>
                    )}
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={formik}
                    inputs={[generalInputs, descriptionInputs, mapInputs, materials3DVideoInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default ProjectEdit