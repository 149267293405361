import QueryString from 'qs'
import { put, takeLatest, select } from 'redux-saga/effects'
import { IS_ERSTE } from '../../common/constants'
import { apiRequest } from '../api'
import {
    fetchPropertyValuesAccess,
    fetchPropertyValuesAccessFailure,
    fetchPropertyValuesAccessRequest,
    fetchPropertyValuesAccessSuccess,
    fetchPropertyValuesAgencyCommission,
    fetchPropertyValuesAgencyCommissionFailure,
    fetchPropertyValuesAgencyCommissionRequest,
    fetchPropertyValuesAgencyCommissionSuccess,
    fetchPropertyValuesCadastralMunicipality,
    fetchPropertyValuesCadastralMunicipalityFailure,
    fetchPropertyValuesCadastralMunicipalityRequest,
    fetchPropertyValuesCadastralMunicipalitySuccess,
    fetchPropertyValuesCondition,
    fetchPropertyValuesConditionFailure,
    fetchPropertyValuesConditionRequest,
    fetchPropertyValuesConditionSuccess,
    fetchPropertyValuesDataSource,
    fetchPropertyValuesDataSourceFailure,
    fetchPropertyValuesDataSourceRequest,
    fetchPropertyValuesDataSourceSuccess,
    fetchPropertyValuesDescriptions,
    fetchPropertyValuesDescriptionsFailure,
    fetchPropertyValuesDescriptionsRequest,
    fetchPropertyValuesDescriptionsSuccess,
    fetchPropertyValuesEnergyEfficiency,
    fetchPropertyValuesEnergyEfficiencyFailure,
    fetchPropertyValuesEnergyEfficiencyRequest,
    fetchPropertyValuesEnergyEfficiencySuccess,
    fetchPropertyValuesEquipment,
    fetchPropertyValuesEquipmentFailure,
    fetchPropertyValuesEquipmentRequest,
    fetchPropertyValuesEquipmentSuccess,
    fetchPropertyValuesFloor,
    fetchPropertyValuesFloorFailure,
    fetchPropertyValuesFloorPosition,
    fetchPropertyValuesFloorPositionFailure,
    fetchPropertyValuesFloorPositionRequest,
    fetchPropertyValuesFloorPositionSuccess,
    fetchPropertyValuesFloorRequest,
    fetchPropertyValuesFloorSuccess,
    fetchPropertyValuesHeating,
    fetchPropertyValuesHeatingFailure,
    fetchPropertyValuesHeatingRequest,
    fetchPropertyValuesHeatingSuccess,
    fetchPropertyValuesHouseType,
    fetchPropertyValuesHouseTypeFailure,
    fetchPropertyValuesHouseTypeRequest,
    fetchPropertyValuesHouseTypeSuccess,
    fetchPropertyValuesLandRegistryStatus,
    fetchPropertyValuesLandRegistryStatusFailure,
    fetchPropertyValuesLandRegistryStatusRequest,
    fetchPropertyValuesLandRegistryStatusSuccess,
    fetchPropertyValuesLandUsage,
    fetchPropertyValuesLandUsageFailure,
    fetchPropertyValuesLandUsageRequest,
    fetchPropertyValuesLandUsageSuccess,
    fetchPropertyValuesMainRegister,
    fetchPropertyValuesMainRegisterDepartment,
    fetchPropertyValuesMainRegisterDepartmentFailure,
    fetchPropertyValuesMainRegisterDepartmentRequest,
    fetchPropertyValuesMainRegisterDepartmentSuccess,
    fetchPropertyValuesMainRegisterFailure,
    fetchPropertyValuesMainRegisterRequest,
    fetchPropertyValuesMainRegisterSuccess,
    fetchPropertyValuesOrientation,
    fetchPropertyValuesOrientationFailure,
    fetchPropertyValuesOrientationRequest,
    fetchPropertyValuesOrientationSuccess,
    fetchPropertyValuesOwnershipAcquisition,
    fetchPropertyValuesOwnershipAcquisitionFailure,
    fetchPropertyValuesOwnershipAcquisitionProof,
    fetchPropertyValuesOwnershipAcquisitionProofFailure,
    fetchPropertyValuesOwnershipAcquisitionProofRequest,
    fetchPropertyValuesOwnershipAcquisitionProofSuccess,
    fetchPropertyValuesOwnershipAcquisitionRequest,
    fetchPropertyValuesOwnershipAcquisitionSuccess,
    fetchPropertyValuesPermit,
    fetchPropertyValuesPermitFailure,
    fetchPropertyValuesPermitRequest,
    fetchPropertyValuesPermitSuccess,
    fetchPropertyValuesPosition,
    fetchPropertyValuesPositionFailure,
    fetchPropertyValuesPositionRequest,
    fetchPropertyValuesPositionSuccess,
    fetchPropertyValuesPrimiaryUsage,
    fetchPropertyValuesPrimiaryUsageFailure,
    fetchPropertyValuesPrimiaryUsageRequest,
    fetchPropertyValuesPrimiaryUsageSuccess,
    fetchPropertyValuesPropertyTransactionRiskDegree,
    fetchPropertyValuesPropertyTransactionRiskDegreeFailure,
    fetchPropertyValuesPropertyTransactionRiskDegreeRequest,
    fetchPropertyValuesPropertyTransactionRiskDegreeSuccess,
    fetchPropertyValuesPublicTransportation,
    fetchPropertyValuesPublicTransportationFailure,
    fetchPropertyValuesPublicTransportationRequest,
    fetchPropertyValuesPublicTransportationSuccess,
    fetchPropertyValuesPuk,
    fetchPropertyValuesPukDepartment,
    fetchPropertyValuesPukDepartmentFailure,
    fetchPropertyValuesPukDepartmentRequest,
    fetchPropertyValuesPukDepartmentSuccess,
    fetchPropertyValuesPukFailure,
    fetchPropertyValuesPukRequest,
    fetchPropertyValuesPukSuccess,
    fetchPropertyValuesRegistryDepartmentKpu,
    fetchPropertyValuesRegistryDepartmentKpuFailure,
    fetchPropertyValuesRegistryDepartmentKpuRequest,
    fetchPropertyValuesRegistryDepartmentKpuSubinsert,
    fetchPropertyValuesRegistryDepartmentKpuSubinsertFailure,
    fetchPropertyValuesRegistryDepartmentKpuSubinsertRequest,
    fetchPropertyValuesRegistryDepartmentKpuSubinsertSuccess,
    fetchPropertyValuesRegistryDepartmentKpuSuccess,
    fetchPropertyValuesRoomsNo,
    fetchPropertyValuesRoomsNoFailure,
    fetchPropertyValuesRoomsNoRequest,
    fetchPropertyValuesRoomsNoSuccess,
    fetchPropertyValuesRoomsType,
    fetchPropertyValuesRoomsTypeFailure,
    fetchPropertyValuesRoomsTypeRequest,
    fetchPropertyValuesRoomsTypeSuccess,
    fetchPropertyValuesSpace,
    fetchPropertyValuesSpaceFailure,
    fetchPropertyValuesSpaceRequest,
    fetchPropertyValuesSpaceSuccess,
    fetchPropertyValuesSpecialFeature,
    fetchPropertyValuesSpecialFeatureFailure,
    fetchPropertyValuesSpecialFeatureRequest,
    fetchPropertyValuesSpecialFeatureSuccess,
    fetchPropertyValuesStatus,
    fetchPropertyValuesStatusFailure,
    fetchPropertyValuesStatusRequest,
    fetchPropertyValuesStatusSuccess,
    fetchPropertyValuesSupplySource,
    fetchPropertyValuesSupplySourceFailure,
    fetchPropertyValuesSupplySourceRequest,
    fetchPropertyValuesSupplySourceSuccess,
    fetchPropertyValuesUtility,
    fetchPropertyValuesUtilityFailure,
    fetchPropertyValuesUtilityRequest,
    fetchPropertyValuesUtilitySuccess,
    fetchPropertyValuesWindowsDoors,
    fetchPropertyValuesWindowsDoorsFailure,
    fetchPropertyValuesWindowsDoorsRequest,
    fetchPropertyValuesWindowsDoorsSuccess,
} from './actions'
import { selectApplicationMarket } from '../applicationSettings'

export function* fetchPropertyValuesPositionSaga() {
    yield put(fetchPropertyValuesPositionRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyPosition', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesPositionSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPositionFailure(error))
    }
}

export function* fetchPropertyValuesRoomsTypeSaga() {
    if (!IS_ERSTE) return

    yield put(fetchPropertyValuesRoomsTypeRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyRoomsType', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesRoomsTypeSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesRoomsTypeFailure(error))
    }
}

export function* fetchPropertyValuesHouseTypeSaga() {
    yield put(fetchPropertyValuesHouseTypeRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyHouseType', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesHouseTypeSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesHouseTypeFailure(error))
    }
}

export function* fetchPropertyValuesRoomsNoSaga() {
    yield put(fetchPropertyValuesRoomsNoRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyRoomsNo', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesRoomsNoSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesRoomsNoFailure(error))
    }
}

export function* fetchPropertyValuesFloorSaga({ payload }) {
    yield put(fetchPropertyValuesFloorRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyFloor', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesFloorSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesFloorFailure(error))
    }
}

export function* fetchPropertyValuesFloorPositionSaga() {
    yield put(fetchPropertyValuesFloorPositionRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyFloorPosition', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesFloorPositionSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesFloorPositionFailure(error))
    }
}

export function* fetchPropertyValuesOrientationSaga() {
    yield put(fetchPropertyValuesOrientationRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyOrientation', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesOrientationSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesOrientationFailure(error))
    }
}

export function* fetchPropertyValuesHeatingSaga({ payload }) {
    yield put(fetchPropertyValuesHeatingRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyHeating', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesHeatingSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesHeatingFailure(error))
    }
}

export function* fetchPropertyValuesConditionSaga({ payload }) {
    yield put(fetchPropertyValuesConditionRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyCondition', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesConditionSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesConditionFailure(error))
    }
}

export function* fetchPropertyValuesWindowsDoorsSaga() {
    yield put(fetchPropertyValuesWindowsDoorsRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyWindowsDoors', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesWindowsDoorsSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesWindowsDoorsFailure(error))
    }
}

export function* fetchPropertyValuesPublicTransportationSaga() {
    yield put(fetchPropertyValuesPublicTransportationRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyPublicTransportation', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesPublicTransportationSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPublicTransportationFailure(error))
    }
}

export function* fetchPropertyValuesSpecialFeatureSaga() {
    yield put(fetchPropertyValuesSpecialFeatureRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertySpecialFeature', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesSpecialFeatureSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesSpecialFeatureFailure(error))
    }
}

export function* fetchPropertyValuesUtilitySaga({ payload }) {
    yield put(fetchPropertyValuesUtilityRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyUtility', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesUtilitySuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesUtilityFailure(error))
    }
}

export function* fetchPropertyValuesEquipmentSaga({ payload }) {
    yield put(fetchPropertyValuesEquipmentRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyEquipment', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesEquipmentSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesEquipmentFailure(error))
    }
}

export function* fetchPropertyValuesSpaceSaga({ payload }) {
    yield put(fetchPropertyValuesSpaceRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertySpace', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesSpaceSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesSpaceFailure(error))
    }
}

export function* fetchPropertyValuesEnergyEfficiencySaga() {
    yield put(fetchPropertyValuesEnergyEfficiencyRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyEnergyEfficiency', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesEnergyEfficiencySuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesEnergyEfficiencyFailure(error))
    }
}

export function* fetchPropertyValuesAccessSaga() {
    yield put(fetchPropertyValuesAccessRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyAccess', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesAccessSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesAccessFailure(error))
    }
}

export function* fetchPropertyValuesPermitSaga({ payload }) {
    yield put(fetchPropertyValuesPermitRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyPermit', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesPermitSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPermitFailure(error))
    }
}

export function* fetchPropertyValuesAgencyCommissionSaga({ payload }) {
    yield put(fetchPropertyValuesAgencyCommissionRequest())
    try {
        const response = yield apiRequest('v1/property/values/agencyCommission', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesAgencyCommissionSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesAgencyCommissionFailure(error))
    }
}

export function* fetchPropertyValuesStatusSaga({ payload }) {
    yield put(fetchPropertyValuesStatusRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyStatus', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesStatusSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesStatusFailure(error))
    }
}

export function* fetchPropertyValuesPropertyTransactionRiskDegreeSaga() {
    yield put(fetchPropertyValuesPropertyTransactionRiskDegreeRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyTransactionRiskDegree', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesPropertyTransactionRiskDegreeSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPropertyTransactionRiskDegreeFailure(error))
    }
}

export function* fetchPropertyValuesMainRegisterSaga() {
    yield put(fetchPropertyValuesMainRegisterRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyMainRegister', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesMainRegisterSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesMainRegisterFailure(error))
    }
}

export function* fetchPropertyValuesDescriptionsSaga({ payload }) {
    yield put(fetchPropertyValuesDescriptionsRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyDescriptions', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesDescriptionsSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesDescriptionsFailure(error))
    }
}

export function* fetchPropertyValuesMainRegisterDepartmentSaga({ payload }) {
    yield put(fetchPropertyValuesMainRegisterDepartmentRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertyMainRegisterDepartment?filter[propertyMainRegister]=${payload}`, {
            method: 'GET',
        })
        yield put(fetchPropertyValuesMainRegisterDepartmentSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesMainRegisterDepartmentFailure(error))
    }
}

export function* fetchPropertyValuesPukSaga() {
    yield put(fetchPropertyValuesPukRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyPuk', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesPukSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPukFailure(error))
    }
}

export function* fetchPropertyValuesPukDepartmentSaga() {
    yield put(fetchPropertyValuesPukDepartmentRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyPukDepartment', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesPukDepartmentSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPukDepartmentFailure(error))
    }
}

export function* fetchPropertyValuesRegistryDepartmentKpuSaga() {
    yield put(fetchPropertyValuesRegistryDepartmentKpuRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyRegistryDepartmentKpu', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesRegistryDepartmentKpuSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesRegistryDepartmentKpuFailure(error))
    }
}

export function* fetchPropertyValuesRegistryDepartmentKpuSubinsertSaga({ payload }) {
    // console.log(payload)
    yield put(fetchPropertyValuesRegistryDepartmentKpuSubinsertRequest())
    try {
        const response = yield apiRequest(
            `v1/property/values/propertyRegistryDepartmentKpuSubinsert?filter[propertyRegistryDepartmentKpu]=${payload}`,
            {
                method: 'GET',
            }
        )
        yield put(fetchPropertyValuesRegistryDepartmentKpuSubinsertSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesRegistryDepartmentKpuSubinsertFailure(error))
    }
}

export function* fetchPropertyValuesDataSourceSaga() {
    // console.log(payload)
    yield put(fetchPropertyValuesDataSourceRequest())
    try {
        const response = yield apiRequest(`v1/property/values/dataSource`, {
            method: 'GET',
        })
        yield put(fetchPropertyValuesDataSourceSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesDataSourceFailure(error))
    }
}

export function* fetchPropertyValuesLandRegistryStatusSaga() {
    // console.log(payload)
    yield put(fetchPropertyValuesLandRegistryStatusRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertyLandRegistryStatus`, {
            method: 'GET',
        })
        yield put(fetchPropertyValuesLandRegistryStatusSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesLandRegistryStatusFailure(error))
    }
}

export function* fetchPropertyValuesOwnershipAcquisitionSaga() {
    // console.log(payload)
    yield put(fetchPropertyValuesOwnershipAcquisitionRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertyOwnershipAcquisition`, {
            method: 'GET',
        })
        yield put(fetchPropertyValuesOwnershipAcquisitionSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesOwnershipAcquisitionFailure(error))
    }
}

export function* fetchPropertyValuesOwnershipAcquisitionProofSaga() {
    // console.log(payload)
    yield put(fetchPropertyValuesOwnershipAcquisitionProofRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertyOwnershipAcquisitionProof`, {
            method: 'GET',
        })
        yield put(fetchPropertyValuesOwnershipAcquisitionProofSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesOwnershipAcquisitionProofFailure(error))
    }
}

export function* fetchPropertyValuesPrimaryUsageSaga({ payload }) {
    // console.log(payload)
    yield put(fetchPropertyValuesPrimiaryUsageRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertyPrimaryUsage`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesPrimiaryUsageSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesPrimiaryUsageFailure(error))
    }
}

export function* fetchPropertyValuesLandUsageSaga({ payload }) {
    // console.log(payload)
    yield put(fetchPropertyValuesLandUsageRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertyLandUsage`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertyValuesLandUsageSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesLandUsageFailure(error))
    }
}

export function* fetchPropertyValuesSupplySourceSaga() {
    yield put(fetchPropertyValuesSupplySourceRequest())
    try {
        const response = yield apiRequest(`v1/property/values/propertySupplySource`, {
            method: 'GET',
        })
        yield put(fetchPropertyValuesSupplySourceSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesSupplySourceFailure(error))
    }
}

export function* fetchPropertyValuesCadastralMunicipalitySaga() {
    yield put(fetchPropertyValuesCadastralMunicipalityRequest())
    const applicationMarket = yield select((state) => selectApplicationMarket(state))
    const isSloMarket = applicationMarket?.name === 'Slovenija'

    if (!isSloMarket) return

    try {
        const response = yield apiRequest('v1/property/values/propertyCadastralMunicipality', {
            method: 'GET',
        })
        yield put(fetchPropertyValuesCadastralMunicipalitySuccess(response.data))
    } catch (error) {
        yield put(fetchPropertyValuesCadastralMunicipalityFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchPropertyValuesPosition}`, fetchPropertyValuesPositionSaga)
    yield takeLatest(`${fetchPropertyValuesRoomsType}`, fetchPropertyValuesRoomsTypeSaga)
    yield takeLatest(`${fetchPropertyValuesHouseType}`, fetchPropertyValuesHouseTypeSaga)
    yield takeLatest(`${fetchPropertyValuesRoomsNo}`, fetchPropertyValuesRoomsNoSaga)
    yield takeLatest(`${fetchPropertyValuesFloor}`, fetchPropertyValuesFloorSaga)
    yield takeLatest(`${fetchPropertyValuesFloorPosition}`, fetchPropertyValuesFloorPositionSaga)
    yield takeLatest(`${fetchPropertyValuesOrientation}`, fetchPropertyValuesOrientationSaga)
    yield takeLatest(`${fetchPropertyValuesHeating}`, fetchPropertyValuesHeatingSaga)
    yield takeLatest(`${fetchPropertyValuesCondition}`, fetchPropertyValuesConditionSaga)
    yield takeLatest(`${fetchPropertyValuesWindowsDoors}`, fetchPropertyValuesWindowsDoorsSaga)
    yield takeLatest(`${fetchPropertyValuesPublicTransportation}`, fetchPropertyValuesPublicTransportationSaga)
    yield takeLatest(`${fetchPropertyValuesSpecialFeature}`, fetchPropertyValuesSpecialFeatureSaga)
    yield takeLatest(`${fetchPropertyValuesUtility}`, fetchPropertyValuesUtilitySaga)
    yield takeLatest(`${fetchPropertyValuesEquipment}`, fetchPropertyValuesEquipmentSaga)
    yield takeLatest(`${fetchPropertyValuesSpace}`, fetchPropertyValuesSpaceSaga)
    yield takeLatest(`${fetchPropertyValuesEnergyEfficiency}`, fetchPropertyValuesEnergyEfficiencySaga)
    yield takeLatest(`${fetchPropertyValuesAccess}`, fetchPropertyValuesAccessSaga)
    yield takeLatest(`${fetchPropertyValuesPermit}`, fetchPropertyValuesPermitSaga)
    yield takeLatest(`${fetchPropertyValuesAgencyCommission}`, fetchPropertyValuesAgencyCommissionSaga)
    yield takeLatest(`${fetchPropertyValuesStatus}`, fetchPropertyValuesStatusSaga)
    yield takeLatest(`${fetchPropertyValuesPropertyTransactionRiskDegree}`, fetchPropertyValuesPropertyTransactionRiskDegreeSaga)
    yield takeLatest(`${fetchPropertyValuesMainRegister}`, fetchPropertyValuesMainRegisterSaga)
    yield takeLatest(`${fetchPropertyValuesDescriptions}`, fetchPropertyValuesDescriptionsSaga)
    yield takeLatest(`${fetchPropertyValuesMainRegisterDepartment}`, fetchPropertyValuesMainRegisterDepartmentSaga)
    yield takeLatest(`${fetchPropertyValuesPuk}`, fetchPropertyValuesPukSaga)
    yield takeLatest(`${fetchPropertyValuesPukDepartment}`, fetchPropertyValuesPukDepartmentSaga)
    yield takeLatest(`${fetchPropertyValuesRegistryDepartmentKpu}`, fetchPropertyValuesRegistryDepartmentKpuSaga)
    yield takeLatest(`${fetchPropertyValuesRegistryDepartmentKpuSubinsert}`, fetchPropertyValuesRegistryDepartmentKpuSubinsertSaga)
    yield takeLatest(`${fetchPropertyValuesDataSource}`, fetchPropertyValuesDataSourceSaga)
    yield takeLatest(`${fetchPropertyValuesLandRegistryStatus}`, fetchPropertyValuesLandRegistryStatusSaga)
    yield takeLatest(`${fetchPropertyValuesOwnershipAcquisition}`, fetchPropertyValuesOwnershipAcquisitionSaga)
    yield takeLatest(`${fetchPropertyValuesOwnershipAcquisitionProof}`, fetchPropertyValuesOwnershipAcquisitionProofSaga)
    yield takeLatest(`${fetchPropertyValuesPrimiaryUsage}`, fetchPropertyValuesPrimaryUsageSaga)
    yield takeLatest(`${fetchPropertyValuesLandUsage}`, fetchPropertyValuesLandUsageSaga)
    yield takeLatest(`${fetchPropertyValuesSupplySource}`, fetchPropertyValuesSupplySourceSaga)
    yield takeLatest(`${fetchPropertyValuesCadastralMunicipality}`, fetchPropertyValuesCadastralMunicipalitySaga)
}
