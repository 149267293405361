import { handleActions } from 'redux-actions'
import {
    fetchExportPortalsFailure,
    fetchExportPortalsRequest,
    fetchExportPortalsSuccess,
    fetchExportSyncFailure,
    fetchExportSyncRequest,
    fetchExportSyncSuccess,
    fetchExportWebsitesFailure,
    fetchExportWebsitesRequest,
    fetchExportWebsitesSuccess,
} from './actions'

export const initialState = {
    portals: {},
    websites: {},
    sync: {
        lastSynchronization: null,
        synchronizationLeft: null,
    },
    fetchingPortals: false,
    fetchingWebsites: false,
    fetchingSync: false,
}

export default handleActions(
    {
        [fetchExportPortalsRequest]: (state) => {
            return {
                ...state,
                fetchingPortals: true,
            }
        },
        [fetchExportPortalsFailure]: (state) => {
            return {
                ...state,
                fetchingPortals: false,
            }
        },
        [fetchExportPortalsSuccess]: (state, { payload }) => {
            return {
                ...state,
                fetchingPortals: false,
                portals: payload,
            }
        },
        [fetchExportWebsitesRequest]: (state) => {
            return {
                ...state,
                fetchingWebsites: true,
            }
        },
        [fetchExportWebsitesFailure]: (state) => {
            return {
                ...state,
                fetchingWebsites: false,
            }
        },
        [fetchExportWebsitesSuccess]: (state, { payload }) => {
            return {
                ...state,
                fetchingWebsites: false,
                websites: payload,
            }
        },
        [fetchExportSyncRequest]: (state) => {
            return {
                ...state,
                fetchingSync: true,
            }
        },
        [fetchExportSyncFailure]: (state) => {
            return {
                ...state,
                fetchingSync: false,
            }
        },
        [fetchExportSyncSuccess]: (state, { payload }) => {
            return {
                ...state,
                fetchingSync: false,
                sync: payload,
            }
        },
    },
    initialState
)
