import React from 'react'
import { useSelector } from 'react-redux'
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import noPhoto from '../../../assets/images/no-photo.jpg'
import { API_URL } from '../../../common/constants'
import { usePropertyQuickViewData } from '../../../hooks'
import { selectApplicationMarket } from '../../../store/applicationSettings'

const QuickViewModal = ({ isOpen, toggle, htmlId, data }) => {
    const viewData = usePropertyQuickViewData(data)

    const currentLang = localStorage.getItem('i18nextLng')
    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const isSRBVersion = useCheckAppMarket(3)

    const applicationMarket = useSelector((state) => selectApplicationMarket(state))
    const SRB_MARKET_ID = 3
    const isSRBVersion = applicationMarket?.id === SRB_MARKET_ID

    const renderNewConstructionBadge = () => {
        const newConstruction = data?.propertyCondition?.find((x) => x.id === 1)

        if (newConstruction) {
            return (
                <Badge color="edit" className="fs-12 fw-medium me-3">
                    {newConstruction.name?.toUpperCase()}
                </Badge>
            )
        }
    }

    const renderLuxBager = () => {
        const lux = data?.propertyDescriptions?.find((x) => x.id === 40)

        if (lux) {
            return (
                <Badge color="edit" className="fs-12 fw-medium me-3">
                    {lux.name?.toUpperCase()}
                </Badge>
            )
        }
    }

    const renderLandRegistryBadge = () => {
        const landRegistry = data?.propertyTransaction?.propertyLandRegistryStatus

        return (
            isSRBVersion &&
            landRegistry?.id === 6 && (
                <Badge color="warning" className="fs-12 fw-medium me-3">
                    {landRegistry?.name?.toUpperCase()}
                </Badge>
            )
        )
    }

    return (
        <Modal size="lg" isOpen={isOpen} id={htmlId} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {data?.translations?.[currentLang]?.propertyTitle ? (
                    <span className="h5">{data?.translations?.[currentLang]?.propertyTitle}</span>
                ) : (
                    <span className="h5">
                        {data?.street ? `${data.street} ${data?.streetNumber ?? ''}, ` : ''} {data?.city ? `${data.city?.name}, ` : ''}{' '}
                        {data?.country ? data.country?.name : ''}
                    </span>
                )}
            </ModalHeader>
            <ModalBody>
                <Row className="mt-4">
                    <Col sm={12} md={6}>
                        {viewData?.general &&
                            viewData.general
                                .filter((viewItem) => viewItem.value)
                                .map((viewItem, index) => {
                                    if (viewItem.value) {
                                        return (
                                            <Row
                                                className={`align-items-start ${
                                                    viewData.general.filter((x) => x.value).length - 1 !== index ? 'mb-4' : ''
                                                }`}
                                                key={viewItem?.id}
                                            >
                                                <Col sm={6}>
                                                    <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                </Col>
                                                <Col sm={6}>
                                                    {typeof viewItem?.value === 'object' ? (
                                                        React.isValidElement(viewItem?.value) ? (
                                                            <span className="flex-grow-1 fw-light">{viewItem?.value}</span>
                                                        ) : (
                                                            <span className="flex-grow-1 fw-light">{viewItem?.value?.name}</span>
                                                        )
                                                    ) : (
                                                        <span className="flex-grow-1 fw-light">{viewItem?.value}</span>
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    return null
                                })}
                    </Col>
                    <Col sm={12} md={6}>
                        <Row>
                            <Col>
                                {data?.mainPhoto ? (
                                    <img src={`${API_URL}v1/file/show/${data?.mainPhoto.id}`} alt={data?.propertyTitle} className="img-fluid" />
                                ) : (
                                    <img src={noPhoto} alt={data?.propertyTitle} className="img-fluid" />
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                {renderNewConstructionBadge()}
                                {renderLuxBager()}
                                {renderLandRegistryBadge()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default QuickViewModal
