import { apiReq } from '.'
import { UpdateFileParams, UpdateFileResponse } from './globalTypes'

export const updateFile = async (params: UpdateFileParams) => {
    const { id, ...data } = params

    const response = await apiReq(`v1/file/${id}`, {
        method: 'PUT',
        data,
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response as UpdateFileResponse
}