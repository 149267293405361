import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Id, toast } from 'react-toastify'
import i18n from '../../i18n'
import { DocumentFile } from '../documents/types'
import { UpdateFileResponse } from '../globalTypes'
import { invalidateQuery, invalidateQueryInTable, updateToast } from '../helpers'
import { deleteImages, getImagesById, syncImage, updateImages, uploadImages } from './functions'
import { handleImagesUpdateInvalidation } from './helpers'
import {
    DeleteImageParams,
    GetImagesParams,
    Image,
    SyncImageParams,
    UpdateImageParams,
    UploadImageContext,
    UploadImageParams,
    UploadImageResponse,
} from './types'

const images = {
    GetImages: {
        useQuery: (params: GetImagesParams, options?: UseQueryOptions<Image[], AxiosError>) => {
            const { collection, module, parent, refreshToken } = params

            return useQuery({
                queryKey: [collection, module, parent, refreshToken],
                queryFn: () => getImagesById(parent, module, collection),
                staleTime: 1 * 60 * 1000,
                ...options,
            })
        },
    },
    UploadImages: {
        useMutation: (options?: UseMutationOptions<UploadImageResponse, AxiosError, UploadImageParams, UploadImageContext>) => {
            return useMutation({
                onMutate: (params) => {
                    const { collection, files } = params
                    const label = collection === 'ground_plan' ? 'groundPlan' : 'photo'
                    const toastId = toast.loading(i18n.t(`toast.loading.${label}`, { filesCount: files?.length }) as string)

                    return { toastId, label }
                },
                mutationFn: (params) => uploadImages(params),
                onSuccess: (data, params, context) => {
                    const { collection, module, entityData } = params
                    const { toastId, label } = context

                    const successCount = data.filter((result): result is DocumentFile => !('error' in result)).length
                    const errorCount = data.filter((result): result is { error: Error } => 'error' in result).length
                    const filesCount = params.files.length

                    const successMessage = i18n.t(`toast.loading.${label}.success`, { successCount, filesCount }) as string
                    const errorMessage = i18n.t(`toast.loading.${label}.error`, { errorCount, filesCount }) as string

                    if (successCount > 0) {
                        toastId && updateToast(toastId, successMessage, 'success')
                    }

                    if (errorCount > 0) {
                        toastId && updateToast(toastId, errorMessage, 'error')
                    }

                    invalidateQuery([collection, module, entityData?.parent])

                    // TODO: fix module names
                    // TODO: fix query keys names, this is crap
                    const moduleName = module === 'property' ? 'propertiesList' : 'projects'
                    
                    if (moduleName === 'projects') {
                        invalidateQuery([moduleName, 'list'])
                    } else {
                        invalidateQueryInTable(moduleName)
                    }
                },
                ...options,
            })
        },
    },
    DeleteImages: {
        useMutation: (options?: UseMutationOptions<UpdateFileResponse[], AxiosError, DeleteImageParams>) => {
            return useMutation({
                mutationFn: (params) => deleteImages(params),
                onSuccess: (data, params) => {
                    const { collection, module, parent } = params

                    invalidateQuery([collection, module, parent])

                    // TODO
                    const moduleName = module === 'property' ? 'propertiesList' : 'projects'

                    if (moduleName === 'projects') {
                        invalidateQuery([moduleName, 'list'])
                    } else {
                        invalidateQueryInTable(moduleName)
                    }
                },
                onError: (error) => {
                    console.log('Images delete error:', error)
                },
                ...options,
            })
        },
    },
    UpdateImages: {
        useMutation: (options?: UseMutationOptions<UpdateFileResponse[], AxiosError, UpdateImageParams[], Id>) => {
            return useMutation({
                onMutate: () => {
                    const toastId = toast.loading(i18n.t('toast.loading.images') as string)
                    return toastId
                },
                mutationFn: (params) => updateImages(params),
                onSuccess: (data, params, context) => {
                    const toastId = context
                    const isSuccess = data.every((result) => !('error' in result))

                    if (isSuccess) {
                        updateToast(toastId, i18n.t('toast.loading.images.success'), 'success')
                    }

                    if (!isSuccess) {
                        updateToast(toastId, i18n.t('toast.loading.images.error'), 'error')
                    }

                    handleImagesUpdateInvalidation(params)

                    // TODO
                    const module = params[0].module
                    const moduleName = module === 'property' ? 'propertiesList' : 'projects'
                    
                    if (moduleName === 'projects') {
                        invalidateQuery([moduleName, 'list'])
                    } else {
                        invalidateQueryInTable(moduleName)
                    }
                },
                ...options,
            })
        },
    },
    SyncImage: {
        useMutation: (options?: UseMutationOptions<UpdateFileResponse, AxiosError, SyncImageParams>) => {
            return useMutation({
                mutationFn: (params) => syncImage(params.id),
                onSuccess: (data, params) => {
                    const { collection, module, parent } = params

                    invalidateQuery([collection, module, parent])
                },
                onError: (error) => {
                    console.log('Image sync error:', error)
                },
                ...options,
            })
        },
    },
}

export default images


