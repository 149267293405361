import { Label, FormFeedback } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'
import _ from 'lodash'

const InputDate = (props) => {
    const { className, id, onChange, defaultValue, label, formikError, formikTouched, isRequired = false } = props

    let error = _.get(formikError, `${id}`)
    let touched = _.get(formikTouched, `${id}`)

    return (
        <div className={className}>
            <Label>{label}</Label>
            {isRequired && <span className="required-mark">*</span>}
            <Flatpickr
                className={classNames(`form-control ${error && touched && 'is-invalid'}`)}
                id={id}
                options={{
                    dateFormat: 'd-m-Y',
                }}
                onChange={onChange}
                onOpen={(e) => console.log(e)}
                value={Date.parse(defaultValue)}
            />
            {error && touched ? (
                <FormFeedback className="d-block" type="invalid">
                    {error?.split(';')[0]}
                </FormFeedback>
            ) : null}
        </div>
    )
}

export default InputDate
