import { values } from 'lodash'
import { stateKey } from './actions'

export const selectReports = (state) => values(state[stateKey].items)

export const selectOmittedActivitiesReports = (state) => {
    const reports = values(state[stateKey].items)
    return reports.map((report) => {
        if (!report.activities) return report
        // Omit activity type with id 10
        const { 10: _ten, ...activities } = report.activities
        return { ...report, activities }
    })
}

export const selectReportsFetching = (state) => state[stateKey].fetching
