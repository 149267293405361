import i18next from 'i18next'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../../utils/property'
import responseFields from '../../constants/responseFields'
import {
    baseCheckboxInput,
    baseDateInput,
    baseMultiSelectInput,
    baseNumberInput,
    basePriceInput,
    baseSelectInput,
    baseTextAreaInput,
    baseTextInput,
} from '../fieldTypes'
import { companyGeneralFields, companyOtherInfoFields, companyProfileFields } from './fields'

export const companyGeneralFieldTypes = () => {
    const { t } = i18next

    return {
        [companyGeneralFields.ID]: {
            ...baseTextInput,
            name: companyGeneralFields.ID,
            label: t('form.company.general.id'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.NAME]: {
            ...baseTextInput,
            name: companyGeneralFields.NAME,
            label: t('app.common.name'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.FULL_NAME]: {
            ...baseTextInput,
            name: companyGeneralFields.FULL_NAME,
            label: t('app.common.company.fullName'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.CONTACT_LEGAL_FORM_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_LEGAL_FORM_ID,
            label: t('app.common.company.legalForm'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.TAX_NUMBER]: {
            ...baseTextInput,
            name: companyGeneralFields.TAX_NUMBER,
            label: t('app.common.pin'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.INITIAL_CONTACT_DATE]: {
            ...baseDateInput,
            name: companyGeneralFields.INITIAL_CONTACT_DATE,
            label: t('app.common.contact.establishmentDate'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.CONTACT_PRIORITY_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_PRIORITY_ID,
            label: t('form.contact.general.priority'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.DATA_SOURCE_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.DATA_SOURCE_ID,
            label: t('app.common.contact.dataSource'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.CONTACT_SOURCE_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_SOURCE_ID,
            label: t('form.contact.general.contactSource'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.PHONE_NUMBER]: {
            ...baseTextInput,
            name: companyGeneralFields.PHONE_NUMBER,
            label: t('app.common.phoneNumber'),
        },
        [companyGeneralFields.CONTACT_PHONE_TYPE_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_PHONE_TYPE_ID,
            label: t('form.contact.general.phoneType'),
        },
        [companyGeneralFields.EMAIL]: {
            ...baseTextInput,
            name: companyGeneralFields.EMAIL,
            label: t('app.common.email.label'),
        },
        [companyGeneralFields.CONTACT_EMAIL_TYPE_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_EMAIL_TYPE_ID,
            label: t('form.contact.general.emailType'),
        },
        [companyGeneralFields.NEWSLETTER_APPROVAL]: {
            ...baseCheckboxInput,
            name: companyGeneralFields.NEWSLETTER_APPROVAL,
            label: t('form.contact.general.newsletter.label'),
            tooltip: t('form.contact.general.newsletter.tooltip'),
        },
        [companyGeneralFields.WEBSITE]: {
            ...baseTextInput,
            name: companyGeneralFields.WEBSITE,
            label: t('app.common.web'),
        },
        [companyGeneralFields.STREET]: {
            ...baseTextInput,
            name: companyGeneralFields.STREET,
            label: t('app.common.street'),
        },
        [companyGeneralFields.STREET_NUMBER]: {
            ...baseTextInput,
            name: companyGeneralFields.STREET_NUMBER,
            label: t('app.common.streetNumber'),
        },
        [companyGeneralFields.ZIP_CODE]: {
            ...baseTextInput,
            name: companyGeneralFields.ZIP_CODE,
            label: t('form.contact.general.zipCode'),
        },
        [companyGeneralFields.CITY]: {
            ...baseTextInput,
            name: companyGeneralFields.CITY,
            label: t('app.common.cityMunicipality'),
        },
        [companyGeneralFields.CONTACT_COUNTRY_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_COUNTRY_ID,
            label: t('app.common.country'),
        },
        [companyGeneralFields.CONTACT_QUERY_CATEGORY_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_QUERY_CATEGORY_ID,
            label: t('app.common.contact.queryCategory'),
        },
        [companyGeneralFields.CONTACT_ID]: {
            ...baseTextInput,
            name: companyGeneralFields.CONTACT_ID,
            label: t('form.company.general.contactPerson'),
        },
        [companyGeneralFields.CONTACT_STATUS_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.CONTACT_STATUS_ID,
            label: t('app.common.status'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.CONTACT_ABOUT]: {
            ...baseTextInput,
            name: companyGeneralFields.CONTACT_ABOUT,
            label: t('app.common.contact.about'),
            colProps: {
                sm: 12,
            },
        },
        [companyGeneralFields.AGENT_ID]: {
            ...baseSelectInput,
            name: companyGeneralFields.AGENT_ID,
            label: t('app.common.agent'),
            required: true,
        },
    }
}

export const companyOtherInfoFieldTypes = () => {
    const { t } = i18next

    return {
        [companyOtherInfoFields.CONTACT_DESCRIPTION_ID]: {
            ...baseSelectInput,
            name: companyOtherInfoFields.CONTACT_DESCRIPTION_ID,
            label: t('form.company.other.description'),
            colProps: {
                sm: 12,
            },
        },
        [companyOtherInfoFields.CONTACT_CORE_BUSINESS_ID]: {
            ...baseSelectInput,
            name: companyOtherInfoFields.CONTACT_CORE_BUSINESS_ID,
            label: t('app.common.company.coreBusiness'),
        },
        [companyOtherInfoFields.CONTACT_COMPANY_SIZE_ID]: {
            ...baseSelectInput,
            name: companyOtherInfoFields.CONTACT_COMPANY_SIZE_ID,
            label: t('app.common.company.size'),
        },
        [companyOtherInfoFields.EMPLOYEES_NO]: {
            ...baseNumberInput,
            name: companyOtherInfoFields.EMPLOYEES_NO,
            label: t('app.common.company.employeesNumber'),
        },
        [companyOtherInfoFields.BANK_ACCOUNT_NO]: {
            ...baseTextInput,
            name: companyOtherInfoFields.BANK_ACCOUNT_NO,
            label: t('app.common.company.bankAccount'),
        },
        [companyOtherInfoFields.REGISTRY_COURT]: {
            ...baseTextInput,
            name: companyOtherInfoFields.REGISTRY_COURT,
            label: t('app.common.company.registryCourt'),
        },
        [companyOtherInfoFields.SHARE_CAPITAL]: {
            ...basePriceInput,
            name: companyOtherInfoFields.SHARE_CAPITAL,
            label: t('app.common.company.shareCapital'),
        },
        [companyOtherInfoFields.INITIAL_CALL_INFO]: {
            ...baseTextAreaInput,
            name: companyOtherInfoFields.INITIAL_CALL_INFO,
            label: t('form.contact.other.initialCallInfo'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '170px' },
            },
        },
        [companyOtherInfoFields.NOTES]: {
            ...baseTextAreaInput,
            name: companyOtherInfoFields.NOTES,
            label: t('app.common.remark'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '170px' },
            },
        },
    }
}

export const companyProfileFieldTypes = (isEdit, appSettings) => {
    const { t } = i18next

    return {
        [companyProfileFields.CONTACT_PROFILE_SERVICE_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.CONTACT_PROFILE_SERVICE_ID,
            label: t('app.common.service'),
            required: true,
            disabled: isEdit,
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.PROPERTY_TYPE_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.PROPERTY_TYPE_ID,
            label: t('app.common.property.type'),
            required: true,
            disabled: isEdit,
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.PROPERTY_SUB_TYPE_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_SUB_TYPE_ID,
            label: t('app.common.property.subtype'),
            tooltip: t('app.common.property.subtype.tooltip'),
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.CONTACT_PROFILE_STATUS_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.CONTACT_PROFILE_STATUS_ID,
            label: t('app.common.contact.profileStatus'),
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.CONTACT_FINANCING_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.CONTACT_FINANCING_ID,
            label: t('app.common.contact.financing'),
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.CONTACT_PROFILE_DURATION_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.CONTACT_PROFILE_DURATION_ID,
            label: t('app.common.contact.realizationTime'),
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.COMPANY_DEFINITION_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.COMPANY_DEFINITION_ID,
            label: t('form.company.profile.definition'),
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.CONTACT_PROFILE_REMARK_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.CONTACT_PROFILE_REMARK_ID,
            label: t('app.common.contact.comment'),
            colProps: {
                sm: 12,
            },
        },
        [companyProfileFields.COUNTRY_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.COUNTRY_ID,
            label: t('app.common.country'),
        },
        [companyProfileFields.COUNTY_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.COUNTY_ID,
            label: t('app.common.county'),
        },
        [companyProfileFields.CITY_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.CITY_ID,
            label: t('app.common.cityMunicipality'),
        },
        [companyProfileFields.WIDER_AREA_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.WIDER_AREA_ID,
            label: t('app.common.widerArea'),
        },
        [companyProfileFields.QUARTER_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.QUARTER_ID,
            label: t('app.common.quarter'),
        },
        [companyProfileFields.ISLAND_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.ISLAND_ID,
            label: t('app.common.island'),
        },
        [companyProfileFields.PRICE_FROM]: {
            ...basePriceInput,
            name: companyProfileFields.PRICE_FROM,
            label: t('form.contact.profile.priceFrom', { currency: renderCurrency(appSettings?.currency) }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.PRICE_TO]: {
            ...basePriceInput,
            name: companyProfileFields.PRICE_TO,
            label: t('form.contact.profile.priceTo', { currency: renderCurrency(appSettings?.currency) }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.PRICE_M2_FROM]: {
            ...basePriceInput,
            name: companyProfileFields.PRICE_M2_FROM,
            label: t('form.contact.profile.pricePerUnitFrom', {
                currencyWithUnit: renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit),
            }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.PRICE_M2_TO]: {
            ...basePriceInput,
            name: companyProfileFields.PRICE_M2_TO,
            label: t('form.contact.profile.pricePerUnitTo', {
                currencyWithUnit: renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit),
            }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.ROOMS_FROM]: {
            ...baseTextInput,
            name: companyProfileFields.ROOMS_FROM,
            label: t('form.contact.profile.roomsFrom'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.ROOMS_TO]: {
            ...baseTextInput,
            name: companyProfileFields.ROOMS_TO,
            label: t('form.contact.profile.roomsTo'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.BEDROOMS_FROM]: {
            ...baseTextInput,
            name: companyProfileFields.BEDROOMS_FROM,
            label: t('form.contact.profile.bedroomsFrom'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.BEDROOMS_TO]: {
            ...baseTextInput,
            name: companyProfileFields.BEDROOMS_TO,
            label: t('form.contact.profile.bedroomsTo'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [companyProfileFields.AREA_FROM]: {
            ...baseTextInput,
            name: companyProfileFields.AREA_FROM,
            label: t('form.contact.profile.areaFrom', { unit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [companyProfileFields.AREA_TO]: {
            ...baseTextInput,
            name: companyProfileFields.AREA_TO,
            label: t('form.contact.profile.areaTo', { unit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [companyProfileFields.PROPERTY_FLOOR_POSITION_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_FLOOR_POSITION_ID,
            label: t('app.common.floorPosition'),
        },
        [companyProfileFields.FLOOR_NO_FROM]: {
            ...baseTextInput,
            name: companyProfileFields.FLOOR_NO_FROM,
            label: t('form.contact.profile.floorFrom'),
        },
        [companyProfileFields.FLOOR_NO_TO]: {
            ...baseTextInput,
            name: companyProfileFields.FLOOR_NO_TO,
            label: t('form.contact.profile.floorTo'),
        },
        [companyProfileFields.EXCLUDE_BASEMENT]: {
            ...baseCheckboxInput,
            name: companyProfileFields.EXCLUDE_BASEMENT,
            label: t('app.common.contact.excludeGroundFloor'),
        },
        [companyProfileFields.EXCLUDE_LAST_FLOOR]: {
            ...baseCheckboxInput,
            name: companyProfileFields.EXCLUDE_LAST_FLOOR,
            label: t('app.common.contact.excludeLastFloor'),
        },
        [companyProfileFields.PROPERTY_SPACE_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_SPACE_ID,
            label: t('app.common.property.space'),
        },
        [companyProfileFields.SPACE_COUNT]: {
            ...baseTextInput,
            name: companyProfileFields.SPACE_COUNT,
            label: t('app.common.spaceCount'),
        },
        [companyProfileFields.SPACE_AREA]: {
            ...baseTextInput,
            name: companyProfileFields.SPACE_AREA,
            label: t('app.common.spaceArea'),
        },
        [companyProfileFields.PROPERTY_DESCRIPTIONS_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_DESCRIPTIONS_ID,
            label: t('app.common.property.description'),
        },
        [companyProfileFields.PROPERTY_EQUIPMENT_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_EQUIPMENT_ID,
            label: t('app.common.equipment'),
        },
        [companyProfileFields.PROPERTY_UTILITY_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_UTILITY_ID,
            label: t('app.common.utility'),
        },
        [companyProfileFields.PROPERTY_HEATING_ID]: {
            ...baseMultiSelectInput,
            name: companyProfileFields.PROPERTY_HEATING_ID,
            label: t('app.common.heating'),
        },
        [companyProfileFields.SPECIAL_REQUEST]: {
            ...baseTextAreaInput,
            name: companyProfileFields.SPECIAL_REQUEST,
            label: t('app.common.contact.specialRequests'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '170px' },
            },
        },
        [companyProfileFields.MAX_CONTSRUCTION_AREA]: {
            ...baseTextInput,
            name: companyProfileFields.MAX_CONTSRUCTION_AREA,
            label: t('form.contact.profile.maxConstructionArea', { unit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [companyProfileFields.PROPERTY_FLOOR_ID]: {
            ...baseSelectInput,
            name: companyProfileFields.PROPERTY_FLOOR_ID,
            label: t('app.common.numberOfFloors'),
        },
    }
}

export const companyDetailSearchFieldTypes = () => {
    const { t } = i18next

    return {
        [responseFields.NAME]: {
            ...baseTextInput,
            name: responseFields.NAME,
            label: t('app.common.company.name'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_PHONE]: {
            ...baseTextInput,
            name: responseFields.CONTACT_PHONE,
            label: t('app.common.phoneNumber'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_EMAIL]: {
            ...baseTextInput,
            name: responseFields.CONTACT_EMAIL,
            label: t('app.common.email.label'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.AGENT]: {
            ...baseSelectInput,
            name: responseFields.AGENT,
            label: t('app.common.agent'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.INITIAL_CONTACT_DATE_FROM]: {
            ...baseDateInput,
            name: responseFields.INITIAL_CONTACT_DATE_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.INITIAL_CONTACT_DATE_TO]: {
            ...baseDateInput,
            name: responseFields.INITIAL_CONTACT_DATE_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.LAST_ACTIVITY_DATE_FROM]: {
            ...baseDateInput,
            name: responseFields.LAST_ACTIVITY_DATE_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.LAST_ACTIVITY_DATE_TO]: {
            ...baseDateInput,
            name: responseFields.LAST_ACTIVITY_DATE_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.CONTACT_COUNTRY]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_COUNTRY,
            label: t('app.common.country'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CITY]: {
            ...baseTextInput,
            name: responseFields.CITY,
            label: t('app.common.cityMunicipality'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_STATUS]: {
            ...baseMultiSelectInput,
            name: responseFields.CONTACT_STATUS,
            label: t('app.common.status'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_PROFILE_SERVICE]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_PROFILE_SERVICE,
            label: t('form.company.companyDetailSearch.profileType'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_PROFILE_STATUS]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_PROFILE_STATUS,
            label: t('app.common.contact.profileStatus'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_QUERY_CATEGORY]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_QUERY_CATEGORY,
            label: t('app.common.contact.queryCategory'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CONTACT_PRIORITY]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_PRIORITY,
            label: t('app.common.contact.priority'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.DATA_SOURCE]: {
            ...baseSelectInput,
            name: responseFields.DATA_SOURCE,
            label: t('app.common.contact.dataSource'),
            colProps: {
                sm: 12,
            },
        },
    }
}

export const companyProfileDetailSearchFieldTypes = () => {
    const { t } = i18next

    return {
        [responseFields.PROPERTY_TYPE]: {
            ...baseMultiSelectInput,
            name: responseFields.PROPERTY_TYPE,
            label: t('app.common.property.type'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.PROPERTY_SUB_TYPE]: {
            ...baseMultiSelectInput,
            name: responseFields.PROPERTY_SUB_TYPE,
            label: t('app.common.property.subtype'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.PRICE_FROM]: {
            ...baseTextInput,
            name: responseFields.PRICE_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.PRICE_TO]: {
            ...baseTextInput,
            name: responseFields.PRICE_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.ROOMS_FROM]: {
            ...baseTextInput,
            name: responseFields.ROOMS_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.ROOMS_TO]: {
            ...baseTextInput,
            name: responseFields.ROOMS_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.BEDROOMS_FROM]: {
            ...baseTextInput,
            name: responseFields.BEDROOMS_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.BEDROOMS_TO]: {
            ...baseTextInput,
            name: responseFields.BEDROOMS_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.CONTACT_PROFILE_DURATION]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_PROFILE_DURATION,
            label: t('app.common.contact.realizationTime'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.AREA_FROM]: {
            ...baseTextInput,
            name: responseFields.AREA_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.AREA_TO]: {
            ...baseTextInput,
            name: responseFields.AREA_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.FLOOR_FROM]: {
            ...baseTextInput,
            name: responseFields.FLOOR_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.FLOOR_TO]: {
            ...baseTextInput,
            name: responseFields.FLOOR_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.COUNTRY]: {
            ...baseSelectInput,
            name: responseFields.COUNTRY,
            label: t('app.common.country'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CITY]: {
            ...baseMultiSelectInput,
            name: responseFields.CITY,
            label: t('app.common.cityMunicipality'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.COUNTY]: {
            ...baseMultiSelectInput,
            name: responseFields.COUNTY,
            label: t('app.common.county'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.WIDER_AREA]: {
            ...baseMultiSelectInput,
            name: responseFields.WIDER_AREA,
            label: t('app.common.widerArea'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.QUARTER]: {
            ...baseMultiSelectInput,
            name: responseFields.QUARTER,
            label: t('app.common.quarter'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.PROPERTY_FLOOR]: {
            ...baseMultiSelectInput,
            name: responseFields.PROPERTY_FLOOR,
            label: t('app.common.numberOfFloors'),
            colProps: {
                sm: 12,
            },
        },
    }
}
