import { useRef, useEffect } from 'react'

export function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false)
    useEffect(() => {
        if (didMountRef.current) {
            fn()
            // console.log("### did update effect");
        } else {
            didMountRef.current = true
            // console.log("### custom update runs first time")
        }
    }, inputs)
}
