import { useContext } from 'react'
import { Card, CardBody, Row } from 'reactstrap'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import RecursiveForm from '../RecursiveForm'
import CompanyFormOtherInfoContext from './CompanyFormOtherInfoContext'

const CompanyFormOtherInfoTab = ({ children, ...props }) => {
    return (
        <CompanyFormOtherInfoContext.Provider
            value={{
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                selectOptions: props.selectOptions,
                inputComponents: props.inputComponents,
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
            }}
        >
            <Row>{children}</Row>
        </CompanyFormOtherInfoContext.Provider>
    )
}

export const CompanyFormOtherInfoTabSide = () => {
    const { inputs, formik, subform, selectOptions, inputComponents } = useContext(CompanyFormOtherInfoContext)

    return (
        <FormSideContainer>
            {inputs?.sideForm?.length ? (
                <Card>
                    <CardBody>
                        <RecursiveForm
                            inputs={inputs?.sideForm}
                            subform={subform}
                            formik={formik}
                            selectOptions={selectOptions}
                            inputComponents={inputComponents}
                        />
                    </CardBody>
                </Card>
            ) : null}
        </FormSideContainer>
    )
}

export const CompanyFormOtherInfoTabMain = () => {
    const { inputs, formik, subform, selectOptions, inputComponents, tabs, activeTab, handleTabChange } = useContext(CompanyFormOtherInfoContext)

    return (
        <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <RecursiveForm
                inputs={inputs?.mainForm}
                subform={subform}
                formik={formik}
                selectOptions={selectOptions}
                inputComponents={inputComponents}
            />
        </FormMainContainer>
    )
}

export default CompanyFormOtherInfoTab
