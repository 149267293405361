import { useRef, useState } from 'react'
import properties from '../../api/properties/hooks'
import DeleteModal from '../Common/DeleteModal'
import TableContainer from '../Table/TableContainer'
import TableColumns from './TableColumns/RelatedPropertiesColumns'

const RelatedPropertiesViewTab = ({ projectId }) => {
    const totalPages = useRef()
    const appModule = 'relatedProperties'
    const refreshToken = localStorage.getItem('refresh_token')

    const [deleteModal, setDeleteModal] = useState(false)
     const [rowId, setRowId] = useState(null)
    const [propertiesParams, setPropertiesParams] = useState({})

    const { isLoading: fetchingProperties, data: propertiesData, isFetched } = properties.GetProperties.useQuery(propertiesParams, refreshToken)
    const { mutate: deleteProperty } = properties.DeleteProperty.useMutation()

    const propertiesItems = propertiesData?.items ?? []
    const propertiesCount = propertiesData?.count ?? 1

    const propertyList = !fetchingProperties ? propertiesData : null
    if (isFetched) totalPages.current = propertyList?.pagination?.totalPages

    const onClickDelete = (id) => {
        setRowId(id)
        setDeleteModal(true)
    }

    const handleDataFetch = (page, limit, order) => {
        setPropertiesParams({ page, limit, order, criteria: { project: projectId } })
    }

    const handleRowClick = (id) => {
        window.open(`/properties/${id}`, '_blank')
    }

    const handleDeleteProperty = () => {
        deleteProperty(rowId)
        setDeleteModal(false)
    }

    const columns = TableColumns(onClickDelete)

    return (
        <>
            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteProperty()} onCloseClick={() => setDeleteModal(false)} />

            <TableContainer
                hasColumnSelection={true}
                columns={columns}
                data={propertiesItems || []}
                isGlobalFilter={false}
                isAddUserList={false}
                isLoading={fetchingProperties}
                customPageSize={20}
                hasPageSizeSelect={true}
                tableClass="dmn-table--property-documents"
                handleDataFetch={handleDataFetch}
                theadClass={'dmn-table-header'}
                isRealEstatesListFilter={true}
                totalCount={propertiesCount}
                pageCount={totalPages.current}
                handleRowClick={handleRowClick}
                tableHash={appModule}
            />
        </>
    )
}

export default RelatedPropertiesViewTab
