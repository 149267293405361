import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import AppPermissionsSwitcher from '../../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../../Components/AppPermissionsSwitcher/constants/actions'
import TableCheckbox from '../../../../Components/Table/TableCheckbox'
import { getCheckboxProps } from '../../../../Components/Table/utils'
import TableShowHideText from '../../../../Components/TableShowHideText/TableShowHideText'
import { contactResponseFields } from '../../../../common/response/contact'
import { formatDateTime, formatNumberWithTwoDecimals } from '../../../../utils/general'
import { CellProps, Row } from './TableColumns.types'

const TableColumns = (onDelete: (id: number) => void, module: string) => {
    const { t } = useTranslation()

    const Link = RouterLink as unknown as React.FC<{
        children: React.ReactNode
        title: string
        to: string
        className: string
        onClick?: () => void
    }>

    const columns = useMemo(
        () => [
            {
                Header: ({
                    toggleRowSelected,
                    isAllPageRowsSelected,
                    page,
                }: {
                    toggleRowSelected: () => void
                    isAllPageRowsSelected: boolean
                    page: Row[]
                }) => {
                    const checkboxProps = getCheckboxProps(toggleRowSelected, isAllPageRowsSelected, page)

                    return <TableCheckbox {...checkboxProps} />
                },
                Cell: ({ row }: { row: Row }) => {
                    return <TableCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original?.disabled} />
                },
                id: '#',
                tdClassName: 'text-center align-middle',
            },
            {
                Header: t('app.common.id'),
                accessor: 'id',
                filterable: true,
            },
            {
                Header: t('app.common.contact.nameAndSurname'),
                id: `name`,
                Cell: (cellProps: CellProps) => (
                    <>
                        {cellProps.row.original?.contactPerson?.firstName} {cellProps.row.original?.contactPerson?.lastName}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.status'),
                id: contactResponseFields.CONTACT_STATUS,
                Cell: (cellProps: CellProps) => <>{cellProps.row.original?.contactStatus?.name}</>,
                filterable: false,
                minWidth: 200,
            },
            {
                Header: t('app.common.profileType'),
                id: contactResponseFields.LAST_CONTACT_PROFILE,
                accessor: `${contactResponseFields.LAST_CONTACT_PROFILE}.${contactResponseFields.CONTACT_PROFILE_SERVICE}.name`,
                filterable: false,
            },
            {
                Header: t('app.common.contact.profileStatus'),
                accessor: contactResponseFields.CONTACT_PROFILE_STATUS,
                filterable: false,
            },
            {
                Header: t('app.common.city'),
                accessor: contactResponseFields.CITY,
                filterable: true,
            },
            {
                Header: t('app.common.street'),
                accessor: contactResponseFields.STREET,
                filterable: true,
            },
            {
                Header: t('app.common.streetNumber'),
                accessor: contactResponseFields.STREET_NUMBER,
                filterable: false,
            },
            {
                Header: t('app.common.contactPhoneNumber1'),
                id: `${contactResponseFields.CONTACT_PHONE}1`,
                Cell: (cellProps: CellProps) => <>{cellProps.row.original?.contactPhone[0]?.phoneNumber}</>,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('table.header.contacts.contactPhoneNumber2'),
                id: `${contactResponseFields.CONTACT_PHONE}2`,
                Cell: (cellProps: CellProps) => <>{cellProps.row.original?.contactPhone[1]?.phoneNumber}</>,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.email.label'),
                id: contactResponseFields.CONTACT_EMAIL,
                Cell: (cellProps: CellProps) => <>{cellProps.row.original?.contactEmail[0]?.email}</>,
                filterable: false,
            },
            {
                Header: t('table.header.contacts.previousActivityDate'),
                id: 'previousActivityDate',
                Cell: (cellProps: CellProps) => <>{formatDateTime(cellProps.row.original?.previousActivityDate)}</>,
                filterable: true,
            },
            {
                Header: t('table.header.contacts.nextActivityDate'),
                id: 'nextActivityDate',
                Cell: (cellProps: CellProps) => {
                    const nextActivityDate = cellProps.row.original?.nextActivityDate
                    const dayDiff = dayjs().diff(nextActivityDate, 'day')

                    return <>{nextActivityDate && <span className={`${dayDiff > 0 && 'text-danger'}`}>{formatDateTime(nextActivityDate)}</span>}</>
                },
                filterable: true,
            },
            {
                Header: t('app.common.agent'),
                id: contactResponseFields.AGENT,
                Cell: (cellProps: CellProps) => (
                    <>
                        {cellProps.row.original?.agent?.firstName} {cellProps.row.original?.agent?.lastName}
                    </>
                ),
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.company.label'),
                id: contactResponseFields.CONTACT_PERSON_COMPANY,
                Cell: (cellProps: CellProps) => (
                    <>
                        {cellProps.row.original?.contactPersonCompany
                            ?.map(({ company }) => {
                                return company?.contactCompany?.name
                            })
                            .filter(Boolean)
                            .join(', ')}
                    </>
                ),
                filterable: true,
                minWidth: 200,
            },
            {
                Header: t('app.common.contact.establishmentDate'),
                id: contactResponseFields.INITIAL_CONTACT_DATE,
                Cell: (cellProps: CellProps) => formatDateTime(cellProps.row.original?.initialContactDate),
                filterable: true,
            },
            {
                Header: t('table.header.contacts.priceTo'),
                // TODO: sort not working
                id: contactResponseFields.PRICE_TO,
                Cell: (cellProps: CellProps) => (
                    <>
                        {cellProps.row.original?.lastContactProfile?.priceTo
                            ? formatNumberWithTwoDecimals(cellProps.row.original?.lastContactProfile?.priceTo)
                            : ''}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.contact.dataSource'),
                id: contactResponseFields.DATA_SOURCE,
                accessor: `${contactResponseFields.DATA_SOURCE}.name`,
                filterable: true,
            },
            {
                Header: t('table.header.contacts.notes'),
                id: contactResponseFields.NOTES,
                filterable: false,
                Cell: (cellProps: CellProps, i: number) => (
                    <TableShowHideText key={i} text={cellProps.row.original?.notes} rowId={cellProps.row?.id} numberOfRows={cellProps.rows?.length} />
                ),
                minWidth: 200,
            },
            {
                Header: t('app.common.activityType'),
                id: contactResponseFields.NEXT_ACTIVITY,
                accessor: `${contactResponseFields.NEXT_ACTIVITY}.activityType.name`,
                filterable: false,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps: CellProps) => {
                    const itemAgentId = cellProps.row.original?.agent?.id
                    const itemAgentOfficeId = cellProps.row.original.agent?.agencyOffice?.id

                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item">
                                <AppPermissionsSwitcher
                                    permission="ModulePermissions"
                                    itemAgentId={itemAgentId}
                                    itemAgentOfficeId={itemAgentOfficeId}
                                    module={module}
                                    action={action.view}
                                >
                                    {({ hasModulePermission }: { hasModulePermission: boolean }) =>
                                        hasModulePermission && (
                                            <Link
                                                title={t('button.view')}
                                                to={`/contacts/${cellProps.row.original?.id}`}
                                                className="text-info d-inline-block"
                                            >
                                                <i className="mdi mdi-eye-outline fs-16"></i>
                                            </Link>
                                        )
                                    }
                                </AppPermissionsSwitcher>
                            </li>
                            <AppPermissionsSwitcher
                                permission="ModulePermissions"
                                itemAgentId={itemAgentId}
                                itemAgentOfficeId={itemAgentOfficeId}
                                module={module}
                                action={action.edit}
                            >
                                {({ hasModulePermission }: { hasModulePermission: boolean }) =>
                                    hasModulePermission && (
                                        <>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.edit')}
                                                    to={`/contacts/${cellProps.row.original?.id}/edit`}
                                                    className="text-info d-inline-block edit-item-btn"
                                                >
                                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.delete')}
                                                    to="#"
                                                    className="text-danger d-inline-block remove-item-btn"
                                                    onClick={() => onDelete(cellProps.row.original?.id)}
                                                >
                                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                </Link>
                                            </li>
                                        </>
                                    )
                                }
                            </AppPermissionsSwitcher>
                        </ul>
                    )
                },
            },
        ],
        [t, module, Link, onDelete]
    )

    return columns
}

export default TableColumns
