import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { PageListParams } from '../globalTypes'
import { AxiosError } from 'axios'
import { getContactById, getContacts } from './functions'
import { ContactData, ContactsDataList } from './types'

const contacts = {
    GetContact: {
        useQuery: (id: string, refreshToken: string | null, options?: UseQueryOptions<ContactData, AxiosError>) =>
            useQuery({
                queryKey: ['contacts', id, refreshToken],
                queryFn: () => getContactById(id),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetContacts: {
        useQuery: (payload: PageListParams, refreshToken: string | null, options?: UseQueryOptions<ContactsDataList, AxiosError>) =>
            useQuery({
                queryKey: [payload.page, payload.limit, payload.order, payload.criteria, 'contactList', refreshToken],
                queryFn: () => getContacts(payload),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
}

export default contacts
