import { createAction } from 'redux-actions'
import { RSAA } from '../middleware/api'

const ENTITY = 'AUTH'
export const stateKey = ENTITY.toLowerCase()

export const authenticateInitial = createAction(`${ENTITY}/INITIAL`)
export const authenticateRequest = createAction(`${ENTITY}/REQUEST`)
export const authenticateSuccess = createAction(`${ENTITY}/SUCCESS`)
export const authenticateFailure = createAction(`${ENTITY}/FAILURE`)
export const authenticateRefresh = createAction(`${ENTITY}/REFRESH`)
export const authenticateExpired = createAction(`${ENTITY}/EXPIRED`)
export const authenticateDestroy = createAction(`${ENTITY}/DESTROY`)

export const authenticateWithPassword = (username, password) => ({
    [RSAA]: {
        types: [authenticateRequest, authenticateSuccess, authenticateFailure],
        endpoint: 'login_check',
        method: 'POST',
        body: {
            username,
            password,
        },
    },
})

export const authenticateWithRefreshToken = (token) => ({
    [RSAA]: {
        types: [authenticateRefresh, authenticateSuccess, authenticateFailure],
        endpoint: 'token/refresh',
        method: 'POST',
        body: {
            refresh_token: token,
        },
    },
})

export const fetchUser = createAction(`${ENTITY}/FETCH_USER`)
export const fetchUserRequest = createAction(`${ENTITY}/FETCH_USER/REQUEST`)
export const fetchUserSuccess = createAction(`${ENTITY}/FETCH_USER/SUCCESS`)
export const fetchUserFailure = createAction(`${ENTITY}/FETCH_USER/FAILURE`)

export const updateCurrentSettings = createAction(`${ENTITY}/CURRENT_SETTINGS`)
export const updateCurrentSettingsRequest = createAction(`${ENTITY}/CURRENT_SETTINGS/REQUEST`)
export const updateCurrentSettingsSuccess = createAction(`${ENTITY}/CURRENT_SETTINGS/SUCCESS`)
export const updateCurrentSettingsFailure = createAction(`${ENTITY}/CURRENT_SETTINGS/FAILURE`)

export const registerUser = createAction(`${ENTITY}/REGISTER_USER`)
export const registerUserRequest = createAction(`${ENTITY}/REGISTER_USER/REQUEST`)
export const registerUserSuccess = createAction(`${ENTITY}/REGISTER_USER/SUCCESS`)
export const registerUserFailure = createAction(`${ENTITY}/REGISTER_USER/FAILURE`)
export const registerUserClear = createAction(`${ENTITY}/REGISTER_USER/CLEAR`)

export const errorMessageClear = createAction(`${ENTITY}/ERROR_CLEAR`)

export const forgotPasswordSendEmail = createAction(`${ENTITY}/FORGOT_PASSWORD_SEND_EMAIL`)
export const forgotPasswordSendEmailRequest = createAction(`${ENTITY}/FORGOT_PASSWORD_SEND_EMAIL/REQUEST`)
export const forgotPasswordSendEmailSuccess = createAction(`${ENTITY}/FORGOT_PASSWORD_SEND_EMAIL/SUCCESS`)
export const forgotPasswordSendEmailFailure = createAction(`${ENTITY}/FORGOT_PASSWORD_SEND_EMAIL/FAILURE`)

export const resetPassword = createAction(`${ENTITY}/RESET_PASSWORD`)
export const resetPasswordRequest = createAction(`${ENTITY}/RESET_PASSWORD/REQUEST`)
export const resetPasswordSuccess = createAction(`${ENTITY}/RESET_PASSWORD/SUCCESS`)
export const resetPasswordFailure = createAction(`${ENTITY}/RESET_PASSWORD/FAILURE`)

export const resetPasswordClear = createAction(`${ENTITY}/RESET_PASSWORD_CLEAR`)
