import { put, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import { fetchPropertyTypes, fetchPropertyTypesFailure, fetchPropertyTypesRequest, fetchPropertyTypesSuccess } from './actions'

export function* fetchPropertyTypesSaga() {
    yield put(fetchPropertyTypesRequest())

    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest('v1/property/values/propertyType', {
            method: 'GET',
        })
        yield put(fetchPropertyTypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchPropertyTypesFailure(error))
    }
}

export default function* actionWatcher() {
    // yield takeLatest([`${fetchPropertyAvailableEntitiesSuccess}`,`${fetchPropertyTypes}`], fetchPropertyTypesSaga);
    yield takeLatest(`${fetchPropertyTypes}`, fetchPropertyTypesSaga)
}
