import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap'

const DropDownWithCheckBox = ({ firstItemName = 'choose activity', activityTypes, selectedActivityTypes, setSelectedActivityTypes }) => {
    const dropDownRef = useRef(null)
    const [isDropDownOpen, setIsDropwDownOpen] = useState()

    useEffect(() => {
        const handleClick = (event) => {
            if (dropDownRef.current.props.children[0].props.id.toString() === event.target.id.toString()) {
                setIsDropwDownOpen(!isDropDownOpen)
            } else if (
                dropDownRef.current.props.children[1].props.id.toString() !==
                (event.target.parentNode.parentNode.id.toString() || event.target.parentNode.id.toString())
            ) {
                setIsDropwDownOpen(false)
            }
        }
        document.addEventListener('click', handleClick, true)

        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [isDropDownOpen])

    const renderActivityCheckBox = activityTypes.map((item, key) => {
        const handleCheckBox = (e, checked) => {
            e.preventDefault()
            e.stopPropagation()
            checked
                ? setSelectedActivityTypes([...selectedActivityTypes, e.target.value])
                : setSelectedActivityTypes(selectedActivityTypes.filter((x) => x !== e.target.value))
        }
        return (
            <DropdownItem key={key}>
                <Input
                    type="checkbox"
                    className="form-check-input"
                    id={`${item.label} ${item.value}`}
                    name={item.label}
                    value={item.value}
                    checked={selectedActivityTypes.includes(item.value.toString())}
                    onChange={(e) => handleCheckBox(e, e.target.checked)}
                />
                <label className="ms-2">{item.label}</label>
            </DropdownItem>
        )
    })

    return (
        <UncontrolledDropdown
            className="ms-2"
            ref={dropDownRef}
            isOpen={isDropDownOpen}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <DropdownToggle tag="button" className="btn btn-light border border-2" id="dropdownMenuButton">
                {firstItemName} <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu id="dropDownMenu">{renderActivityCheckBox}</DropdownMenu>
        </UncontrolledDropdown>
    )
}

DropDownWithCheckBox.propTypes = {
    activityTypes: PropTypes.any,
    selectedActivityTypes: PropTypes.any,
    setSelectedActivityTypes: PropTypes.any,
}

export default DropDownWithCheckBox
