import { useEffect, useState } from 'react'
import { companyGeneralViewData } from '../common/views/companyViewData'

export const useCompanyGeneralViewData = (data) => {
    const [viewData, setViewData] = useState(null)

    // const appSettings = useSelector((state) => ({
    //     currency: selectApplicationCurrency(state),
    //     lang: selectSettingsDefaultLanguage(state),
    //     areaUnit: selectApplicationAreaUnit(state)
    // }));

    useEffect(() => {
        setViewData(companyGeneralViewData(data))
    }, [data])

    return viewData
}
