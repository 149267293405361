import { useSelector } from 'react-redux'
import { selectCurrentUser, selectCurrentUserSettings } from '../store/auth/selectors'

export const useCurrentUser = () => {
    const { user, userSettings } = useSelector((state) => ({
        user: selectCurrentUser(state),
        userSettings: selectCurrentUserSettings(state),
    }))

    return {
        user,
        userSettings,
    }
}
