import { handleActions } from 'redux-actions'
import {
    fetchPropertyValuesAccessSuccess,
    fetchPropertyValuesAgencyCommissionSuccess,
    fetchPropertyValuesConditionSuccess,
    fetchPropertyValuesDescriptionsSuccess,
    fetchPropertyValuesEnergyEfficiencySuccess,
    fetchPropertyValuesEquipmentSuccess,
    fetchPropertyValuesFloorPositionSuccess,
    fetchPropertyValuesFloorSuccess,
    fetchPropertyValuesHeatingSuccess,
    fetchPropertyValuesHouseTypeSuccess,
    fetchPropertyValuesMainRegisterDepartmentSuccess,
    fetchPropertyValuesMainRegisterSuccess,
    fetchPropertyValuesOrientationSuccess,
    fetchPropertyValuesPermitSuccess,
    fetchPropertyValuesPositionSuccess,
    fetchPropertyValuesPropertyTransactionRiskDegreeSuccess,
    fetchPropertyValuesPublicTransportationSuccess,
    fetchPropertyValuesRegistryDepartmentKpuSuccess,
    fetchPropertyValuesPukDepartmentSuccess,
    fetchPropertyValuesPukSuccess,
    fetchPropertyValuesRoomsNoSuccess,
    fetchPropertyValuesRoomsTypeSuccess,
    fetchPropertyValuesSpaceSuccess,
    fetchPropertyValuesSpecialFeatureSuccess,
    fetchPropertyValuesStatusSuccess,
    fetchPropertyValuesStatusRequest,
    fetchPropertyValuesStatusFailure,
    fetchPropertyValuesUtilitySuccess,
    fetchPropertyValuesWindowsDoorsSuccess,
    fetchPropertyValuesRegistryDepartmentKpuSubinsertSuccess,
    fetchPropertyValuesDataSourceSuccess,
    fetchPropertyValuesLandRegistryStatusSuccess,
    fetchPropertyValuesOwnershipAcquisitionSuccess,
    fetchPropertyValuesOwnershipAcquisitionProofSuccess,
    fetchPropertyValuesPrimiaryUsageSuccess,
    fetchPropertyValuesLandUsageSuccess,
    fetchPropertyValuesSupplySourceSuccess,
    fetchPropertyValuesCadastralMunicipalitySuccess,
} from './actions'

export const initialState = {
    availableEntities: {},
    position: {},
    roomstype: {},
    housetype: {},
    roomsno: {},
    floor: {},
    floorposition: {},
    orientation: {},
    heating: {},
    condition: {},
    windowsdoors: {},
    publictransportation: {},
    specialfeature: {},
    utility: {},
    equipment: {},
    space: {},
    energyefficiency: {},
    access: {},
    permit: {},
    agencycommission: {},
    status: {},
    mainregister: {},
    propertytransactionriskdegree: {},
    descriptions: {},
    mainregisterdepartment: {},
    puk: {},
    pukdepartment: {},
    registrydepartmentkpu: {},
    registrydepartmentkpusubinsert: {},
    datasource: {},
    landregistrystatus: {},
    ownershipacquistion: {},
    ownershipacquistionproof: {},
    primaryusage: {},
    landusage: {},
    supplysource: {},
    cadastralMunicipality: {},
}

export default handleActions(
    {
        [fetchPropertyValuesPositionSuccess]: (state, { payload }) => ({
            ...state,
            position: payload,
        }),
        [fetchPropertyValuesDataSourceSuccess]: (state, { payload }) => ({
            ...state,
            datasource: payload,
        }),
        [fetchPropertyValuesRoomsTypeSuccess]: (state, { payload }) => ({
            ...state,
            roomstype: payload,
        }),
        [fetchPropertyValuesHouseTypeSuccess]: (state, { payload }) => ({
            ...state,
            housetype: payload,
        }),
        [fetchPropertyValuesRoomsNoSuccess]: (state, { payload }) => ({
            ...state,
            roomsno: payload,
        }),
        [fetchPropertyValuesFloorSuccess]: (state, { payload }) => ({
            ...state,
            floor: payload,
        }),
        [fetchPropertyValuesFloorPositionSuccess]: (state, { payload }) => ({
            ...state,
            floorposition: payload,
        }),
        [fetchPropertyValuesOrientationSuccess]: (state, { payload }) => ({
            ...state,
            orientation: payload,
        }),
        [fetchPropertyValuesHeatingSuccess]: (state, { payload }) => ({
            ...state,
            heating: payload,
        }),
        [fetchPropertyValuesConditionSuccess]: (state, { payload }) => ({
            ...state,
            condition: payload,
        }),
        [fetchPropertyValuesWindowsDoorsSuccess]: (state, { payload }) => ({
            ...state,
            windowsdoors: payload,
        }),
        [fetchPropertyValuesPublicTransportationSuccess]: (state, { payload }) => ({
            ...state,
            publictransportation: payload,
        }),
        [fetchPropertyValuesSpecialFeatureSuccess]: (state, { payload }) => ({
            ...state,
            specialfeature: payload,
        }),
        [fetchPropertyValuesUtilitySuccess]: (state, { payload }) => ({
            ...state,
            utility: payload,
        }),
        [fetchPropertyValuesEquipmentSuccess]: (state, { payload }) => ({
            ...state,
            equipment: payload,
        }),
        [fetchPropertyValuesSpaceSuccess]: (state, { payload }) => ({
            ...state,
            space: payload,
        }),
        [fetchPropertyValuesEnergyEfficiencySuccess]: (state, { payload }) => ({
            ...state,
            energyefficiency: payload,
        }),
        [fetchPropertyValuesAccessSuccess]: (state, { payload }) => ({
            ...state,
            access: payload,
        }),
        [fetchPropertyValuesPermitSuccess]: (state, { payload }) => ({
            ...state,
            permit: payload,
        }),
        [fetchPropertyValuesAgencyCommissionSuccess]: (state, { payload }) => ({
            ...state,
            agencycommission: payload,
        }),
        [fetchPropertyValuesStatusRequest]: (state, { payload }) => ({
            ...state,
            fetching: true,
            status: payload,
        }),
        [fetchPropertyValuesStatusSuccess]: (state, { payload }) => ({
            ...state,
            fetching: false,
            status: payload,
        }),
        [fetchPropertyValuesStatusFailure]: (state, { payload }) => ({
            ...state,
            fetching: false,
            status: payload,
        }),
        [fetchPropertyValuesPropertyTransactionRiskDegreeSuccess]: (state, { payload }) => ({
            ...state,
            propertytransactionriskdegree: payload,
        }),
        [fetchPropertyValuesMainRegisterSuccess]: (state, { payload }) => ({
            ...state,
            mainregister: payload,
        }),
        [fetchPropertyValuesDescriptionsSuccess]: (state, { payload }) => ({
            ...state,
            descriptions: payload,
        }),
        [fetchPropertyValuesMainRegisterDepartmentSuccess]: (state, { payload }) => ({
            ...state,
            mainregisterdepartment: payload,
        }),
        [fetchPropertyValuesPukSuccess]: (state, { payload }) => ({
            ...state,
            puk: payload,
        }),
        [fetchPropertyValuesPukDepartmentSuccess]: (state, { payload }) => ({
            ...state,
            pukdepartment: payload,
        }),
        [fetchPropertyValuesRegistryDepartmentKpuSuccess]: (state, { payload }) => ({
            ...state,
            registrydepartmentkpu: payload,
        }),
        [fetchPropertyValuesRegistryDepartmentKpuSubinsertSuccess]: (state, { payload }) => ({
            ...state,
            registrydepartmentkpusubinsert: payload,
        }),
        [fetchPropertyValuesLandRegistryStatusSuccess]: (state, { payload }) => ({
            ...state,
            landregistrystatus: payload,
        }),
        [fetchPropertyValuesOwnershipAcquisitionSuccess]: (state, { payload }) => ({
            ...state,
            ownershipacquistion: payload,
        }),
        [fetchPropertyValuesOwnershipAcquisitionProofSuccess]: (state, { payload }) => ({
            ...state,
            ownershipacquistionproof: payload,
        }),
        [fetchPropertyValuesPrimiaryUsageSuccess]: (state, { payload }) => ({
            ...state,
            primaryusage: payload,
        }),
        [fetchPropertyValuesLandUsageSuccess]: (state, { payload }) => ({
            ...state,
            landusage: payload,
        }),
        [fetchPropertyValuesSupplySourceSuccess]: (state, { payload }) => ({
            ...state,
            supplysource: payload,
        }),
        [fetchPropertyValuesCadastralMunicipalitySuccess]: (state, { payload }) => ({
            ...state,
            cadastralMunicipality: payload,
        }),
    },
    initialState
)
