import { combineActions, handleActions } from 'redux-actions'
import {
    createContactNoteFailure,
    createContactNoteRequest,
    createContactNoteSuccess,
    deleteContactNoteFailure,
    deleteContactNoteRequest,
    deleteContactNoteSuccess,
    fetchContactNotesFailure,
    fetchContactNotesRequest,
    fetchContactNotesSuccess,
    updateContactNoteFailure,
    updateContactNoteRequest,
    updateContactNoteSuccess,
} from './actions'

export const initialState = {
    items: {},
    item: {},
    count: 0,
    fetching: false,
    fetchingDetails: false,
    submitting: false,
    error: null,
    errorDetails: null,
}

export default handleActions(
    {
        [fetchContactNotesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchContactNotesSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items?.reduce((acc, item) => {
                // let parsedItem = JSON.parse(item);
                return { ...acc, [item.id]: item }
            }, {}),
            fetching: false,
        }),
        [fetchContactNotesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        // [fetchContactRequest]: state => ({
        //     ...state,
        //     fetchingDetails: true,
        //     errorDetails: null,
        // }),
        // [fetchContactSuccess]: (state, { payload }) => ({
        //     ...state,
        //     item: payload,
        //     fetchingDetails: false
        // }),
        // [fetchContactFailure]: (state, { payload }) => ({
        //     ...state,
        //     errorDetails: payload,
        //     fetchingDetails: false
        // }),
        // [fetchContactProfileRequest]: state => ({
        //     ...state,
        //     fetchingProfile: true,
        //     errorProfile: null,
        // }),
        // [fetchContactProfileSuccess]: (state, { payload }) => ({
        //     ...state,
        //     profile: payload,
        //     fetchingProfile: false
        // }),
        // [fetchContactProfileFailure]: (state, { payload }) => ({
        //     ...state,
        //     errorProfile: payload,
        //     fetchingProfile: false
        // }),
        // [createContactNoteSuccess]: (staet, {payload} => ({
        //     ...state,
        //     items: {
        //         ...state.items,
        //         [payload.id]: payload
        //     }
        // })),
        [combineActions(updateContactNoteRequest, createContactNoteRequest)]: (state) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateContactNoteSuccess, createContactNoteSuccess)]: (state) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateContactNoteFailure, createContactNoteFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload?.response,
        }),
        [updateContactNoteSuccess]: (state, { payload }) => {
            const items = { ...state.items }
            // console.log('items ', items)
            // console.log('paylaod', payload)
            items[payload.id] = {
                ...items[payload.id],
                noteTitle: payload.note_title,
                noteDate: payload.note_date,
            }
            return {
                ...state,
                items,
            }
        },
        [deleteContactNoteRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteContactNoteSuccess]: (state, { payload }) => {
            const items = { ...state.items }
            // console.log('items', items)
            delete items[payload]
            return {
                ...state,
                deleting: false,
                items,
            }
        },
        [deleteContactNoteFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        // [fetchContactsAsyncInputContactRequest]: state => ({
        //     ...state,
        //     asyncFetching: true,
        //     error: null,
        // }),
        // [fetchContactsAsyncInputContactSuccess]: (state, { payload }) => ({
        //     ...state,
        //     // asyncItems: payload?.items?.reduce((acc, item) => {
        //     //     // let parsedItem = JSON.parse(item);
        //     //     return { ...acc, [item.id]: item }}, {}),
        //     asyncFetching: false
        // }),
        // [fetchContactsAsyncInputContactFailure]: (state, { payload }) => ({
        //     ...state,
        //     error: payload,
        //     asyncFetching: false
        // }),
    },
    initialState
)
