import { put, select, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import { selectPropertyAvailableEntities } from '../propertyAvailableEntities'

import { fetchPropertySubtypes, fetchPropertySubtypesFailure, fetchPropertySubtypesRequest, fetchPropertySubtypesSuccess } from './actions'

export function* fetchPropertySubtypesSaga({ payload }) {
    yield put(fetchPropertySubtypesRequest())
    yield select(selectPropertyAvailableEntities)
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType?filter[propertyType]=${payload}]`, {
            method: 'GET',
        })
        yield put(fetchPropertySubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchPropertySubtypesFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchPropertySubtypes}`, fetchPropertySubtypesSaga)
}
