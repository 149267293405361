import { all, fork } from 'redux-saga/effects'

import LayoutSaga from './layouts/saga'

import activitiesSaga from './activities'
import activityValuesSaga from './activityValues'
import administrationSaga from './administration'
import agentsSaga from './agents'
import appSaga from './appSaga'
import authSaga from './auth'
import companiesSaga from './companies'
import companyNotesSaga from './companyNotes'
import contactNotesSaga from './contactNotes'
import contactProfilesSaga from './contactProfiles'
import contactValuesSaga from './contactValues'
import contactsSaga from './contacts'
import diarySaga from './diaries'
import documentValuesSaga from './documentValues'
import documentsSaga from './documents'
import entityValuesSaga from './entityValues'
import exportSaga from './export'
import groundplanssSaga from './groundplans'
import locationsSaga from './locations'
import modalSaga from './modal'
import notesSaga from './notes'
import offersSaga from './offers'
import photosSaga from './photos'
import projectValuesSaga from './projectValues'
import projectSaga from './projects'
import propertiesSaga from './properties'
import propertyAvailableEntitiesSaga from './propertyAvailableEntities'
import propertyServicesSaga from './propertyServices'
import propertySetSaga from './propertySet'
import propertySubtypesSaga from './propertySubtypes'
import propertyTypesSaga from './propertyTypes'
import propertyValuesSaga from './propertyValues'
import reportsSaga from './reports'
import searchSaga from './search'
import searchValuesSaga from './searchValues'
import settingsSaga from './settings'

export default function* rootSaga() {
    yield all([
        fork(LayoutSaga),
        fork(appSaga),
        fork(authSaga),
        fork(projectSaga),
        fork(propertyTypesSaga),
        fork(settingsSaga),
        fork(propertyAvailableEntitiesSaga),
        fork(entityValuesSaga),
        fork(locationsSaga),
        fork(propertyServicesSaga),
        fork(propertySetSaga),
        fork(propertySubtypesSaga),
        fork(propertiesSaga),
        fork(diarySaga),
        fork(administrationSaga),
        fork(propertyValuesSaga),
        fork(contactsSaga),
        fork(companiesSaga),
        fork(contactValuesSaga),
        fork(projectValuesSaga),
        fork(activitiesSaga),
        fork(activityValuesSaga),
        fork(contactNotesSaga),
        fork(companyNotesSaga),
        fork(agentsSaga),
        fork(searchSaga),
        fork(documentsSaga),
        fork(notesSaga),
        fork(offersSaga),
        fork(documentValuesSaga),
        fork(photosSaga),
        fork(groundplanssSaga),
        fork(exportSaga),
        fork(contactProfilesSaga),
        fork(searchValuesSaga),
        fork(modalSaga),
        fork(reportsSaga),
    ])
}
