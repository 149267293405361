import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

const FormActionButtons = ({ disabled = false, hasCancel = false, cancelAction }) => {
    const { t } = useTranslation()

    return (
        <div className="hstack gap-2 justify-content-end d-print-none">
            <Button
                color="success"
                className="btn-label"
                type={'submit'}
                disabled={disabled}
            >
                <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> {t('button.save')}
            </Button>
            {hasCancel && (
                <Button
                    color="primary"
                    className="btn-label"
                    onClick={(e) => {
                        e.preventDefault()

                        if (cancelAction) cancelAction()
                    }}
                >
                    <i className="mdi mdi-close label-icon align-middle fs-16 me-2"></i>
                    {t('button.cancel')}
                </Button>
            )}
        </div>
    )
}

export default FormActionButtons