import { getIn } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Modal, ModalBody } from 'reactstrap'
import { previewOffer, selectOffer, selectOfferError, selectOfferFetching } from '../../store/offers'

import Loader from '../Common/Loader'

const OfferPrintPreviewModal = ({ isOpen, toggle, validation, subform }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { values } = validation
    const { offerPDF, fetching, error } = useSelector((state) => ({
        offerPDF: selectOffer(state),
        fetching: selectOfferFetching(state),
        error: selectOfferError(state),
    }))
    const [propertyCount, setPropertyCount] = useState(1)

    const offerSettingsGroup = getIn(validation.values.offer, 'offer_settings_group')

    useEffect(() => {
        if (isOpen) {
            if (subform === 'offer') {
                dispatch(
                    previewOffer({
                        propertyCount: propertyCount,
                        data: values.offer,
                    })
                )
            }
        }
    }, [isOpen, propertyCount])

    useEffect(() => {
        if (!isOpen) {
            setPropertyCount(1)
        }
    }, [isOpen])

    const renderContent = () => {
        if (fetching) {
            return <Loader />
        }
        if (error) {
            return (
                <div className="alert alert-danger" role="alert">
                    Došlo je do greške prilikom učitvanja PDF ponude. Molimo pokušaj ponovo kasnije.
                </div>
            )
        }
        if (offerPDF) {
            return <iframe style={{ width: '100%', height: '100%' }} title="PDF" frameBorder="0" src={offerPDF}></iframe>
        }

        return null
    }

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {' '}
                    {t('modal.offerPrintPreview.title')}
                </h5>
                <div>
                    {values?.offer?.offer_template !== 3 && (
                        <ButtonGroup>
                            <Button
                                style={{ textDecoration: 'none' }}
                                color="link"
                                disabled={propertyCount === 1}
                                onClick={() => {
                                    setPropertyCount((propertyCount) => propertyCount - 1)
                                }}
                            >
                                <i className="mdi mdi-chevron-left label-icon align-middle fs-16 me-1"></i>
                                prethodna
                            </Button>
                            <div
                                style={{
                                    display: 'block',
                                    width: '1px',
                                    backgroundColor: '#E7E9EB',
                                }}
                            />
                            <Button
                                style={{ textDecoration: 'none' }}
                                color="link"
                                disabled={offerSettingsGroup?.length === 1 || offerSettingsGroup?.length === propertyCount}
                                onClick={() => {
                                    setPropertyCount((propertyCount) => propertyCount + 1)
                                }}
                            >
                                slijedeća
                                <i className="mdi mdi-chevron-right label-icon align-middle fs-16 ms-1"></i>
                            </Button>
                        </ButtonGroup>
                    )}

                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={toggle}></button>
                </div>
            </div>
            <ModalBody>
                <div style={{ height: '600px' }}>{renderContent()}</div>
            </ModalBody>
        </Modal>
    )
}

export default OfferPrintPreviewModal
