import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../../../Components/Common/DeleteModal'
import PageTitle from '../../../Components/Common/PageTitle'
import TableContainer from '../../../Components/Table/TableContainer'
import { useFormatTableData } from '../../../Components/Table/utils'
import { MODAL_TYPES } from '../../../common/constants'
import { fetchAgents } from '../../../store/agents/actions'
import { deleteContact, deleteContactMultiple } from '../../../store/contacts'
import { showModal } from '../../../store/modal'
import { resetTableCheckbox, selectCheckedTableRows } from '../../../store/table'
import TableColumns from './TableColumns/TableColumns'
import contacts from '../../../api/contacts/hooks'
import { getSearchCriteriaAndOrderFromStore } from '../../../api/helpers'
import { Contact } from '../../../api/contacts/types'

const ContactList = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { CHANGE_AGENT_MODAL } = MODAL_TYPES

    const [rowId, setRowId] = useState<number | undefined>()
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const totalPages = useRef<number | undefined>()

    const refreshToken = localStorage.getItem('refresh_token')
    const contactSearchCriteriaAndOrder = getSearchCriteriaAndOrderFromStore('contact')

    const {
        isLoading: fetchingContacts,
        data: contactsData,
        isFetched,
    } = contacts.GetContacts.useQuery({ page, limit, ...contactSearchCriteriaAndOrder }, refreshToken)

    const contactList = !fetchingContacts ? contactsData : null
    if (isFetched) totalPages.current = contactList?.pagination?.totalPages

    const { selectedTableRows } = useSelector((state) => ({
        selectedTableRows: selectCheckedTableRows(state),
    }))

    const appModule = 'contact'

    const onClickDelete = useCallback(
        (id: number) => {
            setRowId(id)
            setDeleteModal(true)
        },
        [setDeleteModal]
    )

    const handleDeleteContact = () => {
        dispatch(deleteContact(rowId))
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleContacts = () => {
        const selectedRowsIds = selectedTableRows.map((row: Contact) => row.id)

        dispatch(deleteContactMultiple(selectedRowsIds))
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const handleChangeAgentModal = () => {
        const payload = {
            modalType: CHANGE_AGENT_MODAL,
        }

        dispatch(showModal(payload))
        dispatch(fetchAgents())
    }

    const handleDataFetch = (page: number, limit: number) => {
        setPage(page)
        setLimit(limit)
    }

    const handleRowClick = (id: string) => {
        history.push(`/contacts/${id}`)
    }

    const tableData = useFormatTableData(contactList?.items, 'contacts')
    const columns = TableColumns(onClickDelete, 'contacts')

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <Button tag={Link as React.ElementType} to="/contacts/new" color="add" className="btn-label">
                <i className="mdi mdi-account-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                {t('button.add')}
            </Button>
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setDeleteModalMulti(true)} disabled={!selectedTableRows.length}>
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                    <AppPermissionsSwitcher permission="ChangeAgent">
                        {({ hasPermission }: { hasPermission: boolean }) =>
                            hasPermission && (
                                <DropdownItem onClick={handleChangeAgentModal} disabled={!selectedTableRows.length}>
                                    <i className="mdi mdi-account-switch-outline align-bottom me-2 text-muted"></i> {t('app.common.switchAgent')}
                                </DropdownItem>
                            )
                        }
                    </AppPermissionsSwitcher>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={() => handleDeleteContact()}
                    onCloseClick={() => setDeleteModal(false)}
                    module={undefined}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => deleteMultipleContacts()}
                    onCloseClick={() => setDeleteModalMulti(false)}
                    module={undefined}
                />

                <Container fluid>
                    <PageTitle title={t('app.common.contacts')} />
                    <Row>
                        <Col lg={12}>
                            <Card id="RealEstatesList">
                                <CardBody>
                                    <TableContainer
                                        tableActions={tableActions}
                                        hasColumnSelection={true}
                                        columns={columns}
                                        data={tableData || []}
                                        isGlobalFilter={false}
                                        isAddUserList={false}
                                        theadClass={'dmn-table-header'}
                                        hasPageSizeSelect={true}
                                        isLoading={fetchingContacts}
                                        handleDataFetch={handleDataFetch}
                                        handleRowClick={handleRowClick}
                                        totalCount={contactList?.pagination?.totalItems}
                                        pageCount={totalPages.current}
                                        searchCriteria={contactSearchCriteriaAndOrder}
                                        tableHash={appModule}
                                        currentPage={contactList?.pagination?.currentPage}
                                        totalPages={contactList?.pagination?.totalPages}
                                        permissionName={'module.contact.view'}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ContactList
