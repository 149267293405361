import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

import { fetchEntityValuesSuccess } from './actions'

const items = handleActions(
    {
        [fetchEntityValuesSuccess]: (state, { payload }) => {
            console.log(payload)
            return {
                ...state,
                ...payload.reduce(
                    (dict, item) => ({
                        ...dict,
                        [item.id]: item,
                    }),
                    {}
                ),
            }
        },
    },
    {}
)

export default combineReducers({
    items,
})
