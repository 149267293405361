import QueryString from 'qs'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import {
    deletePropertyGroundPlan,
    deletePropertyGroundPlanFailure,
    deletePropertyGroundPlanRequest,
    deletePropertyGroundPlanSuccess,
    fetchPropertiesAsyncInput,
    fetchPropertiesAsyncInputContactFailure,
    fetchPropertiesAsyncInputContactRequest,
    fetchPropertiesAsyncInputContactSuccess,
    fetchPropertiesContactProfile,
    fetchPropertiesContactProfileFailure,
    fetchPropertiesContactProfileRequest,
    fetchPropertiesContactProfileSuccess,
    fetchPropertiesNoStore,
    fetchPropertiesNoStoreFailure,
    fetchPropertiesNoStoreRequest,
    fetchPropertiesNoStoreSuccess,
    fetchPropertyGroundPlans,
    fetchPropertyGroundPlansFailure,
    fetchPropertyGroundPlansRequest,
    fetchPropertyGroundPlansSuccess,
    fetchTemplatesNoStore,
    fetchTemplatesNoStoreFailure,
    fetchTemplatesNoStoreRequest,
    fetchTemplatesNoStoreSuccess,
    quePropertyGroundPlan,
    quePropertyGroundPlanFailure,
    quePropertyGroundPlanRequest,
    quePropertyGroundPlanSuccess,
    quePropertyPhoto,
    quePropertyPhotoFailure,
    quePropertyPhotoRequest,
    quePropertyPhotoSuccess,
    uploadPropertyGroundPlan,
    uploadPropertyGroundPlanFailure,
    uploadPropertyGroundPlanRequest,
    uploadPropertyGroundPlanSuccess,
    uploadPropertyPhoto,
    uploadPropertyPhotoFailure,
    uploadPropertyPhotoRequest,
    uploadPropertyPhotoSuccess,
} from './actions'
import { generateLocationData } from '../../utils/general'

export function* uploadPropertyPhotoSaga({ payload }) {
    // console.log('meta', meta);
    console.log('uploading', payload)
    const { formData, file } = payload
    yield put(uploadPropertyPhotoRequest(file))
    try {
        const response = yield apiRequest('v1/file/upload/property/photo', {
            method: 'POST',
            data: formData,
        })

        console.log('upload response', response)
        yield put(uploadPropertyPhotoSuccess(file))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(
            uploadPropertyPhotoFailure({
                error,
                file,
            })
        )
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* quePropertyPhotoSaga({ payload, meta }) {
    yield put(quePropertyPhotoRequest())
    console.log('meta', meta)
    console.log('starting que', payload)
    try {
        yield put(quePropertyPhotoSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(quePropertyPhotoFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* fetchPropertiesContactProfileSaga({ payload, meta }) {
    yield put(fetchPropertiesContactProfileRequest())
    try {
        const response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertiesContactProfileSuccess(response.data))
        meta(response.data)
    } catch (error) {
        yield put(fetchPropertiesContactProfileFailure(error))
    }
}

export function* uploadPropertyGroundPlanSaga({ payload }) {
    // console.log('meta', meta);
    console.log('uploading', payload)
    const { formData, file } = payload
    yield put(uploadPropertyGroundPlanRequest(file))
    try {
        const response = yield apiRequest('v1/file/upload/property/ground_plan', {
            method: 'POST',
            data: formData,
        })

        console.log('upload response', response)
        yield put(uploadPropertyGroundPlanSuccess(file))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(
            uploadPropertyGroundPlanFailure({
                error,
                file,
            })
        )
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* quePropertyGroundPlanSaga({ payload, meta }) {
    yield put(quePropertyGroundPlanRequest())
    console.log('meta', meta)
    console.log('starting que', payload)
    try {
        yield put(quePropertyGroundPlanSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
    } catch (error) {
        yield put(quePropertyGroundPlanFailure(error))
    }
}

export function* deletePropertyGroundPlanSaga({ payload, meta }) {
    yield put(deletePropertyGroundPlanRequest())
    // console.log('detele', payload)
    try {
        yield apiRequest(`v1/file/${payload}`, {
            method: 'DELETE',
        })
        yield put(deletePropertyGroundPlanSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletePropertyGroundPlanFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* fetchPropertyGroundPlansSaga({ payload, meta }) {
    yield put(fetchPropertyGroundPlansRequest())
    const { parentId, ...params } = payload
    // console.log('fetch ground plan params', parentId, params)
    // console.log('meta', meta)
    try {
        const response = yield apiRequest(`v1/file/parent/${parentId}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertyGroundPlansSuccess(response.data))
        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        yield put(fetchPropertyGroundPlansFailure(error))
    }
}

export function* fetchPropertiesAsyncInputSaga({ payload, meta }) {
    const params = payload.params
    const currentLang = localStorage.getItem('i18nextLng')

    yield put(fetchPropertiesAsyncInputContactRequest())

    try {
        let response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertiesAsyncInputContactSuccess())

        let formatedResults
        if (response.data.items) {
            formatedResults = response.data.items?.map((item) => {
                return {
                    value: item?.id,
                    label: `ID: ${item.id} - ${item.translations[currentLang]?.propertyTitle ?? generateLocationData(item)}`,
                    location: generateLocationData(item),
                }
            })
        } else {
            if (response.data.contactType === 'company') {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.contactCompany?.name,
                        selected: payload?.initialValue ? true : false,
                    },
                ]
            } else {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.name,
                    },
                ]
            }
        }

        meta(formatedResults)
    } catch (error) {
        console.log('error', error)
        yield put(fetchPropertiesAsyncInputContactFailure(error))
    }
}

export function* fetchPropertiesNoStoreSaga({ payload, meta }) {
    const params = payload

    yield put(fetchPropertiesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertiesNoStoreSuccess())
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(fetchPropertiesNoStoreFailure(error))
        meta.callback({
            submitStatus: SUBMIT_RESPONSE_STATUS.FAILURE,
        })
    }
}

export function* fetchTemplatesNoStoreSaga({ meta }) {
    yield put(fetchTemplatesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/offer/templates/property`, {
            method: 'GET',
        })
        yield put(fetchTemplatesNoStoreSuccess())
        meta(response.data)
    } catch (error) {
        yield put(fetchTemplatesNoStoreFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchPropertiesContactProfile}`, fetchPropertiesContactProfileSaga)
    yield takeEvery(`${uploadPropertyPhoto}`, uploadPropertyPhotoSaga)
    yield takeEvery(`${quePropertyPhoto}`, quePropertyPhotoSaga)
    yield takeEvery(`${uploadPropertyGroundPlan}`, uploadPropertyGroundPlanSaga)
    yield takeEvery(`${quePropertyGroundPlan}`, quePropertyGroundPlanSaga)
    yield takeEvery(`${deletePropertyGroundPlan}`, deletePropertyGroundPlanSaga)
    yield takeLatest(`${fetchPropertyGroundPlans}`, fetchPropertyGroundPlansSaga)
    yield takeLatest(`${fetchPropertiesAsyncInput}`, fetchPropertiesAsyncInputSaga)
    yield takeLatest(`${fetchPropertiesNoStore}`, fetchPropertiesNoStoreSaga)
    yield takeLatest(`${fetchTemplatesNoStore}`, fetchTemplatesNoStoreSaga)
}
