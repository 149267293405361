import dayjs from 'dayjs'
import { FormikValues, getIn, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
} from 'reactstrap'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import * as Yup from 'yup'
import images from '../../../api/images/hooks'
import properties from '../../../api/properties/hooks'
import { API_URL, DETAIL_VIEW, MODAL_TYPES, WEBSITE_ATTRIBUTES } from '../../../common/constants'
import { activityFields } from '../../../common/forms/activities/fields'
import { activityResponseFields } from '../../../common/response/activity'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../Components/AppPermissionsSwitcher/constants/actions'
import CollapseCard from '../../../Components/Common/CollapseCard'
import DeleteModal from '../../../Components/Common/DeleteModal'
import FormTabNavigation from '../../../Components/Common/FormTabNavigation'
import Loader from '../../../Components/Common/Loader'
import NavigationActions from '../../../Components/Common/NavigationActions/NavigationActions'
import ViewDataCard from '../../../Components/Common/ViewDataCard'
import ViewDataList from '../../../Components/Common/ViewDataList'
import DocumentViewTab from '../../../Components/DocumentViewTab/Index'
import { useActivityFormConfig, useActivityFormSelectOptions } from '../../../Components/Hooks/FormHooks'
import { usePropertyGeneralViewData } from '../../../Components/Hooks/usePropertyViewHook'
import MapContainer from '../../../Components/MapContainer'
import ActivityModal from '../../../Components/Modals/ActivityModal'
import NotesViewAccordion from '../../../Components/NotesViewAccordion'
import OpportunityList from '../../../Components/OpportunityList/OpportunityList'
import Slider from '../../../Components/Slider/Slider'
import SliderOptions from '../../../Components/Slider/SliderOptions'
import { createActivity, updateActivity } from '../../../store/activities'
import { selectActivityValuesType } from '../../../store/activityValues/selectors'
import { selectCurrentUser } from '../../../store/auth/selectors'
import { showModal } from '../../../store/modal'
import { selectSearchCriteriaProperties } from '../../../store/search'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../store/settings/selectors'
import { selectCheckedTableRows } from '../../../store/table'
import { coerceLatLng, convertDateStringToTimeStamp, embedYotubeVideo, generateLocationData, isApproxLocationSyncType } from '../../../utils/general'
import { propertyViewTabs } from '../constants'
import { getFilteredProperties } from '../utils'
import HeaderPropertyView from './HeaderPropertyView/HeaderPropertyView'
import { RouteParams } from '../../../api/globalTypes'
import { SelectedActivity, ViewData } from './PropertyView.types'
import { Image } from '../../../api/images/types'
import { Property } from '../../../api/properties/types'

const PropertyView = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { id } = useParams<RouteParams>()
    const { t } = useTranslation()

    const { OFFER_PRINT_MODAL, TEMPLATES_MODAL, RETURN_TO_OFFER_MODAL } = MODAL_TYPES

    const currentLang = localStorage.getItem('i18nextLng') ?? 'hr'
    const refreshToken = localStorage.getItem('refresh_token') as string

    const { isLoading: fetchingProperty, data: propertyData } = properties.GetProperty.useQuery(id, refreshToken)
    const { mutate: deleteMutate } = properties.DeleteProperty.useMutation()
 
    const imagesParams = { module: 'property', parent: id, refreshToken }
    const { isLoading: fetchingPhotos, data: photosData } = images.GetImages.useQuery({ ...imagesParams, collection: 'photo' })
    const { isLoading: fetchingGroundPlans, data: groundPlansData } = images.GetImages.useQuery({ ...imagesParams, collection: 'ground_plan' })
    
    const property = !fetchingProperty ? propertyData?.item : null
    const photos = !fetchingPhotos ? photosData : []
    const groundPlans = !fetchingGroundPlans ? groundPlansData : []

    const {
        //property,
        //fetching,
        currency,
        areaUnit,
        //fetchingPhotos,
        //fetchingGroundPlans,
        //photos,
        //groundPlans,
        activityTypes,
        selectedTableRows,
        currentAgentId,
    } = useSelector((state) => ({
        //property: selectProperty(state),
        //fetching: selectPropertyFetchingDetails(state),
        currency: selectApplicationCurrency(state),
        areaUnit: selectApplicationAreaUnit(state),
        //fetchingGroundPlans: selectGroundPlansFetching(state),
        //fetchingPhotos: selectPhotosFetching(state),
        //photos: selectSortedPhotos(state),
        //groundPlans: selectSortedGroundPlans(state),
        activityTypes: selectActivityValuesType(state),
        propertySearchCriteria: selectSearchCriteriaProperties(state),
        selectedTableRows: selectCheckedTableRows(state),
        currentAgentId: selectCurrentUser(state).id,
    }))

    const [selectedActivity, setSelectedActivity] = useState<SelectedActivity>()
    const [activityModal, setActivityModal] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [activityIsEdit, setActivityIsEdit] = useState(false)
    const [acType, setAcType] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)

    const { inputs, validationConfig }: { inputs: unknown; validationConfig: { initialValues?: unknown; validationSchema?: unknown } } =
        useActivityFormConfig(selectedActivity, acType)

    // TODO: this hook is using bunch of selectors for store data
    // some of it might not be available after switch to RQ
    const selectOptions = useActivityFormSelectOptions(selectedActivity?.property?.[0].id, DETAIL_VIEW.PROPERTY)

    const viewData: ViewData = usePropertyGeneralViewData(property?.propertyType?.id, property?.propertyService?.id, property) as unknown as ViewData

    useEffect(() => {
        setSelectedActivity((prevActivity) => ({
            ...prevActivity,
            [activityResponseFields.PROPERTY]: [
                {
                    id: id,
                },
            ],
            location: generateLocationData(property),
        }))
    }, [activityModal, id, property])

    const tabs = propertyViewTabs()
    const [activeTab, setActiveTab] = useState(tabs[0].name)
    const [activeMaterialsTab, setActiveMaterialsTab] = useState('photos')

    useEffect(() => {
        if (fetchingPhotos || fetchingGroundPlans) return

        switch (true) {
            case Boolean(!photos?.length && !groundPlans?.length):
                if (property?.mapLatitude && property?.mapLongitude) {
                    setActiveMaterialsTab('map')
                    return
                }

                if (property?.virtualWalkUrl) {
                    setActiveMaterialsTab('3d')
                    return
                }

                if (property?.propertyVideoUrl) {
                    setActiveMaterialsTab('video')
                    return
                }
                break
            case Boolean(!photos?.length && groundPlans?.length):
                setActiveMaterialsTab('groundPlans')
                break
            default:
                setActiveMaterialsTab('photos')
                break
        }
    }, [fetchingPhotos, fetchingGroundPlans, property, photos?.length, groundPlans?.length])

    const handleTabChange = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const submitOtherEvent = () => {
        setShowEditForm(true)
    }

    const validation: FormikValues = useFormik({
        enableReinitialize: true,
        initialValues: validationConfig.initialValues ?? {},
        validationSchema: validationConfig.validationSchema ?? Yup.object({}),
        onSubmit: (values) => {
            if (activityIsEdit) {
                const updateEvent = {
                    ...values,
                }
                // update event
                dispatch(updateActivity(updateEvent))
                setSelectedActivity(undefined)
                validation.resetForm()
            } else {
                const newEvent: { id?: number } = {
                    ...values,
                }

                delete newEvent.id
                // delete newEvent.activity_reminder_group;
                // save new event
                dispatch(createActivity(newEvent))
                validation.resetForm()
            }

            toggleActivityModal()
        },
    })

    const selectedActivityType = getIn(validation.values, activityFields.ACTIVITY_TYPE_ID)

    useEffect(() => {
        if (selectedActivityType !== acType) {
            const foundAc = activityTypes?.find((x) => x.id === parseInt(selectedActivityType))
            if (foundAc) {
                setSelectedActivity((prevState: SelectedActivity | undefined) => ({
                    ...prevState,
                    activityType: {
                        ...(prevState?.activityType ? selectedActivity?.activityType : {}),
                        id: validation.values.activity_type_id,
                    },
                    title: validation.values.title,
                    location: generateLocationData(property) ?? validation.values.location,
                    durationDay: validation.values.duration_day ? 1 : 0,
                    contact: {
                        ...(prevState?.contact ? selectedActivity?.contact : {}),
                        id: validation.values.contact_id,
                    },
                    activityAgent: validation.values.activity_agent_group?.map(
                        (x: { agent_id: { toString: () => unknown }; notification_send: unknown }) => {
                            const eventAgent = prevState?.activityAgent?.find((a) => a.agent?.id?.toString() === x.agent_id?.toString())

                            return {
                                agent: {
                                    ...(eventAgent ? eventAgent?.agent : {}),
                                    id: x.agent_id,
                                },
                                notificationSend: x.notification_send,
                            }
                        }
                    ),
                    notes: validation.values.notes,
                    project: validation.values.project_id.map((x: number) => ({ id: x })),
                    property: validation.values.property_id.map((x: number) => ({ id: x })),
                    activityReminder: validation.values.activity_reminder_group?.map((x: { reminder_type: unknown; reminder_send: number }) => ({
                        reminderType: x.reminder_type,
                        reminderSend: x.reminder_send ? 1 : 0,
                    })),
                }))
                setAcType(foundAc?.activityCategory?.id)
            }
        }
    }, [selectedActivityType])

    const handleMaterialsTabChange = (tab: string) => {
        if (activeMaterialsTab !== tab) {
            setActiveMaterialsTab(tab)
        }
    }

    const offerFunctionAction = () => {
        const selectedRows = activeTab === 'opportunity' && selectedTableRows
        dispatch(
            showModal({
                modalType: OFFER_PRINT_MODAL,
                modalProps: {
                    [OFFER_PRINT_MODAL]: {
                        properties: [property],
                    },
                    selectedTableRows: selectedRows,
                },
            })
        )
    }

    const toggleActivityModal = () => {
        if (activityModal) {
            setActivityModal(false)
            setActivityIsEdit(false)
            setShowEditForm(false)
        } else {
            setActivityModal(true)
        }
    }

    const addNewActivityFunctionAction = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        const currectDate = new Date()
        const currentHour = currectDate.getHours()
        const currentMin = currectDate.getMinutes()
        const currentSec = currectDate.getSeconds()
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec)

        setActivityModal(true)
        setSelectedActivity({
            ...selectedActivity,
            [activityResponseFields.TITLE]: '',
            [activityResponseFields.DATE_FROM]: dayjs(modifiedDate).format('YYYY-MM-DD'),
            [activityResponseFields.TIME_FROM]: dayjs(modifiedDate).format('HH:mm'),
            [activityResponseFields.TIME_TO]: dayjs(modifiedDate).add(15, 'minute').format('HH:mm'),
            [activityResponseFields.DATE_TO]: dayjs(modifiedDate).format('YYYY-MM-DD'),
        })
    }

    const handlePrintTemplatesModal = () => {
        const selectedProperty: Property[] = [property].filter((property): property is Property => property != null)

        const payload = {
            modalType: TEMPLATES_MODAL,
            modalProps: {
                [TEMPLATES_MODAL]: {
                    selectedProperties: getFilteredProperties(selectedProperty),
                },
            },
        }

        dispatch(showModal(payload))
    }

    const isReturnToOfferLinkVisible = () => {
        // ids: 'Prodano', 'Prodano arhiva', 'Iznajmljeno', 'Iznajmljeno arhiva'
        const allowedStatusesIds = [3, 8, 4, 9]
        return allowedStatusesIds.includes(property?.propertyStatus?.id as number)
    }

    const handleReturnToOffer = () => {
        const payload = {
            modalType: RETURN_TO_OFFER_MODAL,
            modalProps: {
                [RETURN_TO_OFFER_MODAL]: { id },
            },
        }

        dispatch(showModal(payload))
    }

    const handleDeleteModal = () => {
        setDeleteModal(true)
    }

    const handleDeleteProperty = () => {
        deleteMutate(+id)
        setDeleteModal(false)
    }

    if (fetchingProperty) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        {property && (
                            <HeaderPropertyView
                                property={property}
                                currency={currency}
                                areaUnit={areaUnit}
                                locationData={viewData?.location}
                                module={location}
                            />
                        )}
                    </Col>
                </Row>

                <Row className="d-flex justify-content-between align-items-center mb-3">
                    <Col sm={12} md={8}>
                        <FormTabNavigation
                            formik={undefined}
                            tabs={tabs}
                            activeTab={activeTab}
                            handleTabChange={handleTabChange}
                            customClass="detail-view-tabs"
                        >
                            <NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        title={t('table.showMore')}
                                        href="#"
                                        className="real-estate-form-nav-link nav-link dropdown"
                                        tag="a"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <AppPermissionsSwitcher permission="ModulePermissions" module={location} action={action.edit}>
                                            {({ hasModulePermission }: { hasModulePermission: boolean }) =>
                                                hasModulePermission && (
                                                    <>
                                                        <DropdownItem onClick={() => history.push(`/properties/${id}/edit`)}>
                                                            <i className="mdi mdi-pencil-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.property.action.edit')}
                                                        </DropdownItem>
                                                        <DropdownItem href="#" onClick={() => handleDeleteModal()}>
                                                            <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.property.action.delete')}
                                                        </DropdownItem>
                                                    </>
                                                )
                                            }
                                        </AppPermissionsSwitcher>
                                        {isReturnToOfferLinkVisible() && (
                                            <DropdownItem onClick={handleReturnToOffer}>
                                                <i className="mdi mdi-undo-variant align-bottom me-2 text-muted"></i>
                                                {t('app.common.returnToOffer')}
                                            </DropdownItem>
                                        )}
                                        <DropdownItem onClick={() => history.push(`/properties/new`)}>
                                            <i className="mdi mdi-plus align-bottom me-2 text-muted"></i> {t('view.property.action.add')}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/properties/${property?.id}/new`,
                                                })
                                            }
                                        >
                                            <i className="mdi mdi-content-copy align-bottom me-2 text-muted"></i> {t('view.property.action.clone')}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.preventDefault()
                                                offerFunctionAction()
                                            }}
                                        >
                                            <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i>{' '}
                                            {t('app.common.offerOrPrint')}
                                        </DropdownItem>
                                        <DropdownItem onClick={handlePrintTemplatesModal}>
                                            <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i>{' '}
                                            {t('table.action.shopWindowPrint')}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.preventDefault()
                                                addNewActivityFunctionAction()
                                            }}
                                        >
                                            <i className="mdi mdi-calendar-plus align-bottom me-2 text-muted"></i>
                                            {t('app.common.addActivity')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                        </FormTabNavigation>
                    </Col>
                    <Col sm={12} md={4}>
                        <NavigationActions links={propertyData?.links} />
                    </Col>
                </Row>

                <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="about">
                        <Row>
                            <Col sm={12} xxl={3}>
                                {viewData?.general && viewData.general.some((x) => x.value) && <ViewDataCard dataList={viewData.general} />}
                                {viewData?.location && viewData.location.some((x) => x.value) && <ViewDataCard dataList={viewData.location} />}
                                {viewData?.owners && viewData.owners.some((x) => x.value) && (
                                    <CollapseCard header={t('view.property.owner.header')} data={viewData.owners}>
                                        <ViewDataList dataList={viewData.owners} />
                                    </CollapseCard>
                                )}
                                {viewData?.zk && viewData.zk.some((x) => x.value) && (
                                    <CollapseCard header={t('view.property.registry.header')} data={viewData.zk}>
                                        <ViewDataList dataList={viewData.zk} />
                                    </CollapseCard>
                                )}
                                <ViewDataCard dataList={viewData?.other} />
                            </Col>
                            <Col sm={12} xxl={9}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={12} xl={6}>
                                                <ViewDataList dataList={viewData?.price} />
                                                <ViewDataList dataList={viewData?.details} />
                                            </Col>
                                            <Col sm={12} xl={6}>
                                                {fetchingPhotos || fetchingGroundPlans ? (
                                                    <div className="d-flex align-items-center justify-content-center" style={{ height: '370px' }}>
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    activeMaterialsTab && (
                                                        <Row className="mb-4">
                                                            <Col>
                                                                <TabContent activeTab={activeMaterialsTab}>
                                                                    {photos?.length ? (
                                                                        <TabPane tabId="photos">
                                                                            <Slider
                                                                                module={location}
                                                                                images={photos.map((item) => {
                                                                                    const timeStamp = convertDateStringToTimeStamp(item.updated)
                                                                                    return {
                                                                                        id: item.id,
                                                                                        size: '1400-933',
                                                                                        src: `${API_URL}v1/file/show/${item.id}/original?${timeStamp}`,
                                                                                        thumb: `${API_URL}v1/file/show/${item.id}?${timeStamp}`,
                                                                                        fileSync: item.fileSync,
                                                                                    }
                                                                                })}
                                                                            />
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {groundPlans?.length ? (
                                                                        <TabPane tabId="groundPlans">
                                                                            <Slider
                                                                                module={location}
                                                                                images={groundPlans.map((item) => {
                                                                                    const timeStamp = convertDateStringToTimeStamp(item.updated)
                                                                                    return {
                                                                                        id: item.id,
                                                                                        size: '1400-933',
                                                                                        src: `${API_URL}v1/file/show/${item.id}/original?${timeStamp}`,
                                                                                        thumb: `${API_URL}v1/file/show/${item.id}?${timeStamp}`,
                                                                                        fileSync: item.fileSync,
                                                                                    }
                                                                                })}
                                                                            />
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {property?.virtualWalkUrl ? (
                                                                        <TabPane tabId="3d">
                                                                            <div className="ratio ratio-4x3">
                                                                                <iframe title="3D virtualna šetnja" src={property?.virtualWalkUrl} />
                                                                            </div>
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {property?.propertyVideoUrl ? (
                                                                        <TabPane tabId="video">
                                                                            <div className="ratio ratio-4x3">
                                                                                <iframe
                                                                                    title="Youtube video"
                                                                                    src={embedYotubeVideo(property?.propertyVideoUrl)}
                                                                                />
                                                                            </div>
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {property?.mapLatitude && property?.mapLongitude ? (
                                                                        <TabPane tabId="map">
                                                                            <MapContainer
                                                                                //@ts-expect-error - Need to replace google-maps-react
                                                                                marker={coerceLatLng(property)}
                                                                                showRadius={isApproxLocationSyncType(property)}
                                                                                height="399px"
                                                                            />
                                                                        </TabPane>
                                                                    ) : null}
                                                                </TabContent>

                                                                <AppPermissionsSwitcher
                                                                    permission="AllowedPhotos"
                                                                    module={location}
                                                                    photos={photos}
                                                                    groundPlans={groundPlans}
                                                                >
                                                                    {({ photos, groundPlans }: { photos: Image[]; groundPlans: Image[] }) =>
                                                                        photos?.length || groundPlans?.length ? (
                                                                            <SliderOptions
                                                                                photos={photos}
                                                                                groundPlans={groundPlans}
                                                                                activeMaterialsTab={activeMaterialsTab}
                                                                                handleMaterialsTabChange={handleMaterialsTabChange}
                                                                                viewData={property}
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </AppPermissionsSwitcher>
                                                            </Col>
                                                        </Row>
                                                    )
                                                )}
                                                <ViewDataList dataList={viewData?.relatedData} />
                                            </Col>
                                        </Row>
                                        {property?.translations?.[currentLang]?.propertyTitle && (
                                            <Row className="align-items-center mt-5">
                                                <Col sm={3}>
                                                    <span className="flex-grow-1 fw-medium">{t('app.common.title')}:</span>
                                                </Col>
                                                <Col sm={9}>
                                                    <span className="flex-grow-1 fw-light">{property?.translations[currentLang].propertyTitle}</span>
                                                </Col>
                                            </Row>
                                        )}
                                        {property?.translations?.[currentLang]?.propertyDescription && (
                                            <Row className="mt-5">
                                                <Col sm={12} className="mb-3">
                                                    <span className="flex-grow-1 fw-medium">{t('view.property.webDescription')}</span>
                                                </Col>
                                                <Col sm={12} className="fw-light text-secondary">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: property?.translations[currentLang].propertyDescription,
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {property?.propertyPortal?.length || property?.propertyWebsite?.length ? (
                                            <Row className="mt-5">
                                                {property?.propertyPortal?.length ? (
                                                    <Col sm={6}>
                                                        <h4 className="fw-medium fs-13 mb-4">{t('view.property.webPortalAdvertised')}</h4>
                                                        {property.propertyPortal.map((item) => (
                                                            <div key={item.id} className="mb-2 fs-13 fw-medium">
                                                                {item.title}
                                                            </div>
                                                        ))}
                                                    </Col>
                                                ) : null}
                                                {property?.propertyWebsite?.length ? (
                                                    <Col sm={6}>
                                                        <h4 className="fw-medium fs-13 mb-4">{t('view.property.webPageAdvertised')}</h4>
                                                        {property.propertyWebsite.map((item) => (
                                                            <div key={item.id} className="mb-4 fs-13">
                                                                <span className="fw-medium d-block mb-3">{item.website.title}</span>
                                                                {WEBSITE_ATTRIBUTES.map(({ attribute, name }) => {
                                                                    return (
                                                                        <Row key={attribute} className=" mb-2">
                                                                            <Col sm={6}>
                                                                                <span className="flex-grow-1 fw-light">{t(name)}:</span>
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                                <span className="flex-grow-1 fw-light">
                                                                                    {item?.[attribute]
                                                                                        ? t('view.common.conclusion.yes')
                                                                                        : t('view.common.conclusion.no')}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })}
                                                            </div>
                                                        ))}
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        ) : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <Card>
                                <CardBody>
                                    <DocumentViewTab
                                        documentModule="property"
                                        addButtonAction={() => {
                                            history.push({
                                                pathname: `/properties/${id}/edit`,
                                                state: { activeTab: 'documents' },
                                            })
                                        }}
                                        contactType={null}
                                        openDocumentFormAdd={null}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                    {activeTab === 'notes' && (
                        <TabPane tabId="notes">
                            <NotesViewAccordion id={id} noteEntity={'property'} currentAgentId={currentAgentId} />
                        </TabPane>
                    )}
                    {activeTab === 'opportunity' && (
                        <TabPane tabId="opportunity">
                            <OpportunityList />
                        </TabPane>
                    )}
                </TabContent>

                {activityModal && (
                    <ActivityModal
                        isEdit={activityIsEdit}
                        modal={activityModal}
                        event={selectedActivity}
                        toggle={toggleActivityModal}
                        showEditForm={showEditForm}
                        inputs={inputs}
                        validation={validation}
                        submitOtherEvent={submitOtherEvent}
                        selectOptions={selectOptions}
                        toggleDeleteModal={null}
                    />
                )}

                <DeleteModal
                    show={deleteModal}
                    module={'property'}
                    onDeleteClick={() => handleDeleteProperty()}
                    onCloseClick={() => setDeleteModal(false)}
                />
            </Container>
        </div>
    )
}

export default PropertyView