import { QueryKey } from '@tanstack/react-query'
import { Id, toast } from 'react-toastify'
import { queryClient } from '../'
import { store } from '../store'
import { authenticateWithRefreshToken } from '../store/auth/actions'

const refreshToken = localStorage.getItem('refresh_token')

export const getSearchCriteriaAndOrderFromStore = (pageName: string) => {
    const pageNameUpperCase = pageName.charAt(0).toUpperCase() + pageName.slice(1)

    let searchCriteria

    switch (pageName) {
        case 'contact':
            searchCriteria = { ...store.getState().search?.[`detailSearch${pageNameUpperCase}Criteria`], contactType: 'person' }
            break
        case 'company':
            searchCriteria = { ...store.getState().search?.[`detailSearch${pageNameUpperCase}Criteria`], contactType: 'company' }
            break
        default:
            searchCriteria = store.getState().search?.[`detailSearch${pageNameUpperCase}Criteria`]
    }

    const order = getColumnSortFromStore(pageName)

    return { order: order, criteria: searchCriteria }
}

export const getColumnSortFromStore = (storeName: string) => {
    const columnSort = store.getState()?.auth?.user?.userSettings?.[`${storeName}_columnSort`] ?? [{ id: 'id', desc: true }]

    const { id: columnName, desc } = columnSort[0]
    const order: Record<string, 'asc' | 'desc'> = { [columnName]: desc ? 'desc' : 'asc' }

    return order
}

export const dispatchAuthenticateWithRefreshToken = (refreshToken: string) => {
    store.dispatch(authenticateWithRefreshToken(refreshToken))
}

export const invalidateQuery = (keys: unknown[], includeToken = true) => {
    const refreshToken = localStorage.getItem('refresh_token')
    const finalKeys = includeToken ? [...keys, refreshToken] : keys

    return queryClient.invalidateQueries({
        queryKey: finalKeys,
    })
}

export const getQueryData = <T>(keys: QueryKey, includeToken = true): T | undefined => {
    const refreshToken = localStorage.getItem('refresh_token')
    const finalKeys = includeToken ? [...keys, refreshToken] : keys

    return queryClient.getQueryData<T>(finalKeys)
}

export const setQueryData = <T>(keys: QueryKey, data: T, includeToken = true): void => {
    const refreshToken = localStorage.getItem('refresh_token')
    const finalKeys = includeToken ? [...keys, refreshToken] : keys

    queryClient.setQueryData(finalKeys, data)
}

export const updateToast = (toastId: Id, message: string, type: 'success' | 'error') => {
    toast.update(toastId, {
        render: message,
        type: type,
        isLoading: false,
        autoClose: 3000,
    })
}

export const removeDuplicates = (array: [], key: string) => {
    const seen = new Set()
    return array.filter((item) => {
        const isDuplicate = seen.has(item[key])
        seen.add(item[key])
        return !isDuplicate
    })
}

export const invalidateQueryInDocumentTableList = () => {
    const cachedData: { pagination: { totalPages: number } } | undefined = queryClient.getQueryData(['null', 1, 20, 'null', 'document', refreshToken])
    const numberOfLoops = cachedData?.pagination.totalPages ?? 1

    for (let i = 0; i < numberOfLoops; i++) {
        queryClient.invalidateQueries({ queryKey: ['null', i + 1, 20, 'null', 'document', refreshToken] })
    }
}

export const invalidateQueryInTableDocuments = (array: { id: string | number; module: string }[], limit = 20, collection: string) => {
    const arrayToInvalidate = array
        .map((item) => {
            const cachedData: { pagination: { totalPages: number } } | undefined = queryClient.getQueryData([
                item.id.toString(),
                1,
                limit,
                item.module.toString(),
                collection,
                refreshToken,
            ])
            return cachedData?.pagination.totalPages ? { id: item.id, module: item.module, totalPages: cachedData?.pagination.totalPages } : undefined
        })
        .filter((value) => value !== undefined)

    arrayToInvalidate.forEach((item) => {
        const totalPages = item?.totalPages ?? 0
        for (let i = 0; i < totalPages; i++) {
            queryClient.invalidateQueries({ queryKey: [item?.id.toString(), i + 1, limit, item?.module, collection, refreshToken] })
        }
    })
}

// TODO: not really needed for table invalidation, should be renamed to something like invalidateMatchingQueries
export const invalidateQueryInTable = (keyToInvalidate: string) => {
    queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(keyToInvalidate),
    })
}
