import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getQueryData } from '../../api/helpers'
import { selectAgentIdFromItem, selectAgentOfficeIdFromItem } from '../../store/agents/selectors'
import { selectCurrentUser } from '../../store/auth/selectors'
import {
    getAgentIdFromItem,
    getAgentOfficeIdFromItem,
    handleAgentOffice,
    handleAgentOwnership,
    setAllowedAgents,
    setAllowedPermissionTabs,
    setAllowedPhotos,
    setChangeAgent,
    setEditData,
    setGoogleMaps,
    setModulePermissions,
    setNavigation,
    setPropertyStatus,
    setViewData,
} from './utils/utils'

// use this component to turn on/off application permissions
const AppPermissionsSwitcher = ({ children, ...props }) => {
    const { id } = useParams()

    let { permission, module, itemAgentId, itemAgentOfficeId, activityAgentId, documentAgentId } = props

    module = module?.pathname?.includes('/') ? module.pathname.split('/')[1] || module : module

    // TODO: switch to getQueryData after RQ is implemented for contacts & companies
    const { agent, agentOwnershipIdStore, agentOfficeIdStore } = useSelector((state) => ({
        agent: selectCurrentUser(state),
        agentOwnershipIdStore: selectAgentIdFromItem(state, module, id),
        agentOfficeIdStore: selectAgentOfficeIdFromItem(state, module, id),
    }))

    const data = getQueryData([module, id])
    const agentOwnershipIdRQ = getAgentIdFromItem(data?.item, module, id)
    const agentOfficeIdRQ = getAgentOfficeIdFromItem(data?.item, module, id)
    const propertyStatus = data?.item?.propertyStatus

    const agentOwnershipId = agentOwnershipIdRQ || agentOwnershipIdStore
    const agentOfficeId = agentOfficeIdRQ || agentOfficeIdStore

    const { agentPermissions, id: currentAgentId, agencyOffice: currentOffice } = agent ?? []

    const isAgentOwnership = handleAgentOwnership(agentOwnershipId, currentAgentId, itemAgentId, activityAgentId, documentAgentId)
    const isAgentOffice = handleAgentOffice(agentOfficeId, currentOffice, itemAgentOfficeId)

    const permissionProps = {
        ...props,
        agentPermissions,
        isAgentOwnership,
        isAgentOffice,
        currentAgentId,
        currentOffice,
        module,
        propertyStatus,
    }
    let newProps = {}

    switch (permission) {
        case 'ModulePermissions':
            newProps = setModulePermissions(permissionProps)
            break
        case 'Navigation':
            newProps = setNavigation(permissionProps)
            break
        case 'ViewData':
            newProps = setViewData(permissionProps)
            break
        case 'EditData':
            newProps = setEditData(permissionProps)
            break
        case 'AllowedTabs':
            newProps = setAllowedPermissionTabs(permissionProps)
            break
        case 'AllowedPhotos':
            newProps = setAllowedPhotos(permissionProps)
            break
        case 'AllowedAgents':
            newProps = setAllowedAgents(permissionProps)
            break
        case 'GoogleMaps':
            newProps = setGoogleMaps(permissionProps)
            break
        case 'PropertyStatus':
            newProps = setPropertyStatus(permissionProps)
            break
        case 'ChangeAgent':
            newProps = setChangeAgent(permissionProps)
            break
        default:
            console.error(`No application permission defined for: ${permission}`)
    }

    return children(newProps)
}

export default AppPermissionsSwitcher
