import values from 'lodash/values'

import { stateKey } from './actions'

export const selectOffers = (state) => values(state[stateKey].items)
export const selectOffersFetching = (state) => state[stateKey].fetching
export const selectOffersSubmitting = (state) => state[stateKey].submitting
export const selectOffersError = (state) => state[stateKey].error
export const selectOffer = (state) => state[stateKey].item
export const selectOfferFetching = (state) => state[stateKey].fetchingDetails
export const selectOfferError = (state) => state[stateKey].errorDetails
