export const projectResponseFields = {
    ID: 'id',
    AGENT: 'agent',
    AGENCY_COMMISSION: 'agencyCommission',
    CITY: 'city',
    CONTRACT_DATE: 'contractDate',
    CONTRACT_NO: 'contractNo',
    COUNTRY: 'country',
    COUNTY: 'county',
    CREATED: 'created',
    ISLAND: 'island',
    MAP_LATITUDE: 'mapLatitude',
    MAP_LONGITUDE: 'mapLongitude',
    MAP_LOCATION_SYNC: 'mapLocationSync',
    MAP_LOCATION_SYNC_TYPE: 'mapLocationSyncType',
    OWNER: 'owner',
    OWNER_COMPANY: 'ownerCompany',
    PROJECT_DESCRIPTION: 'projectDescription',
    PROJECT_DESCRIPTION_TRANSLATIONS: 'projectDescriptionTranslations',
    PROJECT_OFFER_DESCRIPTION: 'projectOfferDescription',
    PROJECT_OFFER_DESCRIPTION_TRANSLATIONS: 'projectOfferDescriptionTranslations',
    PROJECT_DEVELOPMENT_STAGE: 'projectDevelopmentStage',
    PROJECT_END_DATE: 'projectEndDate',
    PROJECT_ENERGY_EFFICIENCY: 'projectEnergyEfficiency',
    PROJECT_STATUS: 'projectStatus',
    PROJECT_SIZE: 'projectSize', // ERSTE ONLY FIELD
    PROJECT_TITLE: 'projectTitle',
    PROJECT_TITLE_TRANSLATIONS: 'projectTitleTranslations',
    PROJECT_TYPE: 'projectType',
    PROJECT_OBJECT_TYPE: 'projectObjectType', // ERSTE ONLY FIELD
    PROJECT_WEB_SYNC: 'projectWebSync',
    PROJECT_WEB_NAVIGATION_SYNC: 'projectWebNavigationSync', // ERSTE ONLY FIELD
    QUARTER: 'quarter',
    STREET: 'street',
    STREET_NUMBER: 'streetNumber',
    STREET_SYNC: 'streetSync',
    WIDER_AREA: 'widerArea',
    UPDATED: 'updated',
    PROJECT_RECEIPT_DATE: 'projectReceiptDate',
    ERSTE_REPRESENTATIVE: 'ersteRepresentative', // ERSTE ONLY FIELD
    PROPERTY_VIDEO_URL: 'propertyVideoUrl',
    VIRTUAL_WALK_URL: 'virtualWalkUrl',
}
