import { useContext } from 'react'
import { Row } from 'reactstrap'
import FormMainContainer from '../../../Common/FormMainContainer'
import FormSideContainer from '../../../Common/FormSideContainer'
import FormTabNavigation from '../../../Common/FormTabNavigation'
import RecursiveForm from '../../../RecursiveForm'
import PropertyFormMaterialsContext from '../../PropertyFormMaterialsContext'
import PropertyFormMaterialsVirtualWalkVideoContext from './PropertyFormMaterialsVirtualWalkVideoContext'

const PropertyFormMaterialsVirtualWalkVideoTab = ({ children, ...props }) => {
    return (
        <PropertyFormMaterialsVirtualWalkVideoContext.Provider
            value={{
                inputs: props.inputs,
                subform: props.subform,
                formik: props.formik,
            }}
        >
            <Row>{children}</Row>
        </PropertyFormMaterialsVirtualWalkVideoContext.Provider>
    )
}
export const PropertyFormMaterialsVirtualWalkVideoTabSide = () => {
    return <FormSideContainer />
}
export const PropertyFormMaterialsVirtualWalkVideoTabMain = () => {
    const { tabs, activeTab, handleTabChange, activeMaterialsTab, materialsTabs, handleMaterialsTabChange } = useContext(PropertyFormMaterialsContext)
    const { inputs, subform, formik } = useContext(PropertyFormMaterialsVirtualWalkVideoContext)

    return (
        <FormMainContainer ainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <FormTabNavigation
                handleTabChange={handleMaterialsTabChange}
                tabs={materialsTabs}
                activeTab={activeMaterialsTab}
                customClass="real-estate-form-subtabs mb-4"
            />
            <RecursiveForm inputs={inputs.mainForm} subform={subform} formik={formik} />
        </FormMainContainer>
    )
}

export default PropertyFormMaterialsVirtualWalkVideoTab
