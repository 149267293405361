import ImageEditor from '@toast-ui/react-image-editor'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import { getQueryData, setQueryData } from '../../api/helpers'
import { API_URL } from '../../common/constants'
import { convertDateStringToTimeStamp } from '../../utils/general'
import { crop1_1button, cropNewButton, handleInputTooltip, undoButton } from './buttonUtils'
import './styles.css'
import { whiteTheme } from './theme'
import {
    actionButtonsTranslation,
    cropTranslations,
    drawTranslation,
    filterTranslation,
    iconsTranslation,
    resizeTranslations,
    rotateDrawRangeTranslation,
    shapeTranslation,
    textTranslation,
    toolbarTranslations,
    useActiveButton,
} from './translationUtils'

const ToastImageEditor = ({ image, isSubmitting }) => {
    const timestamp = convertDateStringToTimeStamp(image?.updated)
    const originalImageUrl = `${API_URL}v1/file/show/${image?.id}/original?timestamp=${timestamp}`

    const editorRef = useRef()
    const [editorInstance, setEditorInstance] = useState(null)

    // set editor instance
    useEffect(() => {
        if (editorRef.current) {
            const editorInstance = editorRef.current.getInstance()
            setEditorInstance(editorInstance)
        }
    }, [editorRef, setEditorInstance])

    // save image changes
    useEffect(() => {
        const base64 = editorInstance?.toDataURL()

        if (!image && !base64) return

        if (isSubmitting) {
            const { collection, module, parent } = image
            const images = getQueryData([collection, module, parent.toString()])
            const updatedImages = images.map((img) => (img.id === image.id ? { ...img, file: base64 } : img))

            setQueryData([collection, module, parent.toString()], updatedImages)
        }
    }, [editorInstance, isSubmitting, image])

    // render custom buttons and translations
    useEffect(() => {
        const editorInstance = editorRef.current?.getInstance()

        crop1_1button()
        cropNewButton(editorInstance, '.preset-4-3', '3:4', 3 / 4)
        cropNewButton(editorInstance, '.preset-16-9', '9:16', 9 / 16)
        undoButton(editorInstance)
        handleInputTooltip()

        toolbarTranslations()
        cropTranslations()
        resizeTranslations()
        drawTranslation()
        shapeTranslation()
        iconsTranslation()
        textTranslation()
        filterTranslation()
    }, [])

    // trigger translations of action buttons and range inputs (rotate & draw)
    useActiveButton('.tie-btn-crop', '.tie-btn-resize', actionButtonsTranslation)
    useActiveButton('.tie-btn-rotate', '.tie-btn-draw', rotateDrawRangeTranslation)

    // inserts GridDimensions component
    const portalContainerRef = useRef(document.createElement('div'))
    const subMenu = document.querySelector('.tui-image-editor-submenu-item')

    useEffect(() => {
        if (subMenu) {
            subMenu.insertAdjacentElement('beforebegin', portalContainerRef.current)
        }
    }, [subMenu])

    // see all props for editor
    // https://www.npmjs.com/package/@toast-ui/react-image-editor
    return (
        <>
            <ImageEditor
                ref={editorRef}
                includeUI={{
                    loadImage: {
                        path: originalImageUrl,
                        name: 'image',
                    },
                    menu: ['crop', 'resize', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter'],
                    initMenu: 'crop',
                    uiSize: {
                        height: '710px',
                    },
                    theme: whiteTheme,
                }}
                usageStatistics={false}
                selectionStyle={{
                    cornerStyle: 'circle',
                    cornerSize: 10,
                    cornerColor: 'white',
                    cornerStrokeColor: 'white',
                    transparentCorners: false,
                    lineWidth: 1,
                    borderColor: 'white',
                }}
                cssMaxHeight={450} // controls maximum height of the image canvas
            />
            {ReactDOM.createPortal(<GridDimensionsDisplay editorInstance={editorInstance} />, portalContainerRef.current)}
        </>
    )
}

const GridDimensionsDisplay = ({ editorInstance }) => {
    const [gridDimensions, setGridDimensions] = useState(null)
    const buttons = ['.preset-none', '.tie-btn-crop', '.tie-btn-resize']

    useEffect(() => {
        if (!editorInstance) return

        const handleGridDimensions = (objectProps) => {
            if (objectProps.type === 'cropzone') {
                const gridWidth = Math.round(objectProps?.width)
                const gridHeight = Math.round(objectProps?.height)

                setGridDimensions(`${gridWidth} x ${gridHeight}`)
            }
        }

        editorInstance.on('objectActivated', handleGridDimensions)
        editorInstance.on('objectScaled', handleGridDimensions)
    }, [editorInstance, setGridDimensions])

    const resetGrid = (selector) => {
        const button = document.querySelector(selector)

        if (button) {
            button.addEventListener('click', () => setGridDimensions(null))
        }
    }

    buttons.forEach((button) => resetGrid(button))

    return <div className="grid-dimensions-display">{gridDimensions}</div>
}

export default ToastImageEditor