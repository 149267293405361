import QueryString from 'qs'
import { apiReq } from '..'
import { PageListParams } from '../globalTypes'
import { getSearchCriteriaAndOrderFromStore } from '../helpers'

export const getContactById = async (id: string) => {
    const response = await apiReq(`v1/contact/${id}`, {
        params: getSearchCriteriaAndOrderFromStore('contact'),
        paramsSerializer: (params: unknown) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}

export const getContacts = async (payload: PageListParams) => {
    const response = await apiReq(`v1/contact`, {
        params: payload,
        paramsSerializer: (params: PageListParams) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}
