import i18next from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateTime, formatNumberWithTwoDecimals } from '../../utils/general'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../utils/property'
import { IS_ERSTE } from '../constants'
import { contactResponseFields } from '../response/contact'

export const contactGeneralViewData = (data) => {
    const { t } = i18next

    const contactPhone = data?.[contactResponseFields.CONTACT_PHONE]?.map((item, i) => ({
        id: `${contactResponseFields.CONTACT_PHONE}-${item.id}`,
        label: `${t('view.contact.general.phone')} ${i + 1}`,
        value: `${item.phoneNumber}${item.contactPhoneType ? ' - ' + item.contactPhoneType?.name : ''}`,
    }))

    const contactEmail = data?.[contactResponseFields.CONTACT_EMAIL]?.map((item, i) => ({
        id: `${contactResponseFields.CONTACT_EMAIL}-${item.id}`,
        label: `${t('app.common.email.label')} ${i + 1}`,
        value: (
            <>
                <a className="dmn-link" href={`mailto:${item.email}`}>
                    {item.email}
                </a>
                {item.contactEmailType ? ' - ' + item.contactEmailType?.name : ''}
            </>
        ),
    }))

    return {
        general: [
            {
                id: contactResponseFields.ID,
                label: t('app.common.id'),
                value: data?.[contactResponseFields.ID],
            },
            {
                id: `${contactResponseFields.CONTACT_TITLE}_name`,
                label: t('app.common.name'),
                value: data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.CONTACT_TITLE]?.name,
            },
            {
                id: `${contactResponseFields.FIRST_NAME}-${contactResponseFields.LAST_NAME}`,
                label: t('app.common.contact.nameAndSurname'),
                value: `${
                    data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.FIRST_NAME]
                        ? data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.FIRST_NAME]
                        : ''
                } ${
                    data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.LAST_NAME]
                        ? data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.LAST_NAME]
                        : ''
                }`,
            },
            {
                id: `${contactResponseFields.GENDER}_name`,
                label: t('app.common.gender'),
                value: data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.GENDER]?.name,
            },
            {
                id: `${contactResponseFields.TAX_NUMBER}_name`,
                label: t('app.common.pin'),
                value: data?.[contactResponseFields.TAX_NUMBER],
            },
            {
                id: contactResponseFields.BIRTH_DATE,
                label: t('app.common.contact.birthDate'),
                value: formatDateTime(data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.BIRTH_DATE]),
            },
            {
                id: `${contactResponseFields.CONTACT_MARITAL_STATUS}_name`,
                label: t('app.common.contact.maritalStatus'),
                value: data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.CONTACT_MARITAL_STATUS]?.name,
            },
            {
                id: `${contactResponseFields.STREET}-${contactResponseFields.STREET_NUMBER}`,
                label: t('view.contact.general.address'),
                value:
                    (data?.[contactResponseFields.STREET] ||
                        data?.[contactResponseFields.STREET_NUMBER] ||
                        data?.[contactResponseFields.ZIP_CODE] ||
                        data?.[contactResponseFields.CITY] ||
                        data?.[contactResponseFields.CONTACT_COUNTRY]) &&
                    `${data?.[contactResponseFields.STREET] ?? ''} ${
                        data?.[contactResponseFields.STREET_NUMBER] ? data?.[contactResponseFields.STREET_NUMBER] + ', ' : ''
                    } ${data?.[contactResponseFields.ZIP_CODE] ?? ''} ${data?.[contactResponseFields.CITY] ?? ''} ${
                        data?.[contactResponseFields.CONTACT_COUNTRY] ? ', ' + data?.[contactResponseFields.CONTACT_COUNTRY]?.name : ''
                    }`,
            },
            ...(contactPhone ?? []),
            ...(contactEmail ?? []),
            {
                id: contactResponseFields.DATA_SOURCE,
                label: t('app.common.contact.dataSource'),
                value: data?.[contactResponseFields.DATA_SOURCE]?.name,
            },
            IS_ERSTE && {
                id: contactResponseFields.CONTACT_SOURCE,
                label: t('view.contact.general.contactSource'),
                value: data?.[contactResponseFields.CONTACT_SOURCE]?.name,
            },
            IS_ERSTE && {
                id: `${contactResponseFields.CONTACT_ABOUT}`,
                label: t('app.common.contact.about'),
                value: data?.[contactResponseFields.CONTACT_ABOUT],
            },
            {
                id: contactResponseFields.CONTACT_STATUS,
                label: t('app.common.status'),
                value: data?.[contactResponseFields.CONTACT_STATUS] ? data?.[contactResponseFields.CONTACT_STATUS]?.name : '',
            },
            {
                id: `${contactResponseFields.CONTACT_PRIORITY}_name`,
                label: t('app.common.contact.priority'),
                value: data?.[contactResponseFields.CONTACT_PRIORITY]?.name,
            },
            {
                id: `${contactResponseFields.CONTACT_DESCRIPTION}_name`,
                label: t('app.common.contact.description'),
                value: data?.[contactResponseFields.CONTACT_DESCRIPTION]?.name,
            },
            {
                id: `${contactResponseFields.CONTACT_QUERY_CATEGORY}_name`,
                label: t('app.common.contact.queryCategory'),
                value: data?.[contactResponseFields.CONTACT_QUERY_CATEGORY]?.name,
            },
            {
                id: `${contactResponseFields.CONTACT_COMPANY}_name`,
                label: t('app.common.company.label'),
                value: data?.[contactResponseFields.CONTACT_PERSON_COMPANY]?.length
                    ? data?.[contactResponseFields.CONTACT_PERSON_COMPANY]?.map((item) => (
                          <div key={item.company.id}>
                              <Link to={`/companies/${item.company.id}`} className="dmn-link">
                                  {item.company.contactCompany.name}
                              </Link>
                          </div>
                      ))
                    : '',
            },
            {
                id: `${contactResponseFields.CONTACT_JOB_TITLE}_name`,
                label: t('app.common.jobTitle'),
                value:
                    data?.[contactResponseFields.CONTACT_PERSON_COMPANY]?.length &&
                    data?.[contactResponseFields.CONTACT_PERSON_COMPANY].some((x) => x.contactJobTitle)
                        ? data?.[contactResponseFields.CONTACT_PERSON_COMPANY]?.map((item) => (
                              <div key={item.company.id}>{item.contactJobTitle?.name}</div>
                          ))
                        : '',
            },
            {
                id: `${contactResponseFields.EMPLOYMENT_TYPE}_name`,
                label: t('app.common.contact.employmentType'),
                value:
                    data?.[contactResponseFields.CONTACT_PERSON_COMPANY]?.length &&
                    data?.[contactResponseFields.CONTACT_PERSON_COMPANY].some((x) => x.employmentType)
                        ? data?.[contactResponseFields.CONTACT_PERSON_COMPANY]?.map((item) => <div key={item.company.id}>{item.employmentType}</div>)
                        : '',
            },
            {
                id: `${contactResponseFields.AGENT}_full_name`,
                label: t('app.common.agent'),
                value: data?.agent ? (
                    <>
                        {data?.[contactResponseFields.AGENT]?.firstName} {data?.[contactResponseFields.AGENT]?.lastName}
                    </>
                ) : (
                    ''
                ),
            },
            {
                id: contactResponseFields.CREATED,
                label: t('view.common.other.created'),
                value: formatDateTime(data?.[contactResponseFields.CREATED]),
            },
            {
                id: contactResponseFields.UPDATED,
                label: t('app.common.updated'),
                value: formatDateTime(data?.[contactResponseFields.UPDATED]),
            },
        ],
    }
}

export const contactProfileViewData = (data, config) => {
    const currency = renderCurrency(config.currency)
    const currencyWithUnit = renderCurrencyWithUnit(config?.currency, config?.areaUnit)
    const unit = renderAreaUnit(config?.areaUnit)
    const { t } = i18next

    return [
        {
            id: `${contactResponseFields.PROPERTY_SUB_TYPE}`,
            label: t('app.common.property.subtype'),
            value: data?.[contactResponseFields.PROPERTY_SUB_TYPE]?.length
                ? data?.[contactResponseFields.PROPERTY_SUB_TYPE]?.map((item, i) => {
                      return <React.Fragment key={item.id}>{i === 0 ? item.name : `, ${item.name}`}</React.Fragment>
                  })
                : null,
        },
        {
            id: `${contactResponseFields.CONTACT_PROFILE_STATUS}`,
            label: t('app.common.contact.profileStatus'),
            value: data?.[contactResponseFields.CONTACT_PROFILE_STATUS]?.name ?? null,
        },
        {
            id: `${contactResponseFields.CONTACT_FINANCING}`,
            label: t('app.common.contact.financing'),
            value: data?.[contactResponseFields.CONTACT_FINANCING]?.length
                ? data?.[contactResponseFields.CONTACT_FINANCING]?.map((item, i) => {
                      return <React.Fragment key={item.id}>{i === 0 ? item.name : `, ${item.name}`}</React.Fragment>
                  })
                : null,
        },
        {
            id: `${contactResponseFields.CONTACT_PROFILE_DURATION}`,
            label: t('app.common.contact.realizationTime'),
            value: data?.[contactResponseFields.CONTACT_PROFILE_DURATION]?.name ?? null,
        },
        {
            id: `${contactResponseFields.CONTACT_DEFINITION}`,
            label: t('app.common.contact.definition'),
            value: data?.[contactResponseFields.CONTACT_DEFINITION]?.name ?? null,
        },
        {
            id: `${contactResponseFields.COUNTRY}`,
            label: t('app.common.country'),
            value: data?.[contactResponseFields.COUNTRY]?.name,
        },
        {
            id: `${contactResponseFields.COUNTY}`,
            label: t('app.common.county'),
            value: data?.[contactResponseFields.COUNTY]?.name,
        },
        {
            id: `${contactResponseFields.CITY}`,
            label: t('app.common.cityMunicipality'),
            value: data?.[contactResponseFields.CITY]?.length
                ? data?.[contactResponseFields.CITY]?.map((item, i) => {
                      return <React.Fragment key={item.id}>{i === 0 ? item.name : `, ${item.name}`}</React.Fragment>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.WIDER_AREA}`,
            label: t('app.common.widerArea'),
            value: data?.[contactResponseFields.WIDER_AREA]?.length
                ? data?.[contactResponseFields.WIDER_AREA]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.QUARTER}`,
            label: t('app.common.quarter'),
            value: data?.[contactResponseFields.QUARTER]?.length
                ? data?.[contactResponseFields.QUARTER]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.ISLAND}`,
            label: t('app.common.island'),
            value: data?.[contactResponseFields.ISLAND]?.length
                ? data?.[contactResponseFields.ISLAND]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PRICE_FROM}_${contactResponseFields.PRICE_TO}`,
            label: t('app.common.price', { currency }),
            value:
                data?.[contactResponseFields.PRICE_FROM] || data?.[contactResponseFields.PRICE_TO] ? (
                    <>
                        {data?.[contactResponseFields.PRICE_FROM] && (
                            <>
                                {t('app.common.from')} {formatNumberWithTwoDecimals(data?.[contactResponseFields.PRICE_FROM])}{' '}
                            </>
                        )}{' '}
                        {data?.[contactResponseFields.PRICE_TO] && (
                            <>
                                {t('app.common.to')} {formatNumberWithTwoDecimals(data?.[contactResponseFields.PRICE_TO])}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${contactResponseFields.PRICE_M2_FROM}_${contactResponseFields.PRICE_M2_TO}`,
            label: t('app.common.pricePerUnit', { currencyWithUnit }),
            value:
                data?.[contactResponseFields.PRICE_M2_FROM] || data?.[contactResponseFields.PRICE_M2_TO] ? (
                    <>
                        {data?.[contactResponseFields.PRICE_M2_FROM] && (
                            <>
                                {t('app.common.from')} {formatNumberWithTwoDecimals(data?.[contactResponseFields.PRICE_M2_FROM])}{' '}
                            </>
                        )}
                        {data?.[contactResponseFields.PRICE_M2_TO] && (
                            <>
                                {t('app.common.to')} {formatNumberWithTwoDecimals(data?.[contactResponseFields.PRICE_M2_TO])}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${contactResponseFields.ROOMS_FROM}_${contactResponseFields.ROOMS_TO}`,
            label: t('view.contact.profile.rooms'),
            value:
                data?.[contactResponseFields.ROOMS_FROM] || data?.[contactResponseFields.ROOMS_TO] ? (
                    <>
                        {data?.[contactResponseFields.ROOMS_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[contactResponseFields.ROOMS_FROM]}{' '}
                            </>
                        )}
                        {data?.[contactResponseFields.ROOMS_TO] && (
                            <>
                                {t('app.common.to')} {data?.[contactResponseFields.ROOMS_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${contactResponseFields.BEDROOMS_FROM}_${contactResponseFields.BEDROOMS_TO}`,
            label: t('app.common.bedrooms'),
            value:
                data?.[contactResponseFields.BEDROOMS_FROM] || data?.[contactResponseFields.BEDROOMS_TO] ? (
                    <>
                        {data?.[contactResponseFields.BEDROOMS_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[contactResponseFields.BEDROOMS_FROM]}{' '}
                            </>
                        )}
                        {data?.[contactResponseFields.BEDROOMS_TO] && (
                            <>
                                {t('app.common.to')} {data?.[contactResponseFields.BEDROOMS_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${contactResponseFields.AREA_FROM}_${contactResponseFields.AREA_TO}`,
            label: t('view.contact.profile.area', { unit }),
            value:
                data?.[contactResponseFields.AREA_FROM] || data?.[contactResponseFields.AREA_TO] ? (
                    <>
                        {data?.[contactResponseFields.AREA_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[contactResponseFields.AREA_FROM]}{' '}
                            </>
                        )}
                        {data?.[contactResponseFields.AREA_TO] && (
                            <>
                                {t('app.common.to')} {data?.[contactResponseFields.AREA_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${contactResponseFields.FLOOR_FROM}_${contactResponseFields.FLOOR_TO}`,
            label: t('app.common.floor'),
            value:
                data?.[contactResponseFields.FLOOR_FROM] || data?.[contactResponseFields.FLOOR_TO] ? (
                    <>
                        {data?.[contactResponseFields.FLOOR_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[contactResponseFields.FLOOR_FROM]}{' '}
                            </>
                        )}
                        {data?.[contactResponseFields.FLOOR_TO] && (
                            <>
                                {t('app.common.to')} {data?.[contactResponseFields.FLOOR_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${contactResponseFields.PROPERTY_FLOOR_POSITION}`,
            label: t('app.common.floorNumber'),
            value: data?.[contactResponseFields.PROPERTY_FLOOR_POSITION]?.length
                ? data?.[contactResponseFields.PROPERTY_FLOOR_POSITION]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PROPERTY_FLOOR}`,
            label: t('app.common.numberOfFloors'),
            value: data?.[contactResponseFields.PROPERTY_FLOOR] ? data?.[contactResponseFields.PROPERTY_FLOOR]?.name : '',
        },
        {
            id: `${contactResponseFields.EXCLUDE_BASEMENT}`,
            label: t('app.common.contact.excludeGroundFloor'),
            value:
                data?.[contactResponseFields.EXCLUDE_BASEMENT] &&
                (data?.[contactResponseFields.EXCLUDE_BASEMENT] === 1 || data?.[contactResponseFields.EXCLUDE_BASEMENT] === 0)
                    ? data?.[contactResponseFields.EXCLUDE_BASEMENT]
                        ? t('view.common.conclusion.yes')
                        : t('view.common.conclusion.no')
                    : '',
        },
        {
            id: `${contactResponseFields.EXCLUDE_LAST_FLOOR}`,
            label: t('app.common.contact.excludeLastFloor'),
            value:
                data?.[contactResponseFields.EXCLUDE_LAST_FLOOR] &&
                (data?.[contactResponseFields.EXCLUDE_LAST_FLOOR] === 1 || data?.[contactResponseFields.EXCLUDE_LAST_FLOOR] === 0)
                    ? data?.[contactResponseFields.EXCLUDE_LAST_FLOOR]
                        ? t('view.common.conclusion.yes')
                        : t('view.common.conclusion.no')
                    : '',
        },
        {
            id: `${contactResponseFields.PROPERTY_SPACE}`,
            label: t('app.common.property.space'),
            value: data?.[contactResponseFields.PROPERTY_SPACE].length
                ? data?.[contactResponseFields.PROPERTY_SPACE]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PROPERTY_DESCRIPTIONS}`,
            label: t('app.common.property.description'),
            value: data?.[contactResponseFields.PROPERTY_DESCRIPTIONS]?.length
                ? data?.[contactResponseFields.PROPERTY_DESCRIPTIONS]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PROPERTY_EQUIPMENT}`,
            label: t('app.common.equipment'),
            value: data?.[contactResponseFields.PROPERTY_EQUIPMENT]?.length
                ? data?.[contactResponseFields.PROPERTY_EQUIPMENT]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PROPERTY_UTILITY}`,
            label: t('app.common.utility'),
            value: data?.[contactResponseFields.PROPERTY_UTILITY]?.length
                ? data?.[contactResponseFields.PROPERTY_UTILITY]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PROPERTY_HEATING}`,
            label: t('app.common.heating'),
            value: data?.[contactResponseFields.PROPERTY_HEATING]?.length
                ? data?.[contactResponseFields.PROPERTY_HEATING]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.PROFILE_REMARK}`,
            label: t('app.common.contact.comment'),
            value: data?.[contactResponseFields.PROFILE_REMARK]?.length
                ? data?.[contactResponseFields.PROFILE_REMARK]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${contactResponseFields.SPECIAL_REQUEST}`,
            label: t('app.common.contact.specialRequests'),
            value: data?.[contactResponseFields.SPECIAL_REQUEST] ?? '',
        },
    ]
}
