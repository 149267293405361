import { useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col, Label, Row } from 'reactstrap'
import { getQueryData } from '../../api/helpers'
import useClickOutside from '../Hooks/useClickOutside'

const Dropzone = ({ callback, tab, module, parent }) => {
    const cardRef = useRef(null)

    const photos = getQueryData(['photo', module, parent])
    const groundPlans = getQueryData(['ground_plan', module, parent])

    const [rejectedFiles, setRejectedFiles] = useState([])
    const [uploadError, setUploadError] = useState(false)

    const isImage = tab === 'photos' || tab === 'groundPlans'

    const maxAllowedPhotos = 40
    const maxAllowedGroundplans = 10

    const { t } = useTranslation()

    useClickOutside(cardRef, setUploadError)

    const { getRootProps, getInputProps } = useDropzone({
        multiple: isImage ? true : false,
        accept: isImage ? 'image/*' : '', // TODO: what kind of documents?
        onDrop: async (acceptedFiles) => {
            const allowUpload = generateUploadCondition(photos?.length, groundPlans?.length, acceptedFiles?.length)

            if (allowUpload) {
                callback(acceptedFiles)
                setUploadError(false)
            } else {
                setUploadError(true)
                return
            }
        },
        onDropRejected: (rejectedFiles) => {
            setRejectedFiles(rejectedFiles)
        },
    })

    const generateUploadCondition = (photosLength, groundPlansLength, acceptedFilesLength) => {
        let allowUpload

        switch (tab) {
            case 'photos':
                allowUpload = photosLength + acceptedFilesLength <= maxAllowedPhotos
                break
            case 'groundPlans':
                allowUpload = groundPlansLength + acceptedFilesLength <= maxAllowedGroundplans
                break
            case 'documents':
                allowUpload = true
                break
            default:
                break
        }

        return allowUpload
    }

    const displayDropzoneMessage = () => {
        switch (tab) {
            case 'photos':
                return t('dropzone.images.photos.label')
            case 'groundPlans':
                return t('dropzone.images.groundPlans.label')
            case 'documents':
                return t('dropzone.documents.label')
            default:
                break
        }
    }

    return (
        <div ref={cardRef}>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={12} className="mb-4">
                            <Label>{displayDropzoneMessage()}</Label>
                            <div {...getRootProps({ className: 'dropzone dz-clickable' })}>
                                <div className="dz-message needsclick">
                                    <input {...getInputProps()} />
                                    <div className="d-flex flex-column">
                                        <i className="fs-36 mdi mdi-cloud-upload-outline" style={{ lineHeight: 1 }} />
                                        <span className="fs-13">{t('dropzone.message')}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {rejectedFiles.length ? (
                            <p className="text-danger text-center">{isImage ? t('dropzone.images.error') : t('dropzone.documents.error')}</p>
                        ) : null}

                        {uploadError ? <p className="text-danger text-center">{t('dropzone.upload.error')}</p> : null}
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default Dropzone
