import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap'

//import images
import logoSm from '../assets/images/dimedia-crm-logo-alt.png'
import logoLight from '../assets/images/dimedia-crm-logo-alt.png'

//import Components
import DetailSearchOption from '../Components/Common/DetailSearchOption'
import FullScreenDropdown from '../Components/Common/FullScreenDropdown'
import LanguageDropdown from '../Components/Common/LanguageDropdown/LanguageDropdown'
import ProfileDropdown from '../Components/Common/ProfileDropdown'
import SearchOption from '../Components/Common/SearchOption/SearchOption'
import HamburgerButton from './HamburgerButton'

const Header = ({ headerClass, btnFunction }) => {
    const [search, setSearch] = useState(false)
    const location = useLocation()

    const toogleSearch = () => {
        setSearch(!search)
    }

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex aling-items-center">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="36" />
                                    </span>
                                </Link>
                            </div>

                            <HamburgerButton btnFunction={btnFunction} />

                            {!location?.pathname.includes('admin') && <SearchOption />}

                            {location?.pathname &&
                                (location.pathname === '/' ||
                                    location.pathname.includes('diary') ||
                                    location.pathname.includes('projects') ||
                                    location.pathname.includes('properties') ||
                                    location.pathname.includes('contacts') ||
                                    location.pathname.includes('companies')) && <DetailSearchOption />}
                        </div>

                        <div className="d-flex align-items-center">
                            <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search ..."
                                                    aria-label="Recipient's username"
                                                />
                                                <button className="btn btn-primary" type="submit">
                                                    <i className="mdi mdi-magnify"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>

                            <FullScreenDropdown />

                            <LanguageDropdown />

                            {/* Dark/Light Mode set */}
                            {/* <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            /> */}

                            {/* <NotificationDropdown /> */}

                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header
