import { ReactElement } from 'react'
import { Col, Row } from 'reactstrap'

/**
 * Creates page title element with optional content on the right (ex. from aaction buttons, breadcrumbs) as children.
 *
 * @param {string} Title Sets value of page title
 *
 *
 * @returns
 */

const PageTitle = ({
    title,
    subtitle,
    titleBadgeComponent,
    children,
    className,
}: {
    title: string
    subtitle?: string
    titleBadgeComponent?: ReactElement
    children?: ReactElement
    className?: string
}) => {
    return (
        <Row>
            <Col xs={12}>
                <div className={`page-title-box d-sm-flex align-items-center justify-content-between ${className}`}>
                    {!titleBadgeComponent ? (
                        <div>
                            <h4 className="mb-sm-0">{title}</h4>
                            {subtitle && <p>{subtitle}</p>}
                        </div>
                    ) : (
                        <h4 className="mb-sm-0">
                            {title} {titleBadgeComponent}
                        </h4>
                    )}

                    {children && <div className="page-title-right">{children}</div>}
                </div>
            </Col>
        </Row>
    )
}

export default PageTitle
