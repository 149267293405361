import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import AppFeaturesSwitcher from '../../Components/AppFeaturesSwitcher/AppFeaturesSwitcher'
import AppPermissionsSwitcher from '../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { selectApplicationPackage } from '../../store/applicationSettings'
import navdata from '../LayoutMenuData'

const HorizontalLayout = (props) => {
    const [isMoreMenu, setIsMoreMenu] = useState(false)
    const [isLinkRendered, setIsLinkRendered] = useState(false)

    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const { applicationPackage } = useApplicationSettings()

    const applicationPackage = useSelector((state) => selectApplicationPackage(state))

    const { name } = applicationPackage ?? {}

    const navData = navdata().props.children
    let menuItems = []
    let splitMenuItems = []
    let menuSplitContainer = 6

    // according to application package different data structure is used for navigation
    const menuData = name !== 'Standard' ? navData : menuItems

    navData.forEach((value, key) => {
        if (value['isHeader']) {
            menuSplitContainer++
        }
        if (key >= menuSplitContainer) {
            let val = value
            val.childItems = value.subItems
            val.isChildItem = value.subItems ? true : false
            delete val.subItems
            splitMenuItems.push(val)
        } else {
            menuItems.push(value)
        }
    })

    if (navData.length >= menuSplitContainer) {
        menuItems.push({
            id: 'more',
            // label: 'Više',
            icon: 'ri-more-fill',
            link: '/#',
            stateVariables: isMoreMenu,
            subItems: splitMenuItems,
            click: function (e) {
                e.preventDefault()
                setIsMoreMenu(!isMoreMenu)
            },
        })
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const initMenu = () => {
            const pathName = process.env.PUBLIC_URL + props.location.pathname
            const ul = document.getElementById('navbar-nav')
            const items = ul.getElementsByTagName('a')
            let itemsArray = [...items] // converts NodeList to Array
            removeActivation(itemsArray)
            let matchingMenuItem = itemsArray.find((x) => {
                // return x.pathname === pathName;
                //tmp fix
                return pathName.includes(x.pathname)
            })
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem)
            }
        }
        initMenu()
    }, [props.location.pathname, isLinkRendered])

    function activateParentDropdown(item) {
        item.classList.add('active')
        let parentCollapseDiv = item.closest('.collapse.menu-dropdown')

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show')
            parentCollapseDiv.parentElement.children[0].classList.add('active')
            parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true')
            if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse').classList.add('show')
                var parentElementDiv = parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling
                if (parentElementDiv) if (parentElementDiv.closest('.collapse')) parentElementDiv.closest('.collapse').classList.add('show')
                parentElementDiv.classList.add('active')
                var parentElementSibling = parentElementDiv.parentElement.parentElement.parentElement.previousElementSibling
                if (parentElementSibling) {
                    parentElementSibling.classList.add('active')
                }
            }
            return false
        }
        return false
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains('active'))

        actiItems.forEach((item) => {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', false)
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show')
                }
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show')
                }
                item.setAttribute('aria-expanded', false)
            }
            item.classList.remove('active')
        })
    }

    const renderNavigation = (item) => {
        return item.subItems ? renderSubMenu(item) : renderMainLinks(item)
    }

    const renderMainLinks = (item) => {
        return (
            <li className="nav-item">
                <AppFeaturesSwitcher link={item.link} feature="Navigation">
                    {({ classes }) => (
                        <Link
                            ref={linkRefCallback}
                            className={classes}
                            to={
                                item.link
                                    ? item.historyState
                                        ? {
                                              pathname: item.link,
                                              state: item.historyState,
                                          }
                                        : item.link
                                    : '/#'
                            }
                        >
                            <i className={item.icon}></i> <span>{item.label}</span>
                        </Link>
                    )}
                </AppFeaturesSwitcher>
            </li>
        )
    }

    // 3 dots menu
    const renderSubMenu = (item) => {
        return (
            <li className="nav-item">
                <AppPermissionsSwitcher subItems={item.subItems} permission="Navigation">
                    {({ updatedSubItems }) => {
                        const hideSubMenu = updatedSubItems.every((item) => !item.isLinkAvailable)

                        return (
                            <>
                                {!hideSubMenu ? (
                                    <Link onClick={item.click} className="nav-link menu-link" to={item.link ? item.link : '/#'}>
                                        <i className={item.icon}></i> <span data-key="t-apps">{item.label}</span>
                                    </Link>
                                ) : null}

                                <Collapse className={'menu-dropdown'} isOpen={item.stateVariables} id="sidebarApps">
                                    <ul className="nav flex-column">
                                        {updatedSubItems?.map((subItem, key) => (
                                            <React.Fragment key={key}>
                                                <li className="nav-item">
                                                    <Link
                                                        to={subItem.link ? subItem.link : '/#'}
                                                        className={subItem.className}
                                                        style={{ color: 'black' }}
                                                        state={subItem.historyState}
                                                    >
                                                        {subItem.label}
                                                    </Link>
                                                </li>
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </Collapse>
                            </>
                        )
                    }}
                </AppPermissionsSwitcher>
            </li>
        )
    }

    const linkRefCallback = (link) => {
        if (link !== null) {
            setIsLinkRendered(true)
        }
    }

    return (
        <React.Fragment>
            {(menuData || []).map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {!item['isHeader'] ? (
                            renderNavigation(item)
                        ) : (
                            <li className="menu-title">
                                <span data-key="t-menu">{item.label}</span>
                            </li>
                        )}
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

HorizontalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(HorizontalLayout)
