import i18next from 'i18next'
import {
    baseCheckboxInput,
    baseDateInput,
    baseMultiLanguageEditorInput,
    baseMultiLanguageTextInput,
    baseMultiSelectInput,
    baseSelectInput,
    baseTextAreaInput,
    baseTextInput,
    getOwnerFieldType,
} from '../fieldTypes'
import { projectDescriptionFields, projectDetailSearchFields, projectGeneralFields, projectNoteFields } from './fields'

export const projectGeneralFieldTypes = (isEdit) => {
    const { t } = i18next

    return {
        [projectGeneralFields.ID]: {
            ...baseTextInput,
            name: projectGeneralFields.ID,
            label: t('app.common.projectId'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_STATUS_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.PROJECT_STATUS_ID,
            label: t('app.common.status'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_SIZE_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.PROJECT_SIZE_ID,
            label: t('app.common.project.size'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_TYPE_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.PROJECT_TYPE_ID,
            label: t('app.common.project.type'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_OBJECT_TYPE_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.PROJECT_OBJECT_TYPE_ID,
            label: t('app.common.project.propertyType'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_DEVELOPMENT_STAGE_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.PROJECT_DEVELOPMENT_STAGE_ID,
            label: t('app.common.project.developmentStage'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_RECEIPT_DATE]: {
            ...baseDateInput,
            colProps: {
                sm: 12,
            },
            name: projectGeneralFields.PROJECT_RECEIPT_DATE,
            label: t('app.common.project.receiptDate'),
        },
        [projectGeneralFields.CONTRACT_NO]: {
            ...baseTextInput,
            name: projectGeneralFields.CONTRACT_NO,
            label: t('app.common.contractNumber'),
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.PROJECT_END_DATE]: {
            ...baseDateInput,
            name: projectGeneralFields.PROJECT_END_DATE,
            label: t('app.common.project.endDate'),
            colProps: {
                sm: 12,
            },
        },
        [projectGeneralFields.CONTRACT_DATE]: {
            ...baseDateInput,
            name: projectGeneralFields.CONTRACT_DATE,
            label: t('app.common.contractDate'),
            colProps: {
                sm: 12,
            },
        },
        ...getOwnerFieldType(projectGeneralFields.OWNER_ID),
        [projectGeneralFields.COUNTRY_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.COUNTRY_ID,
            disabled: isEdit,
            label: t('app.common.country'),
            required: true,
        },
        [projectGeneralFields.COUNTY_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.COUNTY_ID,
            disabled: isEdit,
            label: t('app.common.county'),
            required: true,
        },
        [projectGeneralFields.CITY_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.CITY_ID,
            disabled: isEdit,
            label: t('app.common.cityMunicipality'),
            required: true,
        },
        [projectGeneralFields.WIDER_AREA_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.WIDER_AREA_ID,
            disabled: isEdit,
            label: t('app.common.widerArea'),
            required: true,
        },
        [projectGeneralFields.QUARTER_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.QUARTER_ID,
            disabled: isEdit,
            label: t('app.common.quarter'),
        },
        [projectGeneralFields.ISLAND_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.ISLAND_ID,
            disabled: isEdit,
            label: t('app.common.island'),
        },
        [projectGeneralFields.STREET]: {
            ...baseTextInput,
            name: projectGeneralFields.STREET,
            label: t('app.common.street'),
        },
        [projectGeneralFields.STREET_NUMBER]: {
            ...baseTextInput,
            name: projectGeneralFields.STREET_NUMBER,
            label: t('app.common.streetNumber'),
        },
        [projectGeneralFields.STREET_SYNC]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 2,
                className: 'd-flex align-items-center',
            },
            name: projectGeneralFields.STREET_SYNC,
            label: t('form.common.streetSync.label'),
            tooltip: t('form.common.streetSync.tooltip'),
        },
        [projectGeneralFields.PROJECT_ENERGY_EFFICIENCY_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.PROJECT_ENERGY_EFFICIENCY_ID,
            label: t('app.common.energyEfficiency'),
        },
        [projectGeneralFields.PROJECT_WEB_SYNC]: {
            ...baseCheckboxInput,
            colProps: {
                className: 'd-flex align-items-center',
                sm: 12,
                xxl: 3,
            },
            name: projectGeneralFields.PROJECT_WEB_SYNC,
            label: t('app.common.project.webSync'),
        },
        [projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC]: {
            ...baseCheckboxInput,
            colProps: {
                className: 'd-flex align-items-center',
                sm: 12,
                xxl: 3,
            },
            name: projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC,
            label: t('app.common.project.webNavigationSync'),
        },
        [projectGeneralFields.AGENCY_COMMISSION_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.AGENCY_COMMISSION_ID,
            label: t('app.common.agencyComission'),
            tooltip: t('form.common.agencyComission.tooltip'),
        },
        [projectGeneralFields.AGENT_ID]: {
            ...baseSelectInput,
            name: projectGeneralFields.AGENT_ID,
            label: t('app.common.agent'),
            required: true,
        },
        [projectGeneralFields.ERSTE_REPRESENTATIVE]: {
            ...baseTextInput,
            name: projectGeneralFields.ERSTE_REPRESENTATIVE,
            label: t('app.common.other.ersteRepresentative'),
        },
    }
}

export const projectDescriptionFieldTypes = (appSettings) => {
    const { t } = i18next

    return {
        [projectDescriptionFields.PROJECT_TITLE]: {
            ...baseMultiLanguageTextInput(appSettings.languages),
            name: projectDescriptionFields.PROJECT_TITLE,
            label: t('app.common.project.title'),
            description: t('form.project.description.message'),
            languageStyle: 'tabs',
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '108px' },
            },
            required: true,
            maxLenFeedback: 70,
            hint: t('form.project.description.hint'),
        },
        [projectDescriptionFields.PROJECT_DESCRIPTION]: {
            ...baseMultiLanguageEditorInput(appSettings.languages),
            name: projectDescriptionFields.PROJECT_DESCRIPTION,
            label: t('form.project.description.webField.label'),
            languageStyle: 'tabs',
            colProps: {
                sm: 12,
                xl: 6,
            },
            maxLenFeedback: 2000,
            hint: t('form.project.description.hint'),
        },
        [projectDescriptionFields.PROJECT_OFFER_DESCRIPTION]: {
            ...baseMultiLanguageEditorInput(appSettings.languages),
            name: projectDescriptionFields.PROJECT_OFFER_DESCRIPTION,
            label: t('form.project.description.offerField.label'),
            languageStyle: 'tabs',
            colProps: {
                sm: 12,
                xl: 6,
            },
            maxLenFeedback: 2000,
            hint: t('form.project.description.hint'),
        },
    }
}

export const projectNoteFieldTypes = () => {
    const { t } = i18next

    return {
        [projectNoteFields.NOTE_TITLE]: {
            ...baseTextInput,
            name: projectNoteFields.NOTE_TITLE,
            label: t('app.common.title'),
            colProps: {
                sm: 12,
            },
        },
        [projectNoteFields.NOTE_DATE]: {
            ...baseDateInput,
            name: projectNoteFields.NOTE_DATE,
            label: t('app.common.date'),
            colProps: {
                sm: 12,
            },
        },
        [projectNoteFields.NOTE]: {
            ...baseTextAreaInput,
            name: projectNoteFields.NOTE,
            label: t('form.notes.note'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '127px' },
            },
            maxLenFeedback: 1000,
            hint: t('form.contact.description.hint'),
        },
        [projectNoteFields.PROJECT_NOTE_AGENT_ID]: {
            ...baseSelectInput,
            name: projectNoteFields.PROJECT_NOTE_AGENT_ID,
            label: t('app.common.agent'),
            colProps: {
                sm: 12,
            },
        },
    }
}

export const projectDetailSearchFieldTypes = () => {
    const { t } = i18next

    return {
        [projectDetailSearchFields.ID]: {
            ...baseTextInput,
            name: projectDetailSearchFields.ID,
            label: t('app.common.projectId'),
            colProps: {
                sm: 12,
            },
        },
        [projectDetailSearchFields.PROJECT_TITLE]: {
            ...baseTextInput,
            name: projectDetailSearchFields.PROJECT_TITLE,
            label: t('app.common.project.label'),
            colProps: {
                sm: 12,
            },
        },
        [projectDetailSearchFields.CITY]: {
            ...baseSelectInput,
            name: projectDetailSearchFields.CITY,
            label: t('app.common.cityMunicipality'),
            colProps: {
                sm: 12,
            },
        },
        [projectDetailSearchFields.PROJECT_TYPE]: {
            ...baseSelectInput,
            name: projectDetailSearchFields.PROJECT_TYPE,
            label: t('app.common.project.type'),
            colProps: {
                sm: 12,
            },
        },
        [projectDetailSearchFields.PROJECT_STATUS]: {
            ...baseMultiSelectInput,
            name: projectDetailSearchFields.PROJECT_STATUS,
            label: t('app.common.status'),
            colProps: {
                sm: 12,
            },
        },
        [projectDetailSearchFields.AGENT]: {
            ...baseSelectInput,
            name: projectDetailSearchFields.AGENT,
            label: t('app.common.agent'),
            colProps: {
                sm: 12,
            },
        },
        [projectDetailSearchFields.PROJECT_RECEIPT_DATE_FROM]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
            name: projectDetailSearchFields.PROJECT_RECEIPT_DATE_FROM,
            placeholder: t('app.common.from'),
        },
        [projectDetailSearchFields.PROJECT_RECEIPT_DATE_TO]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
            },
            name: projectDetailSearchFields.PROJECT_RECEIPT_DATE_TO,
            placeholder: t('app.common.to'),
        },
    }
}