import { useRef, useEffect, forwardRef, InputHTMLAttributes } from 'react'

interface TableCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    indeterminate?: boolean
}

const TableCheckbox = forwardRef<HTMLInputElement, TableCheckboxProps>(({ indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>(null)
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        if (resolvedRef && 'current' in resolvedRef && resolvedRef.current) {
            resolvedRef.current.indeterminate = !!indeterminate
        }
    }, [resolvedRef, indeterminate])

    return <input className="form-check-input" type="checkbox" ref={resolvedRef} disabled={disabled} {...rest} />
})

TableCheckbox.displayName = 'TableCheckbox'

export default TableCheckbox
