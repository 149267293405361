import { stateKey } from './actions'

export const selectQuickSearchResults = (state) => state[stateKey].quicksearch
export const selectQuickSearchFetching = (state) => state[stateKey].quicksearchfetching
export const selectQuickSearchCriteria = (state) => state[stateKey]?.searchcriteria
export const selectSearchCriteriaContacts = (state) => {
    let criteria
    if (state[stateKey]?.searchcriteria?.contactCriteria) {
        criteria = state[stateKey]?.searchcriteria?.contactCriteria
    } else if (state[stateKey]?.detailSearchContactCriteria) {
        criteria = state[stateKey]?.detailSearchContactCriteria
    } else {
        criteria = undefined
    }
    return criteria
}
export const selectSearchCriteriaCompanies = (state) => {
    let criteria
    if (state[stateKey]?.searchcriteria?.companyCriteria) {
        criteria = state[stateKey]?.searchcriteria?.companyCriteria
    } else if (state[stateKey]?.detailSearchCompanyCriteria) {
        criteria = state[stateKey]?.detailSearchCompanyCriteria
    } else {
        criteria = undefined
    }
    return criteria
}
export const selectSearchCriteriaProperties = (state) => {
    let criteria
    if (state[stateKey]?.searchcriteria?.propertyCriteria) {
        criteria = state[stateKey]?.searchcriteria?.propertyCriteria
    } else if (state[stateKey]?.detailSearchPropertyCriteria) {
        criteria = state[stateKey]?.detailSearchPropertyCriteria
    } else {
        criteria = undefined
    }
    return criteria
}
export const selectSearchCriteriaProjects = (state) => {
    let criteria
    if (state[stateKey]?.searchcriteria?.projectCriteria) {
        criteria = state[stateKey]?.searchcriteria?.projectCriteria
    } else if (state[stateKey]?.detailSearchProjectCriteria) {
        criteria = state[stateKey]?.detailSearchProjectCriteria
    } else {
        criteria = undefined
    }
    return criteria
}

export const selectSearchCriteriaDiaries = (state) => {
    let criteria
    if (state[stateKey]?.searchcriteria?.diaryCriteria) {
        criteria = state[stateKey]?.searchcriteria?.diaryCriteria
    } else if (state[stateKey]?.detailSearchDiaryCriteria) {
        criteria = state[stateKey]?.detailSearchDiaryCriteria
    } else {
        criteria = undefined
    }
    return criteria
}
