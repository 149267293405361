import classNames from 'classnames'
import { useMemo } from 'react'

export const CharCountFeedback = ({ label, value, max }) => {
    const charsAllowedCount = useMemo(() => {
        const charCount = value?.length || 0
        return max - charCount
    }, [value, max])

    const classes = classNames('fs-6', 'fw-light', 'text-secondary', 'mt-4', {
        'text-danger': charsAllowedCount < 0,
    })

    return (
        <p className={classes}>
            {label} ({charsAllowedCount})
        </p>
    )
}
