import { Redirect } from 'react-router-dom'

import ActivityCalendar from '../pages/Activities/ActivityCalendar'
import AgencyEdit from '../pages/Administration/Agency/AgencyEdit/AgencyEdit'
import AgencyView from '../pages/Administration/Agency/AgencyView/AgencyView'
import OfficeEdit from '../pages/Administration/Office/OfficeEdit/OfficeEdit'
import OfficeList from '../pages/Administration/Office/OfficeList/OfficeList'
import OfficeNew from '../pages/Administration/Office/OfficeNew/OfficeNew'
import UserEdit from '../pages/Administration/User/UserEdit/UserEdit'
import UsersList from '../pages/Administration/User/UsersList/UsersList'
import ForgotPassword from '../pages/Authentication/ForgotPassword'
import Login from '../pages/Authentication/Login'
import Register from '../pages/Authentication/Register'
import ResetPassword from '../pages/Authentication/ResetPassword'
import CompanyCreate from '../pages/Companies/CompanyCreate'
import CompanyEdit from '../pages/Companies/CompanyEdit'
import CompanyList from '../pages/Companies/CompanyList/CompanyList'
import CompanyView from '../pages/Companies/CompanyView'
import ContactCreate from '../pages/Contacts/ContactCreate'
import ContactEdit from '../pages/Contacts/ContactEdit'
import ContactList from '../pages/Contacts/ContactList/ContactList'
import ContactView from '../pages/Contacts/ContactView/ContactView'
import DiaryEdit from '../pages/Diary/DiaryEdit/DiaryEdit'
import DiaryList from '../pages/Diary/DiaryList/DiaryList'
import DocumentList from '../pages/Documents/DocumentList'
import ProjectCreate from '../pages/Projects/ProjectCreate'
import ProjectEdit from '../pages/Projects/ProjectEdit'
import ProjectList from '../pages/Projects/ProjectList/ProjectList'
import ProjectView from '../pages/Projects/ProjectView/ProjectView'
import PropertyCreate from '../pages/Properties/PropertyCreate/PropertyCreate'
import PropertyEdit from '../pages/Properties/PropertyEdit'
import PropertyList from '../pages/Properties/PropertyList/PropertyList'
import PropertyView from '../pages/Properties/PropertyView/PropertyView'
import ReportList from '../pages/Reports/ReportList'

const authProtectedRoutes = [
    // DMN
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/projects" />,
    },

    { path: '/activities', component: ActivityCalendar },

    { path: '/documents', component: DocumentList },
    // { path: '/documents/edit', component: DocumentEdit },

    // projects
    { path: '/projects', component: ProjectList },
    { path: '/projects/new', exact: true, component: ProjectCreate },
    { path: '/projects/:id(\\d+)', exact: true, component: ProjectView },
    { path: '/projects/:id/edit', component: ProjectEdit },

    // contacts route
    { path: '/contacts', component: ContactList },
    { path: '/contacts/new', exact: true, component: ContactCreate },
    { path: '/contacts/:id(\\d+)', exact: true, component: ContactView },
    { path: '/contacts/:id/edit', component: ContactEdit },

    // companies
    { path: '/companies', component: CompanyList },
    { path: '/companies/new', component: CompanyCreate },
    { path: '/companies/:id(\\d+)', component: CompanyView },
    { path: '/companies/:id/edit', component: CompanyEdit },

    // real estates
    { path: '/properties', component: PropertyList },
    { path: '/properties/new/', component: PropertyCreate },
    { path: '/properties/:id/new/', component: PropertyCreate },
    { path: '/properties/:id(\\d+)', component: PropertyView },
    { path: '/properties/:id/edit', component: PropertyEdit },

    //diary
    { path: '/diary', component: DiaryList },
    { path: '/diary/:id/edit', component: DiaryEdit },

    // reports
    {
        path: '/reports/:reportType?',
        component: ReportList,
    },

    // this route should be at the end of all other routes
    /* {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />,
    }, */
]

const adminProtectedRoutes = [
    // Authentication Page
    { path: '/admin', exact: true, component: () => <Redirect to="/admin/agency" /> },
    { path: '/admin/agency', component: AgencyView },
    { path: '/admin/agency/:id/edit', component: AgencyEdit },
    { path: '/admin/office', component: OfficeList },
    { path: '/admin/office/:id/edit', component: OfficeEdit },
    { path: '/admin/office/create', component: OfficeNew },
    { path: '/admin/user', component: UsersList },
    { path: '/admin/user/:id/edit', component: UserEdit },
]

const publicRoutes = [
    // Authentication Page
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/password-recovery', component: ResetPassword },
    { path: '/register', component: Register },
]

export { adminProtectedRoutes, authProtectedRoutes, publicRoutes }
