import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import {
    fetchContactNotes,
    fetchContactNotesFailure,
    fetchContactNotesRequest,
    fetchContactNotesSuccess,
    createContactNote,
    createContactNoteFailure,
    createContactNoteRequest,
    createContactNoteSuccess,
    deleteContactNoteRequest,
    deleteContactNoteSuccess,
    deleteContactNoteFailure,
    deleteContactNote,
    updateContactNoteRequest,
    updateContactNoteSuccess,
    updateContactNoteFailure,
    updateContactNote,
} from './actions'
import QueryString from 'qs'

export function* fetchContactNotesSaga({ payload }) {
    yield put(fetchContactNotesRequest())
    const { id, ...params } = payload
    try {
        const response = yield apiRequest(`v1/contact-note/contact/${id}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchContactNotesSuccess(response.data))
        // meta();
    } catch (error) {
        yield put(fetchContactNotesFailure(error))
    }
}

export function* createContactNoteSaga({ payload, meta }) {
    // console.log({meta})
    yield put(createContactNoteRequest())
    try {
        const response = yield apiRequest(`v1/contact-note/${payload['contact_id']}`, {
            method: 'POST',
            data: payload,
        })
        yield put(createContactNoteSuccess(response))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        // console.log('error', {error})
        yield put(createContactNoteFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* updateContactNoteSaga({ payload, meta }) {
    yield put(updateContactNoteRequest())
    const { id, ...data } = payload
    // console.log('meta', meta)
    try {
        yield apiRequest(`v1/contact-note/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateContactNoteSuccess(payload))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateContactNoteFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* deleteContactNoteSaga({ payload, meta }) {
    yield put(deleteContactNoteRequest())
    // console.log('detele', payload)
    try {
        yield apiRequest(`v1/contact-note/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteContactNoteSuccess(payload))
        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteContactNoteFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchContactNotes}`, fetchContactNotesSaga)
    yield takeEvery(`${createContactNote}`, createContactNoteSaga)
    yield takeEvery(`${updateContactNote}`, updateContactNoteSaga)
    yield takeEvery(`${deleteContactNote}`, deleteContactNoteSaga)
}
