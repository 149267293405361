import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import DeleteModal from '../../../Components/Common/DeleteModal'
import PageTitle from '../../../Components/Common/PageTitle'
import TableContainer from '../../../Components/Table/TableContainer'
import { useFormatTableData } from '../../../Components/Table/utils'
import {
    deleteCompany,
    deleteCompanyMultiple,
    fetchCompanies,
    selectCompanies,
    selectCompaniesCount,
    selectCompaniesFetching,
    selectCompaniesPagination,
} from '../../../store/companies'
import { selectSearchCriteriaCompanies } from '../../../store/search'
import { resetTableCheckbox } from '../../../store/table'
import { selectCheckedTableRows } from '../../../store/table/selectors'
import TableColumns from './TableColumns/TableColumns'

const CompanyList = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [rowId, setRowId] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [pageCount, setPageCount] = useState(0)

    const { pagination, companies, companiesFetching, companiesCount, companySearchCriteria, selectedTableRows } = useSelector((state) => ({
        companies: selectCompanies(state),
        companiesFetching: selectCompaniesFetching(state),
        companySearchCriteria: selectSearchCriteriaCompanies(state),
        companiesCount: selectCompaniesCount(state),
        pagination: selectCompaniesPagination(state),
        selectedTableRows: selectCheckedTableRows(state),
    }))

    const appModule = 'company'

    const onClickDelete = useCallback(
        (id) => {
            setRowId(id)
            setDeleteModal(true)
        },
        [setDeleteModal]
    )

    const handleDeleteCompany = () => {
        dispatch(deleteCompany(rowId))
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleContacts = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)

        dispatch(deleteCompanyMultiple(selectedRowsIds))
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(
            fetchCompanies(
                {
                    page,
                    limit,
                    order,
                    criteria: {
                        ...criteria,
                        contactType: 'company',
                    },
                },
                (count) => setPageCount(Math.ceil(count / limit))
            )
        )
    }

    const handleRowClick = (id) => {
        history.push(`/companies/${id}`)
    }

    const tableData = useFormatTableData(companies, 'companies')
    const columns = TableColumns(onClickDelete, 'companies')

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <Button tag={Link} to="/companies/new" color="add" className="btn-label">
                <i className="mdi mdi-storefront-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                {t('button.add')}
            </Button>
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setDeleteModalMulti(true)} disabled={!selectedTableRows.length}>
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteCompany()} onCloseClick={() => setDeleteModal(false)} />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultipleContacts()
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <PageTitle title={t('app.common.companies')} />
                    <Row>
                        <Col lg={12}>
                            <Card id="RealEstatesList">
                                <CardBody className="">
                                    <div>
                                        <TableContainer
                                            tableActions={tableActions}
                                            hasColumnSelection={true}
                                            columns={columns}
                                            data={tableData || []}
                                            isGlobalFilter={false}
                                            isAddUserList={false}
                                            customPageSize={20}
                                            className="custom-header-css"
                                            theadClass={'dmn-table-header'}
                                            isRealEstatesListFilter={true}
                                            hasPageSizeSelect={true}
                                            isLoading={companiesFetching}
                                            handleDataFetch={handleDataFetch}
                                            handleRowClick={handleRowClick}
                                            totalCount={companiesCount}
                                            pageCount={pageCount}
                                            searchCriteria={companySearchCriteria}
                                            tableHash={appModule}
                                            currentPage={pagination.currentPage}
                                            totalPages={pagination.totalPages}
                                            permissionName={'module.company.view'}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CompanyList
