import { getIn, useFormik } from 'formik'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody, ModalHeader, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { MODAL_TYPES, SUBMIT_RESPONSE_STATUS } from '../../../common/constants'
import { propertyOfferPrintFields } from '../../../common/forms/generalRealEstate/fields'
import { propertyResponseFields } from '../../../common/response/property'
import { useOfferFormConfig, usePropertyPrintFormConfig } from '../../../hooks'
import { hideModal } from '../../../store/modal'
import { createOffer } from '../../../store/offers'
import { fetchTemplatesNoStore } from '../../../store/properties/actions'
import { resetTableCheckbox } from '../../../store/table'
import FormTabNavigation from '../../Common/FormTabNavigation'
import Loader from '../../Common/Loader'
import { useOfferPrintModalTabs } from '../../Hooks/useOfferPrintModalTabs'
import OfferPrintPreviewModal from '../OfferPrintPreviewModal'
import OfferPrintSettingsModal from '../OfferPrintSettingsModal'
import OfferSendModal from '../OfferSendModal'
import OfferPrintTab from './OfferPrintTab'
import { IS_ERSTE } from '../../../common/constants'

const OfferPrintModal = ({ _id, htmlId, isOpen, isFetching, ...modalProps }) => {
    const dispatch = useDispatch()

    const { OFFER_PRINT_MODAL, OFFER_SEND_MODAL } = MODAL_TYPES
    const { properties, propertySelectOptions, selectedTableRows } = modalProps[OFFER_PRINT_MODAL] ?? {}

    const [offerTemplate, setOfferTemplate] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [modalBaseOpen, setModalBaseOpen] = useState(isOpen)
    const [modalOfferOptionsOpen, setModalOfferOptionsOpen] = useState(false)
    const [modalOfferPreviewOpen, setModalOfferPreviewOpen] = useState(false)
    const [modalOfferSendOpen, setModalOfferSendOpen] = useState(false)
    const [selectedContactsForOffer, setSelectedContactsForOffer] = useState([])
    const [addSelectedRows, setAddSelectedRows] = useState(true)

    const { tabs, activeTab, handleTabChange } = useOfferPrintModalTabs()
    const { _inputs, validationConfig } = useOfferFormConfig('property')
    const { inputs: _printInputs, validationConfig: printValidationConfig } = usePropertyPrintFormConfig()

    useEffect(() => {
        dispatch(
            fetchTemplatesNoStore(
                {
                    noLimit: 1,
                },
                (res) => {
                    setOfferTemplate(res)
                    setSelectedTemplate(res.items[0].id)
                }
            )
        )
    }, [])

    useEffect(() => {
        setModalBaseOpen(isOpen)
    }, [isOpen])

    const offerValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            offer: validationConfig.initialValues ?? {},
        },
        validationSchema: Yup.object({
            offer: validationConfig.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const submitValues = {
                ...values.offer,
            }

            dispatch(
                createOffer(submitValues, (responseStatus) => {
                    if (responseStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        setModalOfferOptionsOpen(false)
                        setModalOfferSendOpen(false)
                        dispatch(hideModal({ modalType: OFFER_SEND_MODAL }))
                    }
                })
            )

            dispatch(resetTableCheckbox(true))
        },
    })

    const {
        isValid: _printIsValid,
        isSubmitting: _printIsSubmitting,
        isValidating: _printIsValidation,
        ..._printValidation
    } = useFormik({
        enableReinitialize: true,
        initialValues: printValidationConfig.initialValues ?? {},
        validationSchema: printValidationConfig.validationSchema ?? Yup.object({}),
        onSubmit: (values) => {
            const submitValues = {
                ...values.general,
                property_status_id: 1, // todo change with defaullt when config endpoint is introduced
            }
            delete submitValues.id
        },
    })

    const toggleOfferPrintSettingsModal = () => {
        setModalBaseOpen(!modalBaseOpen)
        setModalOfferOptionsOpen(!modalOfferOptionsOpen)
    }

    const toggleOfferPreviewModal = () => {
        setModalBaseOpen(!modalBaseOpen)
        setModalOfferPreviewOpen(!modalOfferPreviewOpen)
    }

    const toggleOfferSendModal = () => {
        setModalBaseOpen(!modalBaseOpen)
        setModalOfferSendOpen(!modalOfferSendOpen)
    }

    const handleClose = () => {
        const payload = {
            modalType: OFFER_PRINT_MODAL,
        }

        dispatch(hideModal(payload))
        dispatch(resetTableCheckbox(true))
        offerValidation.resetForm()
    }

    const offerSettingsGroup = getIn(offerValidation.values.offer, 'offer_settings_group')

    useEffect(() => {
        if (properties && properties.length) {
            offerValidation?.setFieldValue(
                'offer.offer_settings_group',
                properties?.map((item) => {
                    const foundSettings = offerSettingsGroup?.find((x) => x.property_id === item.id)
                    return {
                        ...foundSettings,
                        [propertyOfferPrintFields.PROPERTY_ID]: item.id,
                        [propertyOfferPrintFields.OFFER_PROPERTY_PRICE]: item.priceOnRequest
                            ? null
                            : item[propertyResponseFields.PRICE]?.toString().replace(/\./g, ','),
                        [propertyOfferPrintFields.OFFER_PROPERTY_PRICEM2]: item.priceOnRequest
                            ? null
                            : item[propertyResponseFields.PRICE_M2]?.toString().replace(/\./g, ','),
                        [propertyOfferPrintFields.OFFER_PROPERTY_AREA]: item[propertyResponseFields.AREA]?.toString()?.replace(/\./g, ','),
                        [propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN]: item[propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN] ?? false,
                        [propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK]: item[propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK] ?? false,
                        [propertyOfferPrintFields.OFFER_INCLUDE_MAP]: item[propertyOfferPrintFields.OFFER_INCLUDE_MAP] ?? IS_ERSTE,
                        [propertyOfferPrintFields.OFFER_EXCLUDE_AGENT]: item[propertyOfferPrintFields.OFFER_EXCLUDE_AGENT] ?? false,
                        [propertyOfferPrintFields.OFFER_EXCLUDE_HEADER]: item[propertyOfferPrintFields.OFFER_EXCLUDE_HEADER] ?? false,
                        [propertyOfferPrintFields.OFFER_PHOTO_ORDER]: item?.photos?.map((x) => x.id).join(',') ?? '',
                        propertyPrice: item[propertyResponseFields.PRICE]?.toString().replace(/\./g, ','),
                        propertyPriceChecked: item.priceOnRequest ? false : true,
                        propertyPriceM2: item[propertyResponseFields.PRICE_M2]?.toString().replace(/\./g, ','),
                        propertyPriceM2Checked: item.priceOnRequest ? false : true,
                        propertyArea: item[propertyResponseFields.AREA]?.toString().replace(/\./g, ','),
                        propertyAreaChecked: item[propertyResponseFields.AREA] ? true : false,
                        agent: item.agent,
                    }
                })
            )
        }
    }, [properties])

    useEffect(() => {
        offerValidation?.setFieldValue(`offer.offer_template`, selectedTemplate)
    }, [selectedTemplate])

    return (
        <>
            <Modal isOpen={modalBaseOpen} id={htmlId}>
                <ModalHeader toggle={handleClose}>
                    <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} customClass="offer-print-modal-tabs" />
                </ModalHeader>
                <ModalBody>
                    <TabContent activeTab={activeTab}>
                        {isFetching && <Loader />}

                        {offerTemplate && offerValidation && properties && (
                            <TabPane tabId="offer">
                                <OfferPrintTab
                                    isOfferView={true}
                                    properties={properties}
                                    offerTemplate={offerTemplate}
                                    selectedTemplate={selectedTemplate}
                                    setSelectedTemplate={setSelectedTemplate}
                                    validation={offerValidation}
                                    toggleSettingsModal={toggleOfferPrintSettingsModal}
                                    togglePreviewModal={toggleOfferPreviewModal}
                                    toggleSendModal={toggleOfferSendModal}
                                    selectedContactsForOffer={selectedContactsForOffer}
                                    setSelectedContactsForOffer={setSelectedContactsForOffer}
                                    selectedTableRows={selectedTableRows}
                                    addSelectedRows={addSelectedRows}
                                    setAddSelectedRows={setAddSelectedRows}
                                />
                            </TabPane>
                        )}

                        <TabPane tabId="print">
                            {offerTemplate && (
                                <OfferPrintTab
                                    isOfferView={false}
                                    properties={properties}
                                    offerTemplate={offerTemplate}
                                    selectedTemplate={selectedTemplate}
                                    setSelectedTemplate={setSelectedTemplate}
                                    validation={offerValidation}
                                    toggleSettingsModal={toggleOfferPrintSettingsModal}
                                    togglePreviewModal={toggleOfferPreviewModal}
                                />
                            )}
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>

            {modalOfferOptionsOpen && (
                <OfferPrintSettingsModal
                    isOpen={modalOfferOptionsOpen}
                    propertySelectOptions={propertySelectOptions}
                    toggle={toggleOfferPrintSettingsModal}
                    validation={offerValidation}
                    properties={properties}
                    subform={'offer'}
                />
            )}

            <OfferPrintPreviewModal isOpen={modalOfferPreviewOpen} toggle={toggleOfferPreviewModal} validation={offerValidation} subform={'offer'} />

            {modalOfferSendOpen && (
                <OfferSendModal
                    isOpen={modalOfferSendOpen}
                    toggle={toggleOfferSendModal}
                    selectedContacts={selectedContactsForOffer}
                    validation={offerValidation}
                    properties={properties}
                    subform={'offer'}
                    type={'property'}
                />
            )}
        </>
    )
}

export default OfferPrintModal
