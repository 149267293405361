import { useFormik } from 'formik'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { SUBMIT_RESPONSE_STATUS } from '../../../../common/constants'
import { useOfferPrintModalTabs } from '../../../../Components/Hooks/useOfferPrintModalTabs'
import ModalWrapper from '../../../../Components/Modals/ModalWrapper/ModalWrapper'
import OfferPrintPreviewModal from '../../../../Components/Modals/OfferPrintPreviewModal'
import OfferSendModal from '../../../../Components/Modals/OfferSendModal'
import { useOfferFormConfig, usePropertyPrintFormConfig } from '../../../../hooks'
import { createOffer } from '../../../../store/offers'
import { fetchTemplatesNoStore } from '../../../../store/projects/actions'
import { resetTableCheckbox } from '../../../../store/table'
import OfferPrintMainModal from './OfferPrintMainModal'

const OfferPrint = (props) => {
    const { data, modalBaseOpen, setModalBaseOpen } = props
    const dispatch = useDispatch()

    const [offerTemplate, setOfferTemplate] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [modalOfferOptionsOpen, setModalOfferOptionsOpen] = useState(false)
    const [modalOfferPreviewOpen, setModalOfferPreviewOpen] = useState(false)
    const [modalOfferSendOpen, setModalOfferSendOpen] = useState(false)
    const [selectedContactsForOffer, setSelectedContactsForOffer] = useState([])
    const { tabs, activeTab, handleTabChange } = useOfferPrintModalTabs()

    const { _inputs, validationConfig } = useOfferFormConfig('project')
    const { inputs: _printInputs, validationConfig: printValidationConfig } = usePropertyPrintFormConfig()

    useEffect(() => {
        dispatch(
            fetchTemplatesNoStore({}, (res) => {
                setOfferTemplate(res)
                setSelectedTemplate(res.items[0].id)
            })
        )
    }, [])

    const offerValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            offer: validationConfig.initialValues ?? {},
        },
        validationSchema: Yup.object({
            offer: validationConfig.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const submitValues = {
                ...values.offer,
            }

            dispatch(
                createOffer(submitValues, (responseStatus) => {
                    if (responseStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        setModalOfferOptionsOpen(false)
                        setModalOfferSendOpen(false)
                        offerValidation.resetForm()
                    }
                })
            )

            dispatch(resetTableCheckbox(true))
            setSelectedContactsForOffer([])
        },
    })

    const {
        isValid: _printIsValid,
        isSubmitting: _printIsSubmitting,
        isValidating: _printIsValidation,
        ..._printValidation
    } = useFormik({
        enableReinitialize: true,
        initialValues: printValidationConfig.initialValues ?? {},
        validationSchema: printValidationConfig.validationSchema ?? Yup.object({}),
        onSubmit: (values) => {
            const submitValues = {
                ...values.general,
                property_status_id: 1, // todo change with defaullt when config endpoint is introduced
            }
            delete submitValues.id
            // console.log('submit values', submitValues)
            // dispatch(createOffer(submitValues, (responseStatus) => {
            //     if(responseStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
            //         setModalOfferOptionsOpen(false);
            //         setModalOfferSendOpen(false);
            //         toggle()
            //     }

            // }))
        },
    })

    const toggleOfferPrintSettingsModal = () => {
        setModalBaseOpen(!modalBaseOpen)
        setModalOfferOptionsOpen(!modalOfferOptionsOpen)
    }

    const toggleOfferPreviewModal = () => {
        setModalBaseOpen(!modalBaseOpen)
        setModalOfferPreviewOpen(!modalOfferPreviewOpen)
    }

    const toggleOfferSendModal = () => {
        setModalBaseOpen(!modalBaseOpen)
        setModalOfferSendOpen(!modalOfferSendOpen)
    }

    const handleModalClose = () => {
        setModalBaseOpen(!modalBaseOpen)
        dispatch(resetTableCheckbox(true))
        setSelectedContactsForOffer([])
        offerValidation.resetForm()
    }

    useEffect(() => {
        if (data && data.length) {
            const newOfferSettings = []

            for (const item of data) {
                const selectedProject = { project_id: item?.id }
                newOfferSettings.push(selectedProject)
            }
            offerValidation?.setFieldValue(`offer.offer_settings_group`, [...newOfferSettings])
        }
    }, [data])

    useEffect(() => {
        offerValidation?.setFieldValue(`offer.offer_template`, selectedTemplate)
    }, [selectedTemplate])

    return (
        <>
            <ModalWrapper open={modalBaseOpen} onClose={handleModalClose}>
                <OfferPrintMainModal
                    offerTemplate={offerTemplate}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    offerValidation={offerValidation}
                    data={data}
                    toggleOfferPrintSettingsModal={toggleOfferPrintSettingsModal}
                    toggleOfferPreviewModal={toggleOfferPreviewModal}
                    toggleOfferSendModal={toggleOfferSendModal}
                    selectedContactsForOffer={selectedContactsForOffer}
                    setSelectedContactsForOffer={setSelectedContactsForOffer}
                    tabs={tabs}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                />
            </ModalWrapper>

            <OfferPrintPreviewModal isOpen={modalOfferPreviewOpen} toggle={toggleOfferPreviewModal} validation={offerValidation} subform={'offer'} />

            {modalOfferSendOpen && (
                <OfferSendModal
                    isOpen={modalOfferSendOpen}
                    toggle={toggleOfferSendModal}
                    selectedContacts={selectedContactsForOffer}
                    validation={offerValidation}
                    properties={data}
                    subform={'offer'}
                    type={'project'}
                />
            )}
        </>
    )
}

export default OfferPrint
