import { Editor } from '@tinymce/tinymce-react'
import { useEffect, useState } from 'react'

const RichTextEditor = ({ content, height, handleCarousel, preserveEditedContent, formik, fieldName, lang }) => {
    const [newContent, setNewContent] = useState(content)

    useEffect(() => {
        setNewContent(content ?? '')
    }, [content])

    const handleContentChange = (newContent) => {
        setNewContent(newContent)
    }

    return (
        <div>
            <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                value={newContent}
                onEditorChange={handleContentChange}
                init={{
                    license_key: 'gpl',
                    height: height || 500,
                    paste_as_text: true, // sets all pasted content as plain text
                    menubar: false,
                    // menubar: 'file edit view insert format tools table help print',
                    // plugins: [],
                    toolbar: `
                        bold italic underline | forecolor backcolor |
                        alignleft aligncenter alignright alignjustify |
                        print | help
                    `,
                    content_css: '/styles/styles.css',
                    setup: (editor) => {
                        editor.on('click', (e) => {
                            const target = e.target.closest('.img-container img')

                            if (target) {
                                const photoIndex = e.target.getAttribute('data-photo-index')
                                const propertyId = e.target.getAttribute('data-property-id')
                                handleCarousel(photoIndex, propertyId)
                            }
                        })

                        editor.on('input', () => {
                            const content = editor.getContent()

                            if (preserveEditedContent) {
                                preserveEditedContent(content)
                            }

                            if (fieldName && lang) {
                                formik?.setFieldValue(`${fieldName}.${lang}`, content)
                            }
                        })

                        editor.on('change', () => {
                            const content = editor.getContent()

                            formik?.setFieldValue(`${fieldName}.${lang}`, content)
                        })

                        // editor.on('blur', () => {
                        //     const content = editor.getContent()

                        //     formik?.setFieldValue(`${fieldName}.${lang}`, content)
                        // })
                    },
                }}
            />
        </div>
    )
}

export default RichTextEditor
