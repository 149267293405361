import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Route from './Routes'
import './assets/scss/themes.scss'
import { selectApplicationLanguage, selectMainLanguage } from './store/auth/selectors'

function App() {
    const location = useLocation()
    const { t, i18n } = useTranslation()

    // applicationLanguage - user selected lang
    // mainLanguage - agency default lang
    const { applicationLanguage, mainLanguage } = useSelector((state) => ({
        applicationLanguage: selectApplicationLanguage(state),
        mainLanguage: selectMainLanguage(state),
    }))

    const appLangLocale = applicationLanguage?.abbreviation
    const mainLangLocale = mainLanguage?.abbreviation
    const defaultLang = appLangLocale ?? mainLangLocale

    useEffect(() => {
        const [, page] = location.pathname.split('/')
        const translation = i18n.exists(`page.${page}`)
        const pageTitle = page && translation ? `${t(`page.${page}`)} |` : ''
        document.title = `${pageTitle} ${t('app.title')}`
    }, [t, location, i18n])

    useEffect(() => {
        if (defaultLang) {
            i18n.changeLanguage(defaultLang)
            localStorage.setItem('i18nextLng', defaultLang)
        }
    }, [defaultLang, i18n])

    // application global data
    // TODO: commented out until sagaMiddleware is refactored
    // useFetchApplicationSettings()

    return (
        <React.Fragment>
            <Route />
            <ToastContainer closeButton={false} draggable autoClose={3000} />
        </React.Fragment>
    )
}

export default App
