import { useContext } from 'react'
import { Card, CardBody, Row } from 'reactstrap'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import RecursiveForm from '../RecursiveForm'
import ContactFormOtherInfoContext from './ContactFormOtherInfoContext'

const ContactFormOtherInfoTab = ({ children, ...props }) => {
    return (
        <ContactFormOtherInfoContext.Provider
            value={{
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                selectOptions: props.selectOptions,
                inputComponents: props.inputComponents,
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
            }}
        >
            <Row>{children}</Row>
        </ContactFormOtherInfoContext.Provider>
    )
}

export const ContactFormOtherInfoTabSide = () => {
    const { inputs, formik, subform, selectOptions, inputComponents } = useContext(ContactFormOtherInfoContext)

    return (
        <FormSideContainer>
            <Card>
                <CardBody>
                    <RecursiveForm
                        inputs={inputs?.sideForm}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />
                </CardBody>
            </Card>
        </FormSideContainer>
    )
}

export const ContactFormOtherInfoTabMain = () => {
    const { inputs, formik, subform, selectOptions, inputComponents, tabs, activeTab, handleTabChange } = useContext(ContactFormOtherInfoContext)

    return (
        <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <RecursiveForm
                inputs={inputs?.mainForm}
                subform={subform}
                formik={formik}
                selectOptions={selectOptions}
                inputComponents={inputComponents}
            />
        </FormMainContainer>
    )
}

export default ContactFormOtherInfoTab
