import { useEffect, useRef } from 'react'
import { TableShowHideTextTypes } from './TableShowHideText.types'
import styles from './TableShowHideText.module.css'

const TableShowHideText = (props: TableShowHideTextTypes) => {
    const { text, rowId, numberOfRows } = props
    const style = useRef<string>()
    const tooltip = useRef<HTMLDivElement>(null)
    const tooltipParent = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const tooltipOffsetHeight = tooltip.current?.offsetHeight ?? 0
        const tooltipParentOffsetHeight = tooltipParent.current?.offsetHeight ?? 0

        style.current =
            tooltipOffsetHeight > tooltipParentOffsetHeight
                ? `${styles.hideText} ${+rowId > numberOfRows / 2 && styles.hideTextSecondHalf}`
                : undefined
    }, [numberOfRows, rowId])

    return (
        <div ref={tooltipParent} className={styles.tableCellTooltip}>
            <div ref={tooltip} className={style.current}>
                {text}
            </div>
        </div>
    )
}

export default TableShowHideText
