import QueryString from 'qs'
import { put, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import {
    fetchDetailSearchLocationsCity,
    fetchDetailSearchLocationsCityFailure,
    fetchDetailSearchLocationsCityRequest,
    fetchDetailSearchLocationsCitySuccess,
    fetchDetailSearchLocationsCountry,
    fetchDetailSearchLocationsCountryFailure,
    fetchDetailSearchLocationsCountryRequest,
    fetchDetailSearchLocationsCountrySuccess,
    fetchDetailSearchLocationsCounty,
    fetchDetailSearchLocationsCountyFailure,
    fetchDetailSearchLocationsCountyRequest,
    fetchDetailSearchLocationsCountySuccess,
    fetchDetailSearchLocationsIsland,
    fetchDetailSearchLocationsIslandFailure,
    fetchDetailSearchLocationsIslandRequest,
    fetchDetailSearchLocationsIslandSuccess,
    fetchDetailSearchLocationsQuarter,
    fetchDetailSearchLocationsQuarterFailure,
    fetchDetailSearchLocationsQuarterRequest,
    fetchDetailSearchLocationsQuarterSuccess,
    fetchDetailSearchLocationsWiderArea,
    fetchDetailSearchLocationsWiderAreaFailure,
    fetchDetailSearchLocationsWiderAreaRequest,
    fetchDetailSearchLocationsWiderAreaSuccess,
    fetchDetailSearchPropertySubtypes,
    fetchDetailSearchPropertySubtypesFailure,
    fetchDetailSearchPropertySubtypesRequest,
    fetchDetailSearchPropertySubtypesSuccess,
    fetchDetailSearchPropertyTypes,
    fetchDetailSearchPropertyTypesFailure,
    fetchDetailSearchPropertyTypesRequest,
    fetchDetailSearchPropertyTypesSuccess,
    fetchDetailSearchPropertyValuesDescriptions,
    fetchDetailSearchPropertyValuesDescriptionsFailure,
    fetchDetailSearchPropertyValuesDescriptionsRequest,
    fetchDetailSearchPropertyValuesDescriptionsSuccess,
    fetchDetailSearchPropertyValuesEquipment,
    fetchDetailSearchPropertyValuesEquipmentFailure,
    fetchDetailSearchPropertyValuesEquipmentRequest,
    fetchDetailSearchPropertyValuesEquipmentSuccess,
    fetchDetailSearchPropertyValuesFloor,
    fetchDetailSearchPropertyValuesFloorFailure,
    fetchDetailSearchPropertyValuesFloorRequest,
    fetchDetailSearchPropertyValuesFloorSuccess,
    fetchDetailSearchPropertyValuesSpace,
    fetchDetailSearchPropertyValuesSpaceFailure,
    fetchDetailSearchPropertyValuesSpaceRequest,
    fetchDetailSearchPropertyValuesSpaceSuccess,
    fetchDetailSearchPropertyValuesUtility,
    fetchDetailSearchPropertyValuesUtilityFailure,
    fetchDetailSearchPropertyValuesUtilityRequest,
    fetchDetailSearchPropertyValuesUtilitySuccess,
} from './actions'

function defineLocationParam(payload, defaultFilter = 'country') {
    let isAdditionalOptions = false
    let params = {}
    if (payload) {
        if (typeof payload === 'object' && !Array.isArray(payload) && payload !== null) {
            const { additionalOptions, ...rest } = payload
            if (additionalOptions) {
                isAdditionalOptions = true
            }
            params = {
                filter: rest,
            }
        } else {
            params = {
                filter: {
                    [defaultFilter]: payload,
                },
            }
        }
    }
    return {
        params,
        isAdditionalOptions,
    }
}

export function* fetchDetailSearchLocationsCountrySaga() {
    yield put(fetchDetailSearchLocationsCountryRequest())
    try {
        const response = yield apiRequest('v1/core/locations/values/country', {
            method: 'GET',
        })
        yield put(fetchDetailSearchLocationsCountrySuccess(response.data))
    } catch (error) {
        yield put(fetchDetailSearchLocationsCountryFailure(error))
    }
}

export function* fetchDetailSearchLocationsCountySaga({ payload }) {
    yield put(fetchDetailSearchLocationsCountyRequest())

    const { params, isAdditionalOptions } = defineLocationParam(payload, 'country')

    try {
        const response = yield apiRequest(`v1/core/locations/values/county`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(
            fetchDetailSearchLocationsCountySuccess({
                data: response.data,
                isAdditionalOptions,
            })
        )
    } catch (error) {
        yield put(fetchDetailSearchLocationsCountyFailure(error))
    }
}

export function* fetchDetailSearchLocationsCitySaga({ payload }) {
    yield put(fetchDetailSearchLocationsCityRequest())
    const { params, isAdditionalOptions } = defineLocationParam(payload, 'county')
    try {
        const response = yield apiRequest(`v1/core/locations/values/city`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(
            fetchDetailSearchLocationsCitySuccess({
                data: response.data,
                isAdditionalOptions,
            })
        )
    } catch (error) {
        yield put(fetchDetailSearchLocationsCityFailure(error))
    }
}

export function* fetchDetailSearchLocationsIslandSaga({ payload }) {
    yield put(fetchDetailSearchLocationsIslandRequest())
    const { params, isAdditionalOptions } = defineLocationParam(payload, 'country')
    try {
        const response = yield apiRequest(`v1/core/locations/values/island`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(
            fetchDetailSearchLocationsIslandSuccess({
                isAdditionalOptions,
                data: response.data,
            })
        )
    } catch (error) {
        yield put(fetchDetailSearchLocationsIslandFailure(error))
    }
}

export function* fetchDetailSearchLocationsWiderAreaSaga({ payload }) {
    yield put(fetchDetailSearchLocationsWiderAreaRequest())
    const { params, isAdditionalOptions } = defineLocationParam(payload, 'city')
    try {
        const response = yield apiRequest(`v1/core/locations/values/widerArea`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(
            fetchDetailSearchLocationsWiderAreaSuccess({
                isAdditionalOptions,
                data: response.data,
            })
        )
    } catch (error) {
        yield put(fetchDetailSearchLocationsWiderAreaFailure(error))
    }
}

export function* fetchDetailSearchLocationsQuarterSaga({ payload }) {
    yield put(fetchDetailSearchLocationsQuarterRequest())
    const { params, isAdditionalOptions } = defineLocationParam(payload, 'widerArea')
    try {
        const response = yield apiRequest(`v1/core/locations/values/quarter`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(
            fetchDetailSearchLocationsQuarterSuccess({
                isAdditionalOptions,
                data: response.data,
            })
        )
    } catch (error) {
        yield put(fetchDetailSearchLocationsQuarterFailure(error))
    }
}

export function* fetchDetailSearchPropertySubtypesSaga({ payload }) {
    yield put(fetchDetailSearchPropertySubtypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDetailSearchPropertySubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchDetailSearchPropertySubtypesFailure(error))
    }
}

export function* fetchDetailSearchPropertyValuesDescriptionsSaga({ payload }) {
    yield put(fetchDetailSearchPropertyValuesDescriptionsRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyDescriptions', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDetailSearchPropertyValuesDescriptionsSuccess(response.data))
    } catch (error) {
        yield put(fetchDetailSearchPropertyValuesDescriptionsFailure(error))
    }
}

export function* fetchDetailSearchPropertyValuesUtilitySaga({ payload }) {
    yield put(fetchDetailSearchPropertyValuesUtilityRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyUtility', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDetailSearchPropertyValuesUtilitySuccess(response.data))
    } catch (error) {
        yield put(fetchDetailSearchPropertyValuesUtilityFailure(error))
    }
}

export function* fetchDetailSearchPropertyValuesSpaceSaga({ payload }) {
    yield put(fetchDetailSearchPropertyValuesSpaceRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertySpace', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDetailSearchPropertyValuesSpaceSuccess(response.data))
    } catch (error) {
        yield put(fetchDetailSearchPropertyValuesSpaceFailure(error))
    }
}

export function* fetchDetailSearchPropertyValuesEquipmentSaga({ payload }) {
    yield put(fetchDetailSearchPropertyValuesEquipmentRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyEquipment', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDetailSearchPropertyValuesEquipmentSuccess(response.data))
    } catch (error) {
        yield put(fetchDetailSearchPropertyValuesEquipmentFailure(error))
    }
}

export function* fetchDetailSearchPropertyTypesSaga() {
    yield put(fetchDetailSearchPropertyTypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertytype`, {
            method: 'GET',
        })
        yield put(fetchDetailSearchPropertyTypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchDetailSearchPropertyTypesFailure(error))
    }
}

export function* fetchDetailSearchPropertyValuesFloorSaga({ payload }) {
    yield put(fetchDetailSearchPropertyValuesFloorRequest())
    try {
        const response = yield apiRequest('v1/property/values/propertyFloor', {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDetailSearchPropertyValuesFloorSuccess(response.data))
    } catch (error) {
        yield put(fetchDetailSearchPropertyValuesFloorFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchDetailSearchLocationsCountry}`, fetchDetailSearchLocationsCountrySaga)
    yield takeLatest(`${fetchDetailSearchLocationsCounty}`, fetchDetailSearchLocationsCountySaga)
    yield takeLatest(`${fetchDetailSearchLocationsCity}`, fetchDetailSearchLocationsCitySaga)
    yield takeLatest(`${fetchDetailSearchLocationsWiderArea}`, fetchDetailSearchLocationsWiderAreaSaga)
    yield takeLatest(`${fetchDetailSearchLocationsQuarter}`, fetchDetailSearchLocationsQuarterSaga)
    yield takeLatest(`${fetchDetailSearchLocationsIsland}`, fetchDetailSearchLocationsIslandSaga)

    yield takeLatest(`${fetchDetailSearchPropertySubtypes}`, fetchDetailSearchPropertySubtypesSaga)
    yield takeLatest(`${fetchDetailSearchPropertyValuesDescriptions}`, fetchDetailSearchPropertyValuesDescriptionsSaga)
    yield takeLatest(`${fetchDetailSearchPropertyValuesUtility}`, fetchDetailSearchPropertyValuesUtilitySaga)
    yield takeLatest(`${fetchDetailSearchPropertyValuesSpace}`, fetchDetailSearchPropertyValuesSpaceSaga)
    yield takeLatest(`${fetchDetailSearchPropertyValuesEquipment}`, fetchDetailSearchPropertyValuesEquipmentSaga)
    yield takeLatest(`${fetchDetailSearchPropertyTypes}`, fetchDetailSearchPropertyTypesSaga)
    yield takeLatest(`${fetchDetailSearchPropertyValuesFloor}`, fetchDetailSearchPropertyValuesFloorSaga)
}
