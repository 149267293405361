import { FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Container, Form, Modal, ModalBody, ModalHeader, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import PageTitle from '../../Components/Common/PageTitle'
import CompanyFormGeneralTab, {
    CompanyFormGeneralTabMain,
    CompanyFormGeneralTabSide,
} from '../../Components/CompanyFormGeneralTab/CompanyFormGeneralTab'
import { useCompanyGeneralFormConfig, useCompanyGeneralFormSelectOptions } from '../../Components/Hooks/FormHooks'
import useCompanyFormTabs from '../../Components/Hooks/useCompanyTabs'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import { createCompany } from '../../store/companies'

const CompanyCreate = () => {
    const history = useHistory()
    const tabs = useCompanyFormTabs(false)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(null)
    const { generalInputs, generalValidationConfig } = useCompanyGeneralFormConfig()
    const formSelectOptions = useCompanyGeneralFormSelectOptions()
    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].name)
        }
    }, [tabs])
    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig.initialValues ?? {},
            // description: descriptionInitialValues
        },
        validationSchema: Yup.object({
            general: generalValidationConfig.validationSchema ?? Yup.object({}),
            // description: descriptionSchema
        }),
        onSubmit: (values) => {
            console.log('submiting')
            console.log('values', values)
            const submitValues = {
                ...generalValidationConfig.prepareModelForSubmit(values.general),
                contact_type: 'company',
            }
            delete submitValues.id
            dispatch(
                createCompany(submitValues, (response) => {
                    if (response.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        console.log('response', response)
                        history.push(`/companies/${response.data.id}/edit`)
                    }
                })
            )
            // console.log('submit values', submitValues)
        },
    })

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/companies')} />

    const [modal_standard, setmodal_standard] = useState(false)
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)
    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    // console.log('config validation', generalInputs)
    // console.log('formik errors', validation.errors, validation.touched)
    // console.log('validations', validation.values)
    // console.log(transactionFieldsConfig)
    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title="Novo poduzeće">{renderFormButtons()}</PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <CompanyFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                >
                                    <CompanyFormGeneralTabSide />
                                    <CompanyFormGeneralTabMain />
                                </CompanyFormGeneralTab>
                            </TabPane>
                        </TabContent>

                        {/* <Row>
                        <Col sm={12} xxl={3}>

                            <TabContent activeTab={activeTab} className="text-muted">
                                <TabPane tabId="general">
                                    <RecursiveForm inputs={formInputs?.sideForm} subform='general' formik={validation} selectOptions={formSelectOptions} inputComponents={formExtraComponenets} />
                                </TabPane>
                            </TabContent>

                        </Col>
                        <Col sm={12} xxl={9}>
                            <Card>
                                <CardHeader>
                                    <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
                                </CardHeader>
                                <CardBody>
                                    <TabContent activeTab={activeTab} className="text-muted">
                                        <TabPane tabId="general">
                                            {(realEstateType && realEstateService) ? (
                                                <RecursiveForm inputs={formInputs?.mainForm} formik={validation} selectOptions={formSelectOptions} />
                                            ) : (
                                                <Row>
                                                    <Col>
                                                        <p className="text-center">Molimo odaberite tip i uslugu. </p>
                                                    </Col>
                                                </Row>
                                            )}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                            {renderFormButtons()}
                        </Col>
                    </Row> */}
                    </Form>
                </FormikProvider>
            </Container>
            <Modal
                id="myModal"
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard()
                }}
            >
                <ModalHeader
                    className="modal-title"
                    id="myModalLabel"
                    toggle={() => {
                        tog_standard()
                    }}
                >
                    Modal Heading
                </ModalHeader>
                <ModalBody>
                    <h5 className="fs-15">Overflowing text to show scroll behavior</h5>
                    <p className="text-muted">
                        One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He
                        lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by
                        arches into stiff sections.
                    </p>
                    <p className="text-muted">
                        The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with
                        the size of the rest of him, waved about helplessly as he looked. "What's happened to me?" he thought.
                    </p>
                    <p className="text-muted">
                        It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls.
                    </p>
                </ModalBody>
                <div className="modal-footer">
                    <Button
                        color="light"
                        onClick={() => {
                            tog_standard()
                        }}
                    >
                        Close
                    </Button>
                    <Button color="primary">Save changes</Button>
                </div>
            </Modal>
            {isErrorModalActive && (
                <FormValidationErrorModal validation={validation} inputs={[generalInputs]} onClose={() => toggleErrorModal(false)} />
            )}
        </div>
    )
}

export default CompanyCreate
