import { handleActions } from 'redux-actions'
import { PROJECT_STATUS, PROPERTY_STATUS } from '../../common/constants'
import { propertyDetailSearchFields } from '../../common/forms/generalRealEstate/fields'
import { projectDetailSearchFields } from '../../common/forms/projects/fields'
import {
    clearDetailSearchCompaniesCriteria,
    clearDetailSearchContactsCriteria,
    clearDetailSearchDiaryCriteria,
    clearDetailSearchProjectsCriteria,
    clearDetailSearchPropertiesCriteria,
    fetchQuickSearchClear,
    fetchQuickSearchFailure,
    fetchQuickSearchRequest,
    fetchQuickSearchSuccess,
    setDetailSearchCompaniesCriteria,
    setDetailSearchConctactsCriteria,
    setDetailSearchDiaryCriteria,
    setDetailSearchProjectsCriteria,
    setDetailSearchPropertiesCriteria,
} from './actions'

const initialProjectCriteria = {
    [projectDetailSearchFields.PROJECT_STATUS]: [
        PROJECT_STATUS.PREPARATION.toString(),
        PROJECT_STATUS.FOR_AUTHORIZATION.toString(),
        PROJECT_STATUS.ACTIVE.toString(),
    ],
}

const initialPropertyCriteria = {
    [propertyDetailSearchFields.PROPERTY_STATUS]: [PROPERTY_STATUS.ACTIVE_OFFER.toString(), PROPERTY_STATUS.FOR_AUTHORIZATION.toString()],
}

const initialContactCriteria = {
    /* [contactDetailSearchFields.CONTACT_STATUS]: [
        CONTACT_STATUS.IN_ENTRY.toString(),
        CONTACT_STATUS.HOT_LEAD.toString(),
        CONTACT_STATUS.ON_HOLD.toString(),
        CONTACT_STATUS.NO_OFFER.toString(),
        CONTACT_STATUS.NO_OFFER_OUT_OF_ZG.toString(),
        CONTACT_STATUS.ACCEPTANCE_IN_OFFER.toString(),
    ], */
}

const initialCompanyCriteria = {
    /* [responseFields.CONTACT_STATUS]: [
        CONTACT_STATUS.IN_ENTRY.toString(),
        CONTACT_STATUS.HOT_LEAD.toString(),
        CONTACT_STATUS.ON_HOLD.toString(),
        CONTACT_STATUS.NO_OFFER.toString(),
        CONTACT_STATUS.NO_OFFER_OUT_OF_ZG.toString(),
        CONTACT_STATUS.ACCEPTANCE_IN_OFFER.toString(),
    ], */
}

export const initialState = {
    quicksearch: {},
    searchcriteria: null,
    count: 0,
    quicksearchfetching: false,
    detailSearchProjectCriteria: initialProjectCriteria,
    detailSearchPropertyCriteria: initialPropertyCriteria,
    detailSearchContactCriteria: initialContactCriteria,
    detailSearchCompanyCriteria: initialCompanyCriteria,
}

export default handleActions(
    {
        [fetchQuickSearchRequest]: (state) => ({
            ...state,
            quicksearchfetching: true,
            error: null,
        }),
        [fetchQuickSearchSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            quicksearch: payload.results,
            searchcriteria: payload?.criteria,
            quicksearchfetching: false,
            detailSearchProjectCriteria: null,
            detailSearchContactCriteria: null,
            detailSearchCompanyCriteria: null,
            detailSearchPropertyCriteria: null,
        }),
        [fetchQuickSearchFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            quicksearchfetching: false,
            detailSearchProjectCriteria: null,
            detailSearchContactCriteria: null,
            detailSearchCompanyCriteria: null,
            detailSearchPropertyCriteria: null,
        }),
        [fetchQuickSearchClear]: (state) => {
            return {
                ...state,
                quicksearch: {},
                searchcriteria: {},
            }
        },
        [setDetailSearchProjectsCriteria]: (state, { payload }) => ({
            ...state,
            detailSearchProjectCriteria: payload,
        }),
        [clearDetailSearchProjectsCriteria]: (state) => ({
            ...state,
            detailSearchProjectCriteria: initialProjectCriteria,
        }),
        [setDetailSearchDiaryCriteria]: (state, { payload }) => ({
            ...state,
            detailSearchDiaryCriteria: payload,
        }),
        [clearDetailSearchDiaryCriteria]: (state) => ({
            ...state,
            detailSearchDiaryCriteria: null,
        }),
        [setDetailSearchPropertiesCriteria]: (state, { payload }) => {
            return {
                ...state,
                detailSearchPropertyCriteria: payload,
            }
        },
        [clearDetailSearchPropertiesCriteria]: (state) => ({
            ...state,
            detailSearchPropertyCriteria: initialPropertyCriteria,
        }),
        [setDetailSearchConctactsCriteria]: (state, { payload }) => {
            let criteria = payload
            if (payload?.contact && payload?.contactProfile) {
                criteria = {
                    ...payload.contact,
                    contactProfile: {
                        ...payload.contactProfile,
                        ...(payload.contact.contactProfileService
                            ? {
                                  contactProfileService: payload.contact.contactProfileService,
                              }
                            : {}),
                        ...(payload.contact.contactProfileStatus
                            ? {
                                  contactProfileStatus: payload.contact.contactProfileStatus,
                              }
                            : {}),
                    },
                }

                delete criteria.contactProfileStatus
                delete criteria.contactProfileService
            }
            return {
                ...initialState,
                detailSearchContactCriteria: criteria,
            }
        },
        [clearDetailSearchContactsCriteria]: (state) => ({
            ...state,
            detailSearchContactCriteria: initialContactCriteria,
        }),
        [setDetailSearchCompaniesCriteria]: (state, { payload }) => {
            let criteria = payload
            if (payload?.contact && payload?.contactProfile) {
                criteria = {
                    ...payload.contact,
                    contactProfile: {
                        ...payload.contactProfile,
                        ...(payload.contact.contactProfileService
                            ? {
                                  contactProfileService: payload.contact.contactProfileService,
                              }
                            : {}),
                        ...(payload.contact.contactProfileStatus
                            ? {
                                  contactProfileStatus: payload.contact.contactProfileStatus,
                              }
                            : {}),
                    },
                }

                delete criteria.contactProfileStatus
                delete criteria.contactProfileService
            }
            return {
                ...initialState,
                detailSearchCompanyCriteria: criteria,
            }
        },
        [clearDetailSearchCompaniesCriteria]: (state) => ({
            ...state,
            detailSearchCompanyCriteria: initialCompanyCriteria,
        }),
    },
    initialState
)
