import { Col, FormFeedback, Label, UncontrolledTooltip } from 'reactstrap'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.in'
import classNames from 'classnames'

export const PriceInput = ({
    inputField,
    isFieldArray,
    inputValue,
    formik,
    fieldName,
    error,
    touched,
    inputComponents,
    fieldArrayDeleteComponent,
    index,
    configLength,
    inputMargin,
}) => {
    return (
        <>
            <Col {...inputField.colProps} className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}>
                {inputField.label && (
                    <div className="d-flex justify-content-between">
                        <Label for={fieldName} id={`${inputField.name}Lbl`}>
                            {inputField.label}
                            {inputField.required && <span className="required-mark">*</span>}
                        </Label>
                        {inputField.tooltip && (
                            <span id={`${inputField.name}Tooltip`}>
                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                            </span>
                        )}
                    </div>
                )}

                <Cleave
                    placeholder={inputField.placeholder}
                    options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        delimiter: '.',
                    }}
                    value={inputValue || ''}
                    onChange={(e) => {
                        formik?.setFieldValue(fieldName, e.target.value)
                        if (inputField.handleChange) {
                            inputField.handleChange(e.value)
                        }
                    }}
                    onBlur={formik?.handleBlur}
                    name={fieldName}
                    disabled={inputField.disabled}
                    id={inputField.name}
                    className={classNames('form-control', {
                        'is-invalid': error && touched,
                    })}
                    {...inputField?.inputProps}
                />
                {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                {inputField.tooltip && (
                    <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                        {inputField.tooltip}
                    </UncontrolledTooltip>
                )}
            </Col>
            {isFieldArray && index === configLength && fieldArrayDeleteComponent}
        </>
    )
}
