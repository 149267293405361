import { put, select, takeEvery } from 'redux-saga/effects'
import { apiRequest } from '../api'
import {
    authenticateDestroy,
    authenticateExpired,
    authenticateFailure,
    authenticateInitial,
    authenticateSuccess,
    authenticateWithRefreshToken,
    fetchUser,
    fetchUserFailure,
    fetchUserRequest,
    fetchUserSuccess,
    forgotPasswordSendEmail,
    forgotPasswordSendEmailFailure,
    forgotPasswordSendEmailRequest,
    forgotPasswordSendEmailSuccess,
    registerUser,
    registerUserFailure,
    registerUserRequest,
    registerUserSuccess,
    resetPassword,
    resetPasswordFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    updateCurrentSettings,
    updateCurrentSettingsFailure,
    updateCurrentSettingsRequest,
    updateCurrentSettingsSuccess,
} from './actions'
import { selectIsRefreshing, selectRefreshToken } from './selectors'

export function* fetchCurrentUserSaga() {
    yield put(fetchUserRequest())

    try {
        const response = yield apiRequest(`v1/core/authentication/agent`, {
            method: 'GET',
        })

        yield put(fetchUserSuccess(response.data))
    } catch (error) {
        yield put(fetchUserFailure(error))
    }
}

export function* updateCurrentUserSettingsSaga({ payload }) {
    yield put(updateCurrentSettingsRequest())
    const { userId, userSettings } = payload

    try {
        yield apiRequest(`v1/core/users/${userId}`, {
            method: 'PUT',
            data: {
                userSettings: JSON.stringify(userSettings),
            },
        })

        yield put(updateCurrentSettingsSuccess(userSettings))
    } catch (error) {
        yield put(updateCurrentSettingsFailure(error))
    }
}

export function* registerUserSaga({ payload }) {
    yield put(registerUserRequest())

    try {
        const response = yield apiRequest(`v1/core/signup`, {
            method: 'POST',
            data: payload,
        })

        yield put(registerUserSuccess(response.status))
    } catch (error) {
        yield put(registerUserFailure(error))
    }
}

export function* setTokensSaga({ payload }) {
    yield localStorage.setItem('access_token', payload.data.token)
    yield localStorage.setItem('refresh_token', payload.data.refresh_token)
}

export function* clearTokensSaga() {
    yield localStorage.removeItem('access_token')
    yield localStorage.removeItem('refresh_token')
}

export function* destroyTokensSaga() {
    yield clearTokensSaga()
}

export function* refreshTokensSaga() {
    const isRefreshing = yield select(selectIsRefreshing)
    const refreshToken = yield select(selectRefreshToken)

    if (!isRefreshing && refreshToken) {
        yield put(authenticateWithRefreshToken(refreshToken))
    }
}

export function* forgotPasswordSendEmailSaga({ payload }) {
    yield put(forgotPasswordSendEmailRequest())

    try {
        const response = yield apiRequest(`v1/core/authentication/password-recovery`, {
            method: 'POST',
            data: payload,
        })

        yield put(forgotPasswordSendEmailSuccess(response.status))
    } catch (error) {
        yield put(forgotPasswordSendEmailFailure(error))
    }
}

export function* resetPasswordSaga({ payload }) {
    yield put(resetPasswordRequest())

    try {
        const response = yield apiRequest(`v1/core/authentication/password-recovery`, {
            method: 'PATCH',
            data: payload,
        })

        yield put(resetPasswordSuccess(response.status))
    } catch (error) {
        yield put(resetPasswordFailure(error))
    }
}

export default function* authSaga() {
    yield takeEvery(`${authenticateSuccess}`, setTokensSaga)
    yield takeEvery(`${authenticateFailure}`, clearTokensSaga)
    yield takeEvery(`${authenticateExpired}`, refreshTokensSaga)
    yield takeEvery(`${authenticateDestroy}`, destroyTokensSaga)
    yield takeEvery(`${updateCurrentSettings}`, updateCurrentUserSettingsSaga)
    yield takeEvery(`${fetchUser}`, fetchCurrentUserSaga)
    yield takeEvery(`${registerUser}`, registerUserSaga)
    yield takeEvery(`${forgotPasswordSendEmail}`, forgotPasswordSendEmailSaga)
    yield takeEvery(`${resetPassword}`, resetPasswordSaga)

    const accessToken = yield localStorage.getItem('access_token')
    const refreshToken = yield localStorage.getItem('refresh_token')

    if (accessToken && refreshToken) {
        yield put(authenticateInitial({ accessToken, refreshToken }))
    }
}
