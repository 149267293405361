import QueryString from 'qs'
import { toast } from 'react-toastify'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    createContact,
    createContactFailure,
    createContactRequest,
    createContactSuccess,
    deleteContact,
    deleteContactDocument,
    deleteContactDocumentFailure,
    deleteContactDocumentRequest,
    deleteContactDocumentSuccess,
    deleteContactFailure,
    deleteContactMultiple,
    deleteContactMultipleFailure,
    deleteContactMultipleRequest,
    deleteContactMultipleSuccess,
    deleteContactRequest,
    deleteContactSuccess,
    downloadContactDocument,
    downloadContactDocumentFailure,
    downloadContactDocumentRequest,
    downloadContactDocumentSuccess,
    fetchContact,
    fetchContactDocuments,
    fetchContactDocumentsFailure,
    fetchContactDocumentsRequest,
    fetchContactDocumentsSuccess,
    fetchContactFailure,
    fetchContactRequest,
    fetchContactSuccess,
    fetchContacts,
    fetchContactsAsyncInput,
    fetchContactsAsyncInputContactFailure,
    fetchContactsAsyncInputContactRequest,
    fetchContactsAsyncInputContactSuccess,
    fetchContactsFailure,
    fetchContactsNoStore,
    fetchContactsNoStoreFailure,
    fetchContactsNoStoreRequest,
    fetchContactsNoStoreSuccess,
    fetchContactsRequest,
    fetchContactsSuccess,
    queContactDocument,
    queContactDocumentFailure,
    queContactDocumentRequest,
    queContactDocumentSuccess,
    syncContactDocument,
    syncContactDocumentFailure,
    syncContactDocumentRequest,
    syncContactDocumentSuccess,
    updateContact,
    updateContactFailure,
    updateContactRequest,
    uploadContactDocument,
    uploadContactDocumentFailure,
    uploadContactDocumentRequest,
    uploadContactDocumentSuccess,
} from './actions'
import { invalidateQueryInTable } from '../../api/helpers'
import { queryClient } from '../../index'

export function* fetchContactsSaga({ payload, meta }) {
    // console.log('payload', payload)
    // console.log('meta', meta)
    yield put(fetchContactsRequest())
    try {
        const response = yield apiRequest(`v1/contact`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchContactsSuccess(response.data))
        meta(response.data.count)
    } catch (error) {
        yield put(fetchContactsFailure(error))
    }
}

export function* fetchContactSaga({ payload }) {
    const { id, order, criteria } = payload

    yield put(fetchContactRequest())
    try {
        const response = yield apiRequest(`v1/contact/${id}`, {
            method: 'GET',
            params: { order, criteria },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchContactSuccess(response.data))
    } catch (error) {
        yield put(fetchContactFailure(error))
    }
}

export function* createContactSaga({ payload, meta }) {
    yield put(createContactRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest('v1/contact/person', {
            method: 'POST',
            data: payload,
        })
        yield put(createContactSuccess(response))
        invalidateQueryInTable('contactList')
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(createContactFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* updateContactSaga({ payload, meta }) {
    yield put(updateContactRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    const { id, ...data } = payload
    // console.log('contact toas',progressToast)
    try {
        const response = yield apiRequest(`v1/contact/${id}`, {
            method: 'PUT',
            data: data,
        })
        //   console.log('update contact ', response.data)
        // yield put(updateContactSuccess(response));
        queryClient.invalidateQueries({ queryKey: ['contacts', id.toString()] })
        invalidateQueryInTable('contactList')
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(updateContactFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* deleteContactSaga({ payload, meta }) {
    yield put(deleteContactRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.delete'))
    try {
        yield apiRequest(`v1/contact/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteContactSuccess(payload))
        invalidateQueryInTable('contactList')
        toast.update(progressToast, { render: i18n.t('toast.update.delete.success'), type: 'success', isLoading: false, autoClose: 3000 })

        if (meta && meta.redirect) {
            meta.redirect()
        }
    } catch (error) {
        console.log(error)
        yield put(deleteContactFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

function* deleteContactById(id) {
    try {
        const response = yield apiRequest(`v1/contact/${id}`, {
            method: 'DELETE',
        })
        return response
    } catch (error) {
        return error
    }
}

export function* deleteContactMultipleSaga({ payload }) {
    const requestsToBeDeletedCount = payload.length
    yield put(deleteContactMultipleRequest())

    const progressToast = toast.loading(i18n.t('toast.loading.delete.contact.number', { requestsToBeDeletedCount }))

    const responses = yield all(payload.map((id) => call(deleteContactById, id)))

    const failedResponses = responses.filter((response) => response instanceof Error)
    const deletedContacts = responses.filter((response) => response.status === 200).map((response) => parseInt(response.data.id))
    const successCount = deletedContacts.length
    const failedCount = failedResponses.length

    if (successCount === 0 && failedCount > 0) {
        yield put(deleteContactMultipleFailure('Brisanje nije uspjelo'))
        toast.update(progressToast, {
            render: i18n.t('toast.update.delete.error'),
            type: 'error',
            isLoading: false,
            autoClose: 3000,
        })
        return
    }

    if (successCount > 0) {
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete.contact.number.success', { successCount, requestsToBeDeletedCount }),
            type: 'success',
            isLoading: false,
            autoClose: 3000,
        })
    }

    if (failedCount > 0) {
        toast.error(i18n.t('toast.loading.delete.contact.number.error', { failedCount, requestsToBeDeletedCount }), {
            autoClose: 3000,
        })
    }
    invalidateQueryInTable('contactList')
    yield put(deleteContactMultipleSuccess(deletedContacts))
}

export function* fetchContactsAsyncInputSaga({ payload, meta }) {
    // console.log('payload async', payload, meta)
    const params = payload.params
    yield put(fetchContactsAsyncInputContactRequest())
    try {
        let response = yield apiRequest(`v1/contact`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        // console.log('## respnse', response)
        yield put(fetchContactsAsyncInputContactSuccess())
        // console.log('response',response.data)
        let formatedResults = []
        if (response.data.items) {
            formatedResults = response.data.items?.map((item) => {
                if (item.contactType === 'company') {
                    // console.log('checking ids', item)
                    return {
                        value: item.id,
                        label: item.contactCompany?.name,
                        data: {
                            contactEmail: item.contactEmail,
                        },
                    }
                }

                return {
                    value: item.id,
                    label: `${item.contactPerson?.firstName} ${item.contactPerson?.lastName}`,
                    data: {
                        contactEmail: item.contactEmail,
                        contactPersonCompany: item.contactPersonCompany,
                    },
                }
            })
        } else {
            if (response.data.contactType === 'company') {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.contactCompany?.name,
                        selected: payload?.initialValue ? true : false,
                    },
                ]
            } else {
                if (response.data.id) {
                    formatedResults = [
                        {
                            value: response.data.id,
                            label: response.data.contactCompany?.name,
                            data: {
                                contactEmail: response.data.contactEmail,
                            },
                        },
                    ]
                }
            }
        }

        meta(formatedResults)

        // return response.data
    } catch (error) {
        yield put(fetchContactsAsyncInputContactFailure(error))
    }
}

export function* uploadContactDocumentSaga({ payload }) {
    // console.log('meta', meta);
    // console.log('uploading', payload)
    const { formData, file } = payload
    yield put(uploadContactDocumentRequest(file))
    try {
        yield apiRequest('v1/file/upload/contact/document', {
            method: 'POST',
            data: formData,
        })

        //   console.log('upload response', response);
        yield put(uploadContactDocumentSuccess(file))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(
            uploadContactDocumentFailure({
                error,
                file,
            })
        )
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* queContactDocumentSaga({ payload }) {
    yield put(queContactDocumentRequest())
    // console.log('meta', meta);
    // console.log('starting que', payload)
    try {
        yield put(queContactDocumentSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
    } catch (error) {
        yield put(queContactDocumentFailure(error))
    }
}

export function* deleteContactDocumentSaga({ payload, meta }) {
    yield put(deleteContactDocumentRequest())
    // console.log('detele', payload)
    try {
        yield apiRequest(`v1/file/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteContactDocumentSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteContactDocumentFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* fetchContactDocumentsSaga({ payload, meta }) {
    yield put(fetchContactDocumentsRequest())
    const { parentId, ...params } = payload
    // console.log('meta', meta)
    try {
        const response = yield apiRequest(`v1/file/parent/${parentId}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        // console.log('conatct document', response.data)
        yield put(fetchContactDocumentsSuccess(response.data))
        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        yield put(fetchContactDocumentsFailure(error))
    }
}

export function* downloadContactDocumentSaga({ payload, meta }) {
    yield put(downloadContactDocumentRequest())
    // console.log('download triggered')
    // console.log('meta', meta)
    try {
        const response = yield apiRequest(`v1/file/download/${payload}`, {
            method: 'GET',
            // params: params,
            // paramsSerializer: params => {
            //     return QueryString.stringify(params)
            // }
        })
        // console.log('response', response.data)
        // var sliceSize = 512;

        // var binaryString = window.atob(response.data);
        // console.log('ibn', binaryString)
        // var binaryLen = binaryString.length;
        // var bytes = new Uint8Array(binaryLen);
        // for (var i = 0; i < binaryLen; i++) {
        // var ascii = binaryString.charCodeAt(i);
        // bytes[i] = ascii;
        // }

        // console.log('bt', bytes)
        // var blob = new Blob(response.data, {type: fileContantType})
        // var link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // var fileName = "test";
        // link.download = fileName;
        // link.click();

        yield put(downloadContactDocumentSuccess(response.data))
        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        console.log('doc dl err', error)
        yield put(downloadContactDocumentFailure(error))
    }
}

export function* syncContactDocumentSaga({ payload, meta }) {
    yield put(syncContactDocumentRequest())
    // console.log('detele', payload)
    try {
        yield apiRequest(`v1/file/sync/${payload}`, {
            method: 'PUT',
        })

        //   console.log('sync response', response.data)
        yield put(syncContactDocumentSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(syncContactDocumentFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* fetchContactsNoStoreSaga({ payload, meta }) {
    let params = {
        criteria: {
            ...payload?.criteria,
            contactType: 'person',
        },
    }

    if (payload.criteria?.contact && payload.criteria.contactProfile) {
        params = {
            criteria: {
                ...payload.criteria.contact,
                contactProfile: {
                    ...payload.criteria.contactProfile,
                    ...(payload.criteria.contact.contactProfileService
                        ? {
                              contactProfileService: payload.criteria.contact.contactProfileService,
                          }
                        : {}),
                    ...(payload.criteria.contact.contactProfileStatus
                        ? {
                              contactProfileStatus: payload.criteria.contact.contactProfileStatus,
                          }
                        : {}),
                },
                contactType: 'person',
            },
        }

        delete params.criteria.contactProfileStatus
        delete params.criteria.contactProfileService
    }

    yield put(fetchContactsNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/contact`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchContactsNoStoreSuccess())
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(fetchContactsNoStoreFailure(error))
        meta.callback({
            submitStatus: SUBMIT_RESPONSE_STATUS.FAILURE,
        })
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchContacts}`, fetchContactsSaga)
    yield takeLatest(`${fetchContact}`, fetchContactSaga)
    yield takeLatest(`${fetchContactDocuments}`, fetchContactDocumentsSaga)
    yield takeEvery(`${createContact}`, createContactSaga)
    yield takeEvery(`${updateContact}`, updateContactSaga)
    yield takeEvery(`${deleteContact}`, deleteContactSaga)
    yield takeEvery(`${deleteContactMultiple}`, deleteContactMultipleSaga)
    yield takeLatest(`${fetchContactsAsyncInput}`, fetchContactsAsyncInputSaga)
    yield takeEvery(`${uploadContactDocument}`, uploadContactDocumentSaga)
    yield takeEvery(`${queContactDocument}`, queContactDocumentSaga)
    yield takeEvery(`${deleteContactDocument}`, deleteContactDocumentSaga)
    yield takeEvery(`${downloadContactDocument}`, downloadContactDocumentSaga)
    yield takeEvery(`${syncContactDocument}`, syncContactDocumentSaga)
    yield takeLatest(`${fetchContactsNoStore}`, fetchContactsNoStoreSaga)
}
