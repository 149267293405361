import i18n from '../../../i18n'
import { fetchContactsAsyncInput } from '../../../store/contacts'
import { baseAsyncSelectInput, baseCheckboxInput, baseDateInput, baseSelectInput, baseTextInput } from '../fieldTypes'
import { documentFields } from './fields'

export const documentFieldTypes = () => {
    return {
        [documentFields.FILE_DESCRIPTION]: {
            ...baseTextInput,
            name: documentFields.FILE_DESCRIPTION,
            label: i18n.t('table.header.documents.fileName'),
            colProps: {
                sm: 12,
            },
        },
        [documentFields.DOCUMENT_PROJECT_ID]: {
            ...baseTextInput,
            name: documentFields.DOCUMENT_PROJECT_ID,
            label: i18n.t('form.documentFormSidebar.document.projectId.label'),
            tooltip: i18n.t('form.documentFormSidebar.document.projectId.tooltip'),
            colProps: {
                sm: 6,
            },
        },
        [documentFields.DOCUMENT_PROPRETY_ID]: {
            ...baseTextInput,
            name: documentFields.DOCUMENT_PROPRETY_ID,
            label: i18n.t('form.documentFormSidebar.document.propertyId.label'),
            tooltip: i18n.t('form.documentFormSidebar.document.propertyId.tooltip'),
            colProps: {
                sm: 6,
            },
        },
        [documentFields.DOCUMENT_CONTACT_ID]: {
            ...baseAsyncSelectInput,
            name: documentFields.DOCUMENT_CONTACT_ID,
            label: i18n.t('form.project.documents.contact'),
            criteria: ['name'],
            action: fetchContactsAsyncInput,
            tooltip: i18n.t('form.project.documents.contact.tooltip'),
            noLimit: true,
            colProps: {
                sm: 12,
            },
        },
        [documentFields.DOCUMENT_TYPE]: {
            ...baseSelectInput,
            name: documentFields.DOCUMENT_TYPE,
            label: i18n.t('app.common.documentType'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [documentFields.DOCUMENT_CREATION_DATE]: {
            ...baseDateInput,
            name: documentFields.DOCUMENT_CREATION_DATE,
            label: i18n.t('app.common.creationDate'),
            colProps: {
                sm: 6,
            },
        },
        [documentFields.DOCUMENT_VALIDITY_DATE]: {
            ...baseDateInput,
            name: documentFields.DOCUMENT_VALIDITY_DATE,
            label: i18n.t('app.common.expirationDate'),
            colProps: {
                sm: 6,
            },
        },
        [documentFields.FILE_SYNC]: {
            ...baseCheckboxInput,
            name: documentFields.FILE_SYNC,
            label: i18n.t('form.property.map.sync'),
            tooltip: i18n.t('form.property.map.sync.tooltip'),
            colProps: {
                sm: 12,
            },
        },
    }
}
