import classNames from 'classnames'
import { isArray, isEqual, isObject } from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
    useAsyncDebounce,
    useColumnOrder,
    useExpanded,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table'
import { Button, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, Table, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { useColumnSettings } from '../../hooks/useColumnSettings'
import { usePageSizeSettings } from '../../hooks/usePageSizeSettings'
import { resetTableCheckbox, selectCheckboxValue, setCheckedTableRows } from '../../store/table'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../AppPermissionsSwitcher/constants/actions'
import {
    CompaniesGlobalFilter,
    ContactsGlobalFilter,
    CryptoOrdersGlobalFilter,
    CustomersGlobalFilter,
    InvoiceListGlobalSearch,
    LeadsGlobalFilter,
    NFTRankingGlobalFilter,
    OrderGlobalFilter,
    ProductsGlobalFilter,
    TaskListGlobalFilter,
    TicketsListGlobalFilter,
} from '../Common/GlobalSearchFilter'
import Loader from '../Common/Loader'
import SortingIcon from '../Common/SortingIcon'
import Paginator from '../Common/UIPagination'
import { DefaultColumnFilter } from '../Common/filters'
import TablePageSize from './TablePageSize'

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    isProductsFilter,
    isLeadsFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <React.Fragment>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <form>
                    <Row>
                        <Col sm={5}>
                            <div
                                className={
                                    isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter
                                        ? 'search-box me-2 mb-2 d-inline-block'
                                        : 'search-box me-2 mb-2 d-inline-block col-12'
                                }
                            >
                                <input
                                    onChange={(e) => {
                                        setValue(e.target.value)
                                        onChange(e.target.value)
                                    }}
                                    id="search-bar-0"
                                    type="text"
                                    className="form-control search /"
                                    placeholder={`${count} Search...`}
                                    value={value || ''}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </Col>
                        {isProductsFilter && <ProductsGlobalFilter />}
                        {isCustomerFilter && <CustomersGlobalFilter />}
                        {isOrderFilter && <OrderGlobalFilter />}
                        {isContactsFilter && <ContactsGlobalFilter />}
                        {isCompaniesFilter && <CompaniesGlobalFilter />}
                        {isLeadsFilter && <LeadsGlobalFilter />}
                        {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
                        {isInvoiceListFilter && <InvoiceListGlobalSearch />}
                        {isTicketsListFilter && <TicketsListGlobalFilter />}
                        {isNFTRankingFilter && <NFTRankingGlobalFilter />}
                        {isTaskListFilter && <TaskListGlobalFilter />}
                    </Row>
                </form>
            </CardBody>
        </React.Fragment>
    )
}

const TableContainer = ({
    columns,
    data,
    isGlobalFilter,
    isProductsFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isLeadsFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    tableActions,
    hasColumnSelection,
    hasPageSizeSelect,
    isLoading,
    handleDataFetch,
    handleRowClick,
    pageCount: controlledPageCount,
    totalCount,
    searchCriteria,
    tableHash,
    tableFooter,
    hidePagination = false,
    striped = true,
    currentPage,
    totalPages,
    hasPageCount,
    noResultMessage,
    customFetch = true,
    permissionName,
}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation()
    const tableRef = useRef()

    const [selectedRowData, setSelectedRowData] = useState([])
    const [tableWidth, setTableWidth] = useState(null)

    const {
        hiddenColumns: storedHiddenColumns,
        updateHiddenColumns,
        columnOrder: storedColumnOrder,
        updateColumnOrder,
        columnSort: storedColumnSort,
        updateColumnSort,
    } = useColumnSettings(tableHash)

    const { pageSize: storedPageSize, updatePageSize } = usePageSizeSettings(tableHash)

    const { isChecked } = useSelector((state) => ({
        isChecked: selectCheckboxValue(state),
    }))

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        allColumns,
        setColumnOrder,
        page,
        prepareRow,
        pageOptions,
        gotoPage,
        setHiddenColumns,
        setPageSize,
        setSortBy,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: { pageIndex, pageSize, sortBy, hiddenColumns, columnOrder },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter, width: 'auto' },
            initialState: {
                pageIndex: currentPage ?? 1,
                pageSize: storedPageSize || 20,
                selectedRowIds: 0,
                sortBy: storedColumnSort,
                hiddenColumns: storedHiddenColumns,
                columnOrder: storedColumnOrder,
            },
            autoResetPage: false,
            autoResetSelectedRows: false,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount + 1,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useColumnOrder
    )

    useEffect(() => {
        // use this function to add additional row data
        // selectedFlatRows - returns an array of objects representing all the selected rows

        const selectedData = selectedFlatRows.map((row) => ({
            ...row.original,
            id: row.original.id,
            city: isObject(row.original.city) ? row.original.city?.name : row.original.city,
            agentFirstName: isArray(row.original.agent) ? row.original.agent[0]?.firstName : row.original.agent?.firstName,
            agentLastName: isArray(row.original.agent) ? row.original.agent[0]?.lastName : row.original.agent?.lastName,
        }))

        if (!isEqual(selectedData, selectedRowData)) {
            setSelectedRowData(selectedData)
        }
    }, [selectedFlatRows, selectedRowData])

    useEffect(() => {
        dispatch(setCheckedTableRows(selectedRowData))
    }, [dispatch, selectedRowData])

    // unchecks the table checkbox upon applied action
    useEffect(() => {
        if (isChecked) {
            toggleAllRowsSelected(false)
            dispatch(resetTableCheckbox(false))
        }
    }, [isChecked, toggleAllRowsSelected, dispatch])

    useEffect(() => {
        const order = sortBy ? sortBy.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.desc ? 'desc' : 'asc' }), {}) : undefined

        // TODO: handleDataFetch is causing infinite loop so its not added to dependency array
        if (handleDataFetch && customFetch) {
            handleDataFetch(pageIndex === 0 ? 1 : pageIndex, pageSize, order, searchCriteria)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sortBy, searchCriteria, dispatch])

    useEffect(() => {
        currentPage > totalPages ? gotoPage(1) : gotoPage(currentPage)
    }, [currentPage, totalPages])

    useEffect(() => {
        if (JSON.stringify(storedHiddenColumns) !== JSON.stringify(hiddenColumns)) {
            setHiddenColumns(storedHiddenColumns)
        }
    }, [storedHiddenColumns])

    useEffect(() => {
        updateHiddenColumns(hiddenColumns)
    }, [hiddenColumns])

    useEffect(() => {
        // Filter out actions column
        const columnOrderWithoutActions = columnOrder?.filter((c) => c !== 'actions')
        updateColumnOrder(columnOrderWithoutActions)
    }, [columnOrder])

    useEffect(() => {
        updateColumnSort(sortBy)
    }, [sortBy])

    useEffect(() => {
        getTableWidth()

        window.addEventListener('resize', getTableWidth)

        return () => window.removeEventListener('resize', getTableWidth)
    }, [])

    const getTableWidth = () => {
        if (tableRef.current) {
            setTableWidth(tableRef.current.offsetWidth)
        }
    }

    const generateSortingIndicator = (column) => {
        if (!column.filterable) return ' '

        let sort = undefined

        if (column.isSortedDesc !== undefined) {
            sort = column.isSortedDesc ? 'desc' : 'asc'
        }

        return <SortingIcon sort={sort} />
    }

    const onChangePageSize = (value) => {
        setPageSize(Number(value))
        updatePageSize(Number(value))
        gotoPage(1)
    }

    const handleSortBy = (column) => {
        if (!column.filterable) return null

        const { id } = column
        const desc = !column.isSortedDesc
        setSortBy([{ id, desc }])
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columnIds = allColumns.map((column) => column.id)

            if (toIndex !== 0 && toIndex !== 1 && toIndex !== allColumns.length - 1) {
                const columnId = columnIds.splice(fromIndex, 1)[0]
                if (!['#', 'id', 'actions'].includes(columnId)) {
                    columnIds.splice(toIndex, 0, columnId)
                    setColumnOrder(columnIds)
                }
            }
        },
        nodeSelector: 'label',
        handleSelector: 'label',
    }

    const renderRow = (page) => {
        return page.map((row) => {
            prepareRow(row)

            return (
                <Fragment key={row.id}>
                    <tr>{row.cells.map((cell) => renderCell(cell))}</tr>
                </Fragment>
            )
        })
    }

    const renderCell = (cell) => {
        const rowClickAvailable = cell.column.id !== 'actions' && cell.column.id !== '#' && handleRowClick
        const { key, ...cellProps } = cell.getCellProps()

        if (rowClickAvailable) {
            return renderCellData(cell)
        }

        return (
            <td key={key} className={cell.column.tdClassName} {...cellProps}>
                {cell.render('Cell')}
            </td>
        )
    }

    const renderCellData = (cell) => {
        const { key, ...cellProps } = cell.getCellProps()
        const agent = cell.row.original.agent
        const itemAgentId = agent && Array.isArray(agent) ? agent[0]?.id : agent?.id
        const itemAgentOfficeId = Array.isArray(agent) ? agent[0].agencyOffice?.id : agent?.agencyOffice?.id

        return (
            <AppPermissionsSwitcher
                permission="ModulePermissions"
                permissionName={permissionName}
                itemAgentId={itemAgentId}
                itemAgentOfficeId={itemAgentOfficeId}
                module={location}
                action={action.view}
                key={key}
            >
                {({ hasModulePermission }) => (
                    <td
                        className={cell.column.tdClassName}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            hasModulePermission && handleRowClick(cell.row.original.id)
                        }}
                        style={{ cursor: hasModulePermission ? 'pointer' : 'not-allowed' }}
                        {...cellProps}
                    >
                        {cell.render('Cell')}
                    </td>
                )}
            </AppPermissionsSwitcher>
        )
    }

    const noResults = (
        <tr>
            <td colSpan="100%">
                <div style={{ color: '#878a99' }} className="text-center d-flex justify-content-center">
                    {noResultMessage ? noResultMessage : t('app.common.noResult')}
                </div>
            </td>
        </tr>
    )

    return (
        <Fragment>
            {(tableActions || hasColumnSelection || hasPageCount) && (
                <Row className="align-items-center">
                    <div className="d-flex">
                        {/* TODO: temporary see how other table will fnction */}
                        {tableActions && tableActions()}
                        <div className="flex-shrink-0 d-flex flex-row align-items-center">
                            <TablePageSize
                                className={'me-4 fs-13 fw-light'}
                                pageLength={page.length}
                                totalCount={totalCount}
                                pageOptionsLength={pageOptions.length}
                            />
                            {hasColumnSelection && (
                                <UncontrolledDropdown className="ms-2">
                                    <DropdownToggle
                                        title={t('table.columns')}
                                        onClick={(e) => e.preventDefault()}
                                        href="#"
                                        className="btn btn-primary dropdown"
                                        tag="button"
                                    >
                                        <i className="mdi mdi-format-list-bulleted-square pe-none align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end" end={true}>
                                        <SimpleBar style={{ maxHeight: '400px', width: '300px', overflowX: 'hidden' }}>
                                            <ReactDragListView {...dragProps}>
                                                {allColumns.map((column) => {
                                                    if (column.id !== 'actions' && column.id !== '#') {
                                                        return (
                                                            <DropdownItem
                                                                key={column.id}
                                                                tag={Label}
                                                                for={column.id}
                                                                className="d-flex align-items-center justify-content-between"
                                                                style={{ cursor: 'move' }}
                                                                toggle={false}
                                                            >
                                                                <div className="form-check">
                                                                    <input
                                                                        {...column.getToggleHiddenProps()}
                                                                        type="checkbox"
                                                                        name={column.id}
                                                                        id={column.id}
                                                                        className="form-check-input"
                                                                    />{' '}
                                                                    {column.Header}
                                                                </div>
                                                                <i className="ms-2 mdi mdi-menu fs-16" style={{ color: '#CED4DA' }}></i>
                                                            </DropdownItem>
                                                        )
                                                    } else {
                                                        // TODO: find solution without dummy hidden dropdown element
                                                        return (
                                                            <DropdownItem
                                                                key={column.id}
                                                                tag={Label}
                                                                for={column.id}
                                                                className="d-none align-items-center justify-content-between"
                                                                toggle={false}
                                                            >
                                                                <div className="form-check">
                                                                    <input
                                                                        {...column.getToggleHiddenProps()}
                                                                        type="checkbox"
                                                                        name={column.id}
                                                                        id={column.id}
                                                                        className="form-check-input"
                                                                    />{' '}
                                                                    {column.Header.toString()}
                                                                </div>
                                                                <i className="ms-2 mdi mdi-menu fs-16" style={{ color: '#CED4DA' }}></i>
                                                            </DropdownItem>
                                                        )
                                                    }
                                                })}
                                            </ReactDragListView>
                                        </SimpleBar>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            )}
                        </div>
                    </div>
                </Row>
            )}

            <Row className="mb-3">
                {/* {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )} */}
                {isGlobalFilter && (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isProductsFilter={isProductsFilter}
                        isCustomerFilter={isCustomerFilter}
                        isOrderFilter={isOrderFilter}
                        isContactsFilter={isContactsFilter}
                        isCompaniesFilter={isCompaniesFilter}
                        isLeadsFilter={isLeadsFilter}
                        isCryptoOrdersFilter={isCryptoOrdersFilter}
                        isInvoiceListFilter={isInvoiceListFilter}
                        isTicketsListFilter={isTicketsListFilter}
                        isNFTRankingFilter={isNFTRankingFilter}
                        isTaskListFilter={isTaskListFilter}
                    />
                )}
                {isAddOptions && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleOrderClicks}>
                                <i className="mdi mdi-plus me-1" />
                                Add New Order
                            </Button>
                        </div>
                    </Col>
                )}
                {isAddUserList && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleUserClick}>
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New User
                            </Button>
                        </div>
                    </Col>
                )}
                {isAddCustList && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button type="button" color="success" className="btn-rounded mb-2 me-2" onClick={handleCustomerClick}>
                                <i className="mdi mdi-plus me-1" />
                                New Customers
                            </Button>
                        </div>
                    </Col>
                )}
            </Row>

            <div className={divClass} ref={tableRef}>
                <Table responsive striped={striped} bordered {...getTableProps()} className={`${tableClass ?? ''}`} style={{ position: 'relative' }}>
                    <thead className={theadClass}>
                        {headerGroups.map((headerGroup) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                            return (
                                <tr className={trClass} key={key} {...headerGroupProps}>
                                    {headerGroup.headers.map((column) => {
                                        const { key, ...headerProps } = column.getHeaderProps({
                                            style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                                        })

                                        return (
                                            <th
                                                key={key}
                                                id={column.id}
                                                className={classNames(thClass, {
                                                    'is-filterable': column.filterable,
                                                })}
                                                {...column.getSortByToggleProps()}
                                                {...headerProps}
                                                onClick={() => handleSortBy(column)}
                                            >
                                                <div
                                                    className={classNames(
                                                        column.thDivClass,
                                                        'd-flex',
                                                        'justify-content-between',
                                                        'align-items-center'
                                                    )}
                                                >
                                                    {column.render('Header')}
                                                    {generateSortingIndicator(column)}
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {!isLoading ? (
                            page.length ? (
                                renderRow(page)
                            ) : (
                                noResults
                            )
                        ) : customFetch ? (
                            <tr>
                                <td colSpan="100%" height={65}>
                                    <div
                                        className="text-center d-flex justify-content-center"
                                        style={{ position: 'absolute', inset: 'auto 0', width: tableWidth }}
                                    >
                                        <Loader />
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            noResults
                        )}
                    </tbody>
                    {!isLoading && customFetch && tableFooter && <tfoot className="table-light">{tableFooter}</tfoot>}
                </Table>
            </div>

            {!hidePagination && (
                <Row className="justify-content-md-between justify-content-center align-items-center p-2">
                    <TablePageSize
                        className={'col-md-auto mb-2'}
                        pageLength={page.length}
                        totalCount={totalCount}
                        pageOptionsLength={pageOptions.length}
                    />
                    <Col className="d-flex align-center col-auto">
                        <div className="d-flex flex-wrap">
                            {hasPageSizeSelect && (
                                <div className="me-4 mb-2">
                                    <Input
                                        type="select"
                                        className="form-control"
                                        onChange={(e) => onChangePageSize(Number(e.target.value))}
                                        value={storedPageSize}
                                    >
                                        {[20, 50, 100].map((pageSize) => (
                                            <option value={pageSize} key={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </Input>
                                </div>
                            )}

                            <Paginator currentPage={pageIndex === 0 ? 1 : pageIndex} totalPages={controlledPageCount} onPageChange={gotoPage} />
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    )
}

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    isProductsFilter: PropTypes.any,
    isCustomerFilter: PropTypes.any,
    isOrderFilter: PropTypes.any,
    isContactsFilter: PropTypes.any,
    isCompaniesFilter: PropTypes.any,
    isLeadsFilter: PropTypes.any,
    isCryptoOrdersFilter: PropTypes.any,
    isInvoiceListFilter: PropTypes.any,
    isTicketsListFilter: PropTypes.any,
    isNFTRankingFilter: PropTypes.any,
    isTaskListFilter: PropTypes.any,
    isAddOptions: PropTypes.any,
    handleOrderClicks: PropTypes.any,
    handleUserClick: PropTypes.any,
    handleCustomerClick: PropTypes.any,
    isAddCustList: PropTypes.any,
    tableClass: PropTypes.any,
    trClass: PropTypes.any,
    thClass: PropTypes.any,
    divClass: PropTypes.any,
    tableFooter: PropTypes.any,
    hasPageCount: PropTypes.any,
    noResultMessage: PropTypes.any,
}

export default TableContainer
