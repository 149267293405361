import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import * as Yup from 'yup'
import { MODAL_TYPES } from '../../../common/constants'
import { realEstateGeneralFieldTypes } from '../../../common/forms/generalRealEstate/fieldTypes'
import { realEstateGeneralFields } from '../../../common/forms/generalRealEstate/fields'
import { updateAgent } from '../../../store/agents/actions'
import { selectAgentsFormOption } from '../../../store/agents/selectors'
import { hideModal } from '../../../store/modal'
import { resetTableCheckbox, selectCheckedTableRows } from '../../../store/table'
import { getUrlBase } from '../../../utils/general'
import RecursiveForm from '../../RecursiveForm'
import styles from './ChangeAgentModal.module.css'

const ChangeAgentModal = ({ isOpen }) => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { CHANGE_AGENT_MODAL } = MODAL_TYPES

    const { pathname } = useLocation()
    const dataKey = pathname.slice(1)

    const { agent_id: agentProps } = realEstateGeneralFieldTypes(false, {})
    const newAgentProps = { ...agentProps, label: t('form.errors.changeAgentModal.agent_id'), colProps: { sm: 12 }, required: false }

    const agents = useSelector((state) => ({
        [realEstateGeneralFields.AGENT_ID]: selectAgentsFormOption(state),
    }))

    const { selectedTableRows } = useSelector((state) => ({
        selectedTableRows: selectCheckedTableRows(state),
    }))

    const selectedRowsIds = selectedTableRows.map((row) => row.id)

    const handleClose = () => {
        const payload = {
            modalType: CHANGE_AGENT_MODAL,
        }

        dispatch(hideModal(payload))
        dispatch(resetTableCheckbox(true))
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { agent_id: '' },
        validationSchema: Yup.object({
            agent_id: Yup.string().required(t('form.errors.changeAgentModal.agent_id')),
        }),
        onSubmit: (values) => {
            const { agent_id } = values
            const urlBase = getUrlBase(dataKey)

            dispatch(
                updateAgent({ agentId: agent_id, data: { [dataKey]: selectedRowsIds }, urlBase, setIsLoading }, () => {
                    handleClose()
                    setIsLoading(false)
                })
            )
        },
    })

    return (
        <Modal isOpen={isOpen} fullscreen="md" centered>
            <ModalHeader toggle={handleClose}>
                <p className="m-0">{t('app.common.switchAgent')}</p>
            </ModalHeader>
            <ModalBody>
                <div className={`${styles.displayBox} py-4`}>
                    <Row>
                        <Col className="fw-bold">{dataKey === 'projects' ? t('app.common.project.label') : t('modal.changeAgent.property')}</Col>
                        <Col className="fw-bold">{t('app.common.agent')}</Col>
                    </Row>
                    {selectedTableRows.map((row, i) => {
                        return (
                            <Row className={`${styles.displayData} my-2`} key={i}>
                                <Col>
                                    {row.id}, {row.city ? row.city : '-'}
                                </Col>
                                <Col>
                                    {row.agentFirstName} {row.agentLastName}
                                </Col>
                            </Row>
                        )
                    })}
                </div>
                <Form onSubmit={formik.handleSubmit} className="mt-4 d-flex flex-column">
                    <RecursiveForm inputs={newAgentProps} selectOptions={agents} formik={formik} />
                    <Button className="btn-label align-self-end" type="submit" color="success" disabled={isLoading}>
                        <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> {t('button.save')}
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default ChangeAgentModal
