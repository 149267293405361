import i18next from 'i18next'

export const propertyGeneralFormTabs = (isEdit: boolean, isClone = false) => {
    const { t } = i18next
    const tabs = [
        { id: 1, name: 'general', label: t('app.common.generalInfo') },
        { id: 2, name: 'description', label: t('app.common.description') },
        { id: 3, name: 'map', label: t('form.common.tab.map'), disabled: isClone ? false : !isEdit },
        {
            id: 4,
            name: 'materials',
            label: t('form.common.tab.materials'),
            disabled: !isEdit,
            children: [
                { id: '4-1', name: 'photos', label: t('app.common.photos') },
                { id: '4-2', name: 'groundPlans', label: t('app.common.groundPlans') },
                { id: '4-3', name: '3dVideo', label: t('form.common.tab.3dVideo') },
            ],
        },
        { id: 5, name: 'export', label: t('form.property.tab.export'), disabled: isClone ? false : !isEdit },
        { id: 6, name: 'documents', label: t('app.common.documents'), disabled: !isEdit },
        { id: 7, name: 'transactions', label: t('form.property.tab.transactions'), disabled: !isEdit },
    ]
    return tabs
}

export const propertyViewTabs = () => {
    const { t } = i18next
    const tabs = [
        { id: 1, name: 'about', label: t('view.property.tab.about') },
        { id: 2, name: 'notes', label: t('app.common.notes') },
        { id: 6, name: 'documents', label: t('app.common.documents') },
        { id: 8, name: 'opportunity', label: t('view.common.tab.opportunity') },
    ]
    return tabs
}
