import { combineReducers } from 'redux'
import { combineActions, handleActions } from 'redux-actions'
import {
    fetchSettingsFailure,
    fetchSettingsPropertyFlatSubtypesSuccess,
    fetchSettingsPropertyGarageSubtypesSuccess,
    fetchSettingsPropertyHouseSubtypesSuccess,
    fetchSettingsPropertyLandSubtypesSuccess,
    fetchSettingsPropertyOfficeSpaceSubtypesSuccess,
    fetchSettingsRequest,
    fetchSettingsSuccess,
} from './actions'

// TODO: this data will come via application-settings api
const data = handleActions(
    {
        [fetchSettingsRequest]: () => null,
        [fetchSettingsSuccess]: (state, { payload }) => ({ ...state, ...payload }),
    },
    {
        name: 'Local',
        defaultLanguage: 'hr',
        currency: '€',
        areaUnit: 'm2',
    }
)

const loading = handleActions(
    {
        [fetchSettingsRequest]: () => true,
        [combineActions(fetchSettingsSuccess, fetchSettingsFailure)]: () => false,
    },
    false
)

const propertyFlatSubTypes = handleActions(
    {
        [fetchSettingsPropertyFlatSubtypesSuccess]: (state, { payload }) => [...state, ...payload],
    },
    []
)

const propertyHouseSubTypes = handleActions(
    {
        [fetchSettingsPropertyHouseSubtypesSuccess]: (state, { payload }) => [...state, ...payload],
    },
    []
)

const propertyLandSubTypes = handleActions(
    {
        [fetchSettingsPropertyLandSubtypesSuccess]: (state, { payload }) => [...state, ...payload],
    },
    []
)

const propertyOfficeSpaceSubTypes = handleActions(
    {
        [fetchSettingsPropertyOfficeSpaceSubtypesSuccess]: (state, { payload }) => [...state, ...payload],
    },
    []
)

const propertyGarageSubTypes = handleActions(
    {
        [fetchSettingsPropertyGarageSubtypesSuccess]: (state, { payload }) => [...state, ...payload],
    },
    []
)

export default combineReducers({
    loading,
    data,
    propertyFlatSubTypes,
    propertyHouseSubTypes,
    propertyLandSubTypes,
    propertyOfficeSpaceSubTypes,
    propertyGarageSubTypes,
})
