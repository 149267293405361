import { combineActions, handleActions } from 'redux-actions'
import {
    createCompanyNoteFailure,
    createCompanyNoteRequest,
    createCompanyNoteSuccess,
    deleteCompanyNoteFailure,
    deleteCompanyNoteRequest,
    deleteCompanyNoteSuccess,
    fetchCompanyNotesFailure,
    fetchCompanyNotesRequest,
    fetchCompanyNotesSuccess,
    updateCompanyNoteFailure,
    updateCompanyNoteRequest,
    updateCompanyNoteSuccess,
} from './actions'

export const initialState = {
    items: {},
    item: {},
    count: 0,
    fetching: false,
    fetchingDetails: false,
    submitting: false,
    error: null,
    errorDetails: null,
}

export default handleActions(
    {
        [fetchCompanyNotesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchCompanyNotesSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items?.reduce((acc, item) => {
                // let parsedItem = JSON.parse(item);
                return { ...acc, [item.id]: item }
            }, {}),
            fetching: false,
        }),
        [fetchCompanyNotesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        // [fetchCompanyRequest]: state => ({
        //     ...state,
        //     fetchingDetails: true,
        //     errorDetails: null,
        // }),
        // [fetchCompanySuccess]: (state, { payload }) => ({
        //     ...state,
        //     item: payload,
        //     fetchingDetails: false
        // }),
        // [fetchCompanyFailure]: (state, { payload }) => ({
        //     ...state,
        //     errorDetails: payload,
        //     fetchingDetails: false
        // }),
        // [fetchCompanyProfileRequest]: state => ({
        //     ...state,
        //     fetchingProfile: true,
        //     errorProfile: null,
        // }),
        // [fetchCompanyProfileSuccess]: (state, { payload }) => ({
        //     ...state,
        //     profile: payload,
        //     fetchingProfile: false
        // }),
        // [fetchCompanyProfileFailure]: (state, { payload }) => ({
        //     ...state,
        //     errorProfile: payload,
        //     fetchingProfile: false
        // }),
        // [createCompanyNoteSuccess]: (staet, {payload} => ({
        //     ...state,
        //     items: {
        //         ...state.items,
        //         [payload.id]: payload
        //     }
        // })),
        [combineActions(updateCompanyNoteRequest, createCompanyNoteRequest)]: (state) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateCompanyNoteSuccess, createCompanyNoteSuccess)]: (state) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateCompanyNoteFailure, createCompanyNoteFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload?.response,
        }),
        [updateCompanyNoteSuccess]: (state, { payload }) => {
            const items = { ...state.items }
            // console.log('items ', items)
            // console.log('paylaod', payload)
            items[payload.id] = {
                ...items[payload.id],
                noteTitle: payload.note_title,
                noteDate: payload.note_date,
            }
            return {
                ...state,
                items,
            }
        },
        [deleteCompanyNoteRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteCompanyNoteSuccess]: (state, { payload }) => {
            const items = { ...state.items }
            // console.log('items', items)
            delete items[payload]
            return {
                ...state,
                deleting: false,
                items,
            }
        },
        [deleteCompanyNoteFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        // [fetchCompanysAsyncInputCompanyRequest]: state => ({
        //     ...state,
        //     asyncFetching: true,
        //     error: null,
        // }),
        // [fetchCompanysAsyncInputCompanySuccess]: (state, { payload }) => ({
        //     ...state,
        //     // asyncItems: payload?.items?.reduce((acc, item) => {
        //     //     // let parsedItem = JSON.parse(item);
        //     //     return { ...acc, [item.id]: item }}, {}),
        //     asyncFetching: false
        // }),
        // [fetchCompanysAsyncInputCompanyFailure]: (state, { payload }) => ({
        //     ...state,
        //     error: payload,
        //     asyncFetching: false
        // }),
    },
    initialState
)
