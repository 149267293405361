import { useSelector } from 'react-redux'
import { selectAvailableTranslationLanguages } from '../../store/applicationSettings'

const useLocaleOptions = () => {
    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const { availableTranslationLanguages } = useApplicationSettings()

    const availableTranslationLanguages = useSelector((state) => selectAvailableTranslationLanguages(state))

    const locales = availableTranslationLanguages?.map((lang) => lang.abbreviation)
    localStorage.setItem('translationLangs', JSON.stringify(locales))

    const localeOptions = availableTranslationLanguages.map((lang) => ({
        value: lang.abbreviation,
        label: lang.abbreviation.toUpperCase(),
    }))

    return localeOptions
}

export default useLocaleOptions
