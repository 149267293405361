import { toast } from 'react-toastify'
import { put, takeEvery } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    createOffer,
    createOfferFailure,
    createOfferRequest,
    createOfferSuccess,
    deleteOffer,
    deleteOfferFailure,
    deleteOfferRequest,
    deleteOfferSuccess,
    previewOffer,
    previoewOfferFailure,
    previoewOfferRequest,
    previoewOfferSuccess,
    updateOffer,
    updateOfferFailure,
    updateOfferRequest,
    updateOfferSuccess,
} from './actions'

export function* createOfferSaga({ payload, meta }) {
    yield put(createOfferRequest())

    const progressToast = toast.loading(i18n.t('toast.loading.offer'))
    try {
        yield apiRequest(`v1/offer`, {
            method: 'POST',
            data: payload,
        })

        yield put(createOfferSuccess())

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
        toast.update(progressToast, { render: i18n.t('toast.loading.offer.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        console.log('error', error)
        yield put(createOfferFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
        toast.update(progressToast, { render: i18n.t('toast.loading.offer.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* updateOfferSaga({ payload, meta }) {
    yield put(updateOfferRequest())
    const { id, noteEntity, ...data } = payload
    try {
        yield apiRequest(`v1/${noteEntity}-note/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateOfferSuccess(payload))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateOfferFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* deleteOfferSaga({ payload, meta }) {
    yield put(deleteOfferRequest())
    // console.log('detele', payload)
    const { id, noteEntity } = payload
    try {
        yield apiRequest(`v1/${noteEntity}-note/${id}`, {
            method: 'DELETE',
        })
        yield put(deleteOfferSuccess(payload))

        // toast.success("Todo Added Successfully", { autoClose: 3000 });
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
    } catch (error) {
        yield put(deleteOfferFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* previewOfferSaga({ payload, meta }) {
    const { propertyCount, data } = payload
    yield put(previoewOfferRequest())
    try {
        const response = yield apiRequest(`v1/offer/pdf/${propertyCount}`, {
            method: 'POST',
            responseType: 'blob',
            transformResponse: [
                function (data) {
                    let blob = new window.Blob([data], { type: 'application/pdf' })
                    return window.URL.createObjectURL(blob)
                },
            ],
            data: data,
        })
        yield put(previoewOfferSuccess(response.data))
        if (meta && meta.callback) {
            meta.callback({
                submittStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                // data: new Uint8Array(response.data)
                data: response.data,
            })
        }
    } catch (error) {
        yield put(previoewOfferFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
    }
}

export default function* actionWatcher() {
    yield takeEvery(`${createOffer}`, createOfferSaga)
    yield takeEvery(`${updateOffer}`, updateOfferSaga)
    yield takeEvery(`${deleteOffer}`, deleteOfferSaga)
    yield takeEvery(`${previewOffer}`, previewOfferSaga)
}
