import { useSelector } from 'react-redux'
import { selectApplicationPackage } from '../../store/applicationSettings'
import { setFeatures } from './utils'

// use this component to turn on/off application features according to application modes
const AppFeaturesSwitcher = ({ children, ...props }) => {
    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const { applicationPackage } = useApplicationSettings()

    const applicationPackage = useSelector((state) => selectApplicationPackage(state))

    if (!applicationPackage || !applicationPackage.name) return

    const { name, applicationModules = [], applicationModuleFeatures = [] } = applicationPackage ?? {}

    const featureProps = {
        ...props,
        applicationModules,
        applicationModuleFeatures,
    }
    let newProps = {}

    switch (name) {
        case 'Basic':
        case 'Start':
        case 'Standard':
            newProps = setFeatures(featureProps)
            break
        default:
            console.error(`No application mode with name: ${name}`)
    }

    return children(newProps)
}

export default AppFeaturesSwitcher
