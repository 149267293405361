import values from 'lodash/values'
import { getQueryData } from '../../api/helpers'
import { projectResponseFields } from '../../common/response/project'
import { contactOptions, findEntityTitle, generateLocationData, selectStateOwnerFormOptions } from '../../utils/general'
import { stateKey } from './actions'

export const selectProperties = (state) => values(state[stateKey].items)
export const selectPropertiesFetching = (state) => state[stateKey].fetching
export const selectPropertyFetchingDetails = (state) => state[stateKey].fetchingDetails
export const selectPropertyCount = (state) => state[stateKey].count
export const selectProperty = (state) => state[stateKey].item

export const selectOpportunityActivity = (state) => state[stateKey].itemsOpportunityActivity
export const selectOpportunityActivityCount = (state) => state[stateKey].countOpportunityActivity
export const selectOpportunityActivityFetching = (state) => state[stateKey].fetchingOpportunityActivity

export const selectOpportunityContact = (state) => state[stateKey].itemsOpportunityContact
export const selectOpportunityContactCount = (state) => state[stateKey].countOpportunityContact
export const selectOpportunityContactFetching = (state) => state[stateKey].fetchingOpportunityContact

export const selectOpportunityShowActivity = (state) => state[stateKey].opportunityShowActivity
export const selectOpportunityTitle = (state) => state[stateKey].opportunityTitle
export const selectOpportunityDropDownButtonText = (state) => state[stateKey].opportunityDropDownButtonText

export const selectPropertyPagination = (state) => state[stateKey].pagination
export const selectPropertyLinks = (state) => state[stateKey].links

export const selectPropertyPhoto = (state) => values(state[stateKey].itemphoto)
export const selectPropertyPhotoQue = (state) => values(state[stateKey].photoque)
export const selectPropertyFetchingPhotos = (state) => state[stateKey].fetchingPhotos

export const selectPropertiesContactProfile = (state) => state[stateKey].itemsPropertiesContactProfile
export const selectPropertiesContactProfileCount = (state) => state[stateKey].countPropertiesContactProfile
export const selectPropertiesContactProfileFetching = (state) => state[stateKey].fetchingPropertiesContactProfile

// used in property edit 
export const setPropertyOwnerField = (id) => {
    const data = getQueryData(['properties', id])

    if (!data) return []
    const { item } = data

    return selectStateOwnerFormOptions(item?.owner)
}

// used in PropertyEdit for project field (connect property to project)
export const setProjectFieldData = (id) => {
    const data = getQueryData(['properties', id])

    if (!data) return []
    const { item } = data
    const projectId = item?.project?.id

    if (!item || !projectId) return []

    return [
        {
            label: `${findEntityTitle(item.project, projectResponseFields.PROJECT_TITLE)} - ID: ${projectId}`,
            value: projectId.toString(),
        },
    ]
}

// used for transaction tab in property
export const setPropertyContactField = (id) => {
    const data = getQueryData(['properties', id])

    if (!data) return []

    const { item } = data
    const contacts = item?.propertyTransaction?.contact

    if (contacts?.length > 0) {
        return contacts.map((contact) => contactOptions(contact)[0])
    }

    return []
}

// used for activity modal in property
export const setPropertyIdFieldData = (id) => {
    const data = getQueryData(['properties', id])
    const currentLang = localStorage.getItem('i18nextLng')

    if (!data) return []
    const { item } = data

    const option = {
        value: item?.id?.toString(),
        label: `ID: ${item.id} - ${item.translations[currentLang]?.propertyTitle ?? generateLocationData(item)}`,
        location: generateLocationData(item),
    }

    return [option]
}
