import QueryString from 'qs'
import { toast } from 'react-toastify'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { updateFile } from '../../api'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { getUploadPhotoFormData } from '../../utils/general'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    deleteGroundPlan,
    deleteGroundPlanFailure,
    deleteGroundPlanRequest,
    deleteGroundPlanSuccess,
    fetchGroundPlans,
    fetchGroundPlansFailure,
    fetchGroundPlansRequest,
    fetchGroundPlansSuccess,
    proxyGroundPlanEdit,
    proxyGroundPlanEditFailure,
    proxyGroundPlanEditRequest,
    proxyGroundPlanEditSuccess,
    queGroundPlan,
    queGroundPlanFailure,
    queGroundPlanRequest,
    queGroundPlanSuccess,
    syncGroundPlan,
    syncGroundPlanFailure,
    syncGroundPlanRequest,
    syncGroundPlanSuccess,
    updateGroundPlan,
    updateGroundPlanFailure,
    updateGroundPlanRequest,
    updateGroundPlanSuccess,
    updateGroundPlansCount,
    updateGroundPlansPriority,
    uploadGroundPlan,
    uploadGroundPlanFailure,
    uploadGroundPlanRequest,
    uploadGroundPlanSuccess,
    uploadGroundPlans,
} from './actions'
import { selectGroundPlans, selectGroundPlansCount } from './selectors'
import { invalidateQuery } from '../../api/helpers'

////////////////////////////////////////////////////////////////
// TODO: this is obsolete, can be removed later
////////////////////////////////////////////////////////////////
export function* uploadGroundPlanSaga({ payload }) {
    const { formData, uploadUrl } = payload
    const formDataObj = Object.fromEntries(formData.entries())
    delete formDataObj.file // not needed anymore

    yield put(uploadGroundPlanRequest())

    try {
        const response = yield apiRequest(`v1/file/upload/${uploadUrl}`, {
            method: 'POST',
            data: formData,
        })

        yield put(
            uploadGroundPlanSuccess({
                ...formDataObj,
                id: response.data?.[0].id,
                translations: response.data?.[0].translations,
            })
        )
        const groundPlan = response.data?.[0]
        invalidateQuery([groundPlan.parent.toString(), groundPlan.module, 'ground_plan'])

        return { status: 'success' }
    } catch (error) {
        yield put(uploadGroundPlanFailure())

        return { status: 'error' }
    }
}

export function* uploadGroundPlansSaga({ payload }) {
    const { files, entity, photosModule } = payload

    const uploadPromises = []
    const uploadUrl = `${photosModule}/ground_plan`
    const collection = 'ground_plan'
    const filesCount = files.length

    const progressToast = toast.loading(i18n.t('toast.loading.groundPlan', { filesCount }))

    try {
        for (const [_i, file] of files.entries()) {
            let existingCount = yield select(selectGroundPlansCount)

            yield put(updateGroundPlansCount(existingCount + 1))

            const priority = existingCount + 1

            const formData = getUploadPhotoFormData(file, entity, photosModule, collection, priority)

            const { status } = yield call(uploadGroundPlanSaga, {
                payload: { formData, uploadUrl },
            })

            uploadPromises.push({ status })
        }

        const successUploads = uploadPromises.filter((promise) => promise.status === 'success')
        const errorUploads = uploadPromises.filter((promise) => promise.status === 'error')
        const errorCount = errorUploads.length

        if (successUploads.length > 0) {
            toast.update(progressToast, {
                render: i18n.t('toast.loading.groundPlan.success', { filesCount }),
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            })
        }

        if (errorCount > 0) {
            toast.error(i18n.t('toast.loading.groundPlan.error', { errorCount }), {
                autoClose: 3000,
                isLoading: false,
            })
        }
    } catch (error) {
        console.error('Error during groundplans upload:', error)
    }
}

export function* queGroundPlanSaga({ payload }) {
    yield put(queGroundPlanRequest())
    try {
        yield put(queGroundPlanSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
    } catch (error) {
        yield put(queGroundPlanFailure(error))
    }
}

export function* deleteGroundPlanSaga({ payload, meta }) {
    yield put(deleteGroundPlanRequest())

    try {
        yield apiRequest(`v1/file/${payload.id}`, {
            method: 'DELETE',
        })

        yield put(deleteGroundPlanSuccess(payload.id))
        invalidateQuery([payload.entity.id.toString(), payload.groundPlansModule, 'ground_plan'])
        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(deleteGroundPlanFailure(error))
    }
}

export function* syncGroundPlanSaga({ payload, meta }) {
    yield put(syncGroundPlanRequest())

    try {
        yield apiRequest(`v1/file/sync/${payload}`, {
            method: 'PUT',
        })

        yield put(syncGroundPlanSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(syncGroundPlanFailure(error))
    }
}

export function* fetchGroundPlansSaga({ payload, meta }) {
    yield put(fetchGroundPlansRequest())

    const { parentId, ...params } = payload
    const fileUrl = parentId ? `v1/file/parent/${parentId}` : 'v1/file'

    try {
        const response = yield apiRequest(fileUrl, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchGroundPlansSuccess(response.data))

        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        yield put(fetchGroundPlansFailure(error))
    }
}

export function* updateGroundPlanSaga({ payload, meta }) {
    const { module, entity, id, ...data } = payload

    yield put(updateGroundPlanRequest())

    try {
        yield updateFile(id, data)

        yield put(updateGroundPlanSuccess(payload))

        invalidateQuery([entity.id.toString(), module, 'ground_plan'])

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
    } catch (error) {
        yield put(updateGroundPlanFailure(error))
    }
}

export function* proxyGroundPlanEditSaga({ payload, meta }) {
    yield put(proxyGroundPlanEditRequest())

    const groundPlans = yield select(selectGroundPlans)
    const groundPlan = groundPlans.find((photo) => photo.id === payload)

    try {
        const response = yield apiRequest(`v1/file/show/${payload}/original`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache',
            },
        })

        const proxyGroundPlan = {
            ...groundPlan,
            id: payload,
            url: URL.createObjectURL(response.data),
        }

        yield put(proxyGroundPlanEditSuccess(proxyGroundPlan))

        if (meta && meta.callback) {
            meta.callback(proxyGroundPlan)
        }
    } catch (error) {
        yield put(proxyGroundPlanEditFailure(error))
    }
}

export function* updateGroundplansPrioritySaga({ payload }) {
    const groundPlansToDelete = payload.map((str) => Number(str))

    const groundPlans = yield select(selectGroundPlans)

    const updatedData = groundPlans
        .filter((photo) => !groundPlansToDelete.includes(photo.id))
        .map((photo, index) => ({ id: photo.id, priority: index + 1 }))

    for (const groundPlan of updatedData) {
        yield put(updateGroundPlan(groundPlan))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchGroundPlans}`, fetchGroundPlansSaga)
    yield takeEvery(`${uploadGroundPlan}`, uploadGroundPlanSaga)
    yield takeEvery(`${uploadGroundPlans}`, uploadGroundPlansSaga)
    yield takeEvery(`${queGroundPlan}`, queGroundPlanSaga)
    yield takeEvery(`${deleteGroundPlan}`, deleteGroundPlanSaga)
    yield takeEvery(`${syncGroundPlan}`, syncGroundPlanSaga)
    yield takeEvery(`${updateGroundPlan}`, updateGroundPlanSaga)
    yield takeEvery(`${proxyGroundPlanEdit}`, proxyGroundPlanEditSaga)
    yield takeEvery(`${updateGroundPlansPriority}`, updateGroundplansPrioritySaga)
}
