import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import {
    fetchCompanyNotes,
    fetchCompanyNotesFailure,
    fetchCompanyNotesRequest,
    fetchCompanyNotesSuccess,
    createCompanyNote,
    createCompanyNoteFailure,
    createCompanyNoteRequest,
    createCompanyNoteSuccess,
    updateCompanyNoteRequest,
    updateCompanyNoteSuccess,
    updateCompanyNoteFailure,
    deleteCompanyNoteRequest,
    deleteCompanyNoteSuccess,
    deleteCompanyNoteFailure,
    updateCompanyNote,
    deleteCompanyNote,
} from './actions'
import QueryString from 'qs'
export function* fetchCompanyNotesSaga({ payload }) {
    yield put(fetchCompanyNotesRequest())
    const { id, ...params } = payload
    try {
        const response = yield apiRequest(`v1/contact-note/contact/${id}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchCompanyNotesSuccess(response.data))
        // meta();
    } catch (error) {
        yield put(fetchCompanyNotesFailure(error))
    }
}

export function* createCompanyNoteSaga({ payload, meta }) {
    console.log({ meta })
    console.log('[payload', payload)
    yield put(createCompanyNoteRequest())
    try {
        const response = yield apiRequest(`v1/contact-note/${payload['contact_id']}`, {
            method: 'POST',
            data: payload,
        })
        yield put(createCompanyNoteSuccess(response))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        // console.log('error', {error})
        yield put(createCompanyNoteFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* updateCompanyNoteSaga({ payload, meta }) {
    yield put(updateCompanyNoteRequest())
    const { id, ...data } = payload
    try {
        yield apiRequest(`v1/contact-note/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateCompanyNoteSuccess(payload))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateCompanyNoteFailure(error))
        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* deleteCompanyNoteSaga({ payload, meta }) {
    yield put(deleteCompanyNoteRequest())
    // console.log('detele', payload)
    try {
        yield apiRequest(`v1/contact-note/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteCompanyNoteSuccess(payload))
        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteCompanyNoteFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchCompanyNotes}`, fetchCompanyNotesSaga)
    yield takeEvery(`${createCompanyNote}`, createCompanyNoteSaga)
    yield takeEvery(`${updateCompanyNote}`, updateCompanyNoteSaga)
    yield takeEvery(`${deleteCompanyNote}`, deleteCompanyNoteSaga)
}
