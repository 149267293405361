import i18next from 'i18next'

export const contactViewTabs = () => {
    const { t } = i18next
    const tabs = [
        { id: 1, name: 'general', label: t('view.contact.tab.general') },
        { id: 2, name: 'contactProfile', label: t('app.common.contact.demandProfile') },
        { id: 6, name: 'documents', label: t('app.common.documents') },
    ]
    return tabs
}
