import QueryString from 'qs'
import { put, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import {
    fetchSettingsPropertyFlatSubtypes,
    fetchSettingsPropertyFlatSubtypesFailure,
    fetchSettingsPropertyFlatSubtypesRequest,
    fetchSettingsPropertyFlatSubtypesSuccess,
    fetchSettingsPropertyGarageSubtypesFailure,
    fetchSettingsPropertyGarageSubtypesRequest,
    fetchSettingsPropertyGarageSubtypesSuccess,
    fetchSettingsPropertyHouseSubtypes,
    fetchSettingsPropertyHouseSubtypesFailure,
    fetchSettingsPropertyHouseSubtypesRequest,
    fetchSettingsPropertyHouseSubtypesSuccess,
    fetchSettingsPropertyLandSubtypes,
    fetchSettingsPropertyLandSubtypesFailure,
    fetchSettingsPropertyLandSubtypesRequest,
    fetchSettingsPropertyLandSubtypesSuccess,
    fetchSettingsPropertyOfficeSpaceSubtypes,
    fetchSettingsPropertyOfficeSpaceSubtypesFailure,
    fetchSettingsPropertyOfficeSpaceSubtypesRequest,
    fetchSettingsPropertyOfficeSpaceSubtypesSuccess,
} from './actions'
import { REAL_ESTATE_TYPES } from '../../common/constants'

export function* fetchSettingsPropertyFlatSubtypesSaga() {
    yield put(fetchSettingsPropertyFlatSubtypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType`, {
            method: 'GET',
            params: {
                filter: {
                    propertyType: REAL_ESTATE_TYPES.flat,
                },
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchSettingsPropertyFlatSubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchSettingsPropertyFlatSubtypesFailure(error))
    }
}

export function* fetchSettingsPropertyHouseSubtypesSaga() {
    yield put(fetchSettingsPropertyHouseSubtypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType`, {
            method: 'GET',
            params: {
                filter: {
                    propertyType: REAL_ESTATE_TYPES.house,
                },
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchSettingsPropertyHouseSubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchSettingsPropertyHouseSubtypesFailure(error))
    }
}

export function* fetchSettingsPropertyLandSubtypesSaga() {
    yield put(fetchSettingsPropertyLandSubtypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType`, {
            method: 'GET',
            params: {
                filter: {
                    propertyType: REAL_ESTATE_TYPES.land,
                },
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchSettingsPropertyLandSubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchSettingsPropertyLandSubtypesFailure(error))
    }
}

export function* fetchSettingsPropertyOfficeSpaceSubtypesSaga() {
    yield put(fetchSettingsPropertyOfficeSpaceSubtypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType`, {
            method: 'GET',
            params: {
                filter: {
                    propertyType: REAL_ESTATE_TYPES.officeSpace,
                },
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchSettingsPropertyOfficeSpaceSubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchSettingsPropertyOfficeSpaceSubtypesFailure(error))
    }
}

export function* fetchSettingsPropertyGarageSubtypesSaga() {
    yield put(fetchSettingsPropertyGarageSubtypesRequest())
    try {
        // if(availableEntities['propertyType']){
        const response = yield apiRequest(`v1/property/values/propertySubType`, {
            method: 'GET',
            params: {
                filter: {
                    propertyType: REAL_ESTATE_TYPES.garage,
                },
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchSettingsPropertyGarageSubtypesSuccess(response.data))
        // }
    } catch (error) {
        yield put(fetchSettingsPropertyGarageSubtypesFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchSettingsPropertyFlatSubtypes}`, fetchSettingsPropertyFlatSubtypesSaga)
    yield takeLatest(`${fetchSettingsPropertyHouseSubtypes}`, fetchSettingsPropertyHouseSubtypesSaga)
    yield takeLatest(`${fetchSettingsPropertyLandSubtypes}`, fetchSettingsPropertyLandSubtypesSaga)
    yield takeLatest(`${fetchSettingsPropertyOfficeSpaceSubtypes}`, fetchSettingsPropertyOfficeSpaceSubtypesSaga)
}
