import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

//import Components
import Header from './Header'
import Sidebar from './Sidebar'

//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
} from '../store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'
import ModalRoot from '../Components/Modals/ModalRoot'
import { selectModalFetching, selectModalIsOpen, selectModalProps, selectModalType } from '../store/modal'
import { selectCurrentUserChecked } from '../store/auth/selectors'
import Loader from '../Components/Common/Loader'

const Layout = (props) => {
    const [headerClass, setHeaderClass] = useState('')
    const dispatch = useDispatch()
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        modalType,
        modalProps,
        isModalOpen,
        isModalFetching,
        currentUserChecked,
    } = useSelector((state) => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
        leftSidebarImageType: state.Layout.leftSidebarImageType,
        modalType: selectModalType(state),
        modalProps: selectModalProps(state),
        isModalOpen: selectModalIsOpen(state),
        isModalFetching: selectModalFetching(state),
        currentUserChecked: selectCurrentUserChecked(state),
    }))

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
        ) {
            dispatch(changeLeftsidebarViewType(leftSidebarViewType))
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
            dispatch(changeSidebarTheme(leftSidebarType))
            dispatch(changeLayoutMode(layoutModeType))
            dispatch(changeLayoutWidth(layoutWidthType))
            dispatch(changeLayoutPosition(layoutPositionType))
            dispatch(changeTopbarTheme(topbarThemeType))
            dispatch(changeLayout(layoutType))
            dispatch(changeSidebarImageType(leftSidebarImageType))
        }
    }, [
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch,
    ])
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value))
        }
    }

    // class add remove in header
    useEffect(() => {
        window.addEventListener('scroll', scrollNavigation, true)
    })

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop
        if (scrollup > 50) {
            setHeaderClass('topbar-shadow')
        } else {
            setHeaderClass('')
        }
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                {!currentUserChecked ? (
                    <div className="full-page-loader">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <Header headerClass={headerClass} layoutModeType={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} />
                        <Sidebar layoutType={layoutType} />
                        <div className="main-content">
                            {props.children}
                            {/* <Footer /> */}
                        </div>
                    </>
                )}
            </div>

            <ModalRoot
                modalType={modalType}
                isOpen={isModalOpen}
                modalProps={{
                    ...modalProps,
                    isOpen: isModalOpen,
                    isFetching: isModalFetching,
                }}
            />
        </React.Fragment>
    )
}

Layout.propTypes = {
    children: PropTypes.object,
}

export default withRouter(Layout)
