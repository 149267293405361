import dayjs from 'dayjs'
import { getIn, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AccordionBody, AccordionHeader, AccordionItem, Button, Input, Label, UncontrolledAccordion } from 'reactstrap'
import * as Yup from 'yup'
import { ACTIVITY_CATEGORY, CONTACT_TYPE, DETAIL_VIEW } from '../../common/constants'
import { activityFields } from '../../common/forms/activities/fields'
import { activityResponseFields } from '../../common/response/activity'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect'
import { createActivity, deleteActivity, fetchActivities, selectActivities, selectActivitiesFetching, updateActivity } from '../../store/activities'
import { selectActivityValuesTypeFormOptions } from '../../store/activityValues/selectors'
import { updateCurrentSettings } from '../../store/auth/actions'
import { formatDateTime } from '../../utils/general'
import DropDownWithCheckBox from '../ActivitiesViewAccordion/DropDownWithCheckBox/DropDownWithCheckBox'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../AppPermissionsSwitcher/constants/actions'
import DeleteModal from '../Common/DeleteModal'
import Loader from '../Common/Loader'
import { useActivityFormConfig, useActivityFormSelectOptions } from '../Hooks/FormHooks'
import ActivityModal from '../Modals/ActivityModal'

const ActivitiesViewAccordion = ({ parentId, contactType = CONTACT_TYPE.PERSON, module }) => {
    const { fetchingActivities, activities, activityTypes } = useSelector((state) => ({
        activities: selectActivities(state),
        fetchingActivities: selectActivitiesFetching(state),
        activityTypes: selectActivityValuesTypeFormOptions(state),
    }))

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { user, userSettings } = useCurrentUser()
    const [activityDeleteModal, setActivityDeleteModal] = useState(false)
    const [activityModal, setActivityModal] = useState(false)
    const [activityIsEdit, setActivityIsEdit] = useState(false)
    const [selectedActivity, setSelectedActity] = useState(null)
    const [showEditForm, setShowEditForm] = useState(false)
    const [selectedActivityTypes, setSelectedActivityTypes] = useState(
        user && userSettings?.contact_activityTypes ? userSettings.contact_activityTypes : []
    )
    const [acType, setAcType] = useState(null)
    const { inputs, validationConfig } = useActivityFormConfig(selectedActivity, acType)
    const selectOptions = useActivityFormSelectOptions(
        selectedActivity?.id,
        contactType === CONTACT_TYPE.COMPANY ? DETAIL_VIEW.COMPANY : DETAIL_VIEW.CONTACT
    )

    const newActivityTypes = [...activityTypes, { value: 'system', label: 'Sistemske aktivnosti' }]

    useEffect(() => {
        dispatch(
            fetchActivities({
                noLimit: 1,
                contact: parentId,
            })
        )
    }, [dispatch, parentId])

    useEffect(() => {
        if (Object.hasOwn(userSettings, 'contact_activityTypes') === false && activityTypes.length >= 1) {
            const newSelectedActivityType = Object.keys(newActivityTypes).map((key) => newActivityTypes[key].value)
            dispatch(
                updateCurrentSettings({
                    userId: user.id,
                    userSettings: {
                        ...userSettings,
                        contact_activityTypes: newSelectedActivityType,
                    },
                })
            )
        }
    }, [])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationConfig.initialValues ?? {},
        validationSchema: validationConfig.validationSchema ?? Yup.object({}),
        onSubmit: (values) => {
            if (activityIsEdit) {
                const updateEvent = {
                    ...values,
                }

                dispatch(updateActivity(updateEvent))
                setSelectedActity(null)
                validation.resetForm()
            } else {
                const newEvent = {
                    ...values,
                }

                delete newEvent.id
                dispatch(createActivity(newEvent))
                validation.resetForm()
            }
            toggleActivityModal()
        },
    })

    useDidUpdateEffect(() => {
        if (user && user.id) {
            dispatch(
                updateCurrentSettings({
                    userId: user.id,
                    userSettings: {
                        ...userSettings,
                        contact_activityTypes: selectedActivityTypes,
                    },
                })
            )
        }
    }, [selectedActivityTypes])

    const selectedActivityType = getIn(validation.values, activityFields.ACTIVITY_TYPE_ID)
    useEffect(() => {
        if (selectedActivityType !== acType) {
            const foundAc = activityTypes?.find((x) => x.id === parseInt(selectedActivityType))
            if (foundAc) {
                if (foundAc?.activityCategory?.id === ACTIVITY_CATEGORY.TASK && (foundAc?.id === 6 || foundAc?.id === 11)) {
                    setAcType(ACTIVITY_CATEGORY.EVENT)
                } else {
                    setAcType(foundAc?.activityCategory?.id)
                }
            }
        }
    }, [selectedActivityType])

    const submitOtherEvent = () => {
        setShowEditForm(true)
    }

    const handleDeleteActivity = () => {
        if (selectedActivity) {
            dispatch(deleteActivity(selectedActivity.id))
            setActivityDeleteModal(false)
        }
    }

    const onActivityClickUpdate = (activityItem) => {
        setSelectedActity(activities.find((x) => x.id === activityItem.id))
        setActivityIsEdit(true)
        toggleActivityModal()
        // setActivityModal(false);
        // setSelectedActity(null);
        // setActivityIsEdit(false);
        setShowEditForm(true)
    }

    const onActivityClickDelete = (activityItem) => {
        setSelectedActity(activityItem)
        setActivityDeleteModal(true)
    }

    const toggleActivityModal = () => {
        if (activityModal) {
            setActivityModal(false)
            setSelectedActity(null)
            setActivityIsEdit(false)
            setShowEditForm(false)
        } else {
            setActivityModal(true)
        }
    }

    const handleNewActivityButton = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        const currectDate = new Date()
        const currentHour = currectDate.getHours()
        const currentMin = currectDate.getMinutes()
        const currentSec = currectDate.getSeconds()
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec)

        setSelectedActity({
            [activityResponseFields.TITLE]: '',
            ...(contactType === CONTACT_TYPE.PERSON
                ? {
                      [activityResponseFields.CONTACT]: {
                          id: parentId,
                      },
                  }
                : {}),
            ...(contactType === CONTACT_TYPE.COMPANY
                ? {
                      [activityResponseFields.CONTACT_COMPANY]: [
                          {
                              id: parentId,
                          },
                      ],
                  }
                : {}),
            [activityResponseFields.DATE_FROM]: dayjs(modifiedDate).format('YYYY-MM-DD'),
            [activityResponseFields.TIME_FROM]: dayjs(modifiedDate).format('HH:mm'),
            [activityResponseFields.TIME_TO]: dayjs(modifiedDate).add(15, 'minute').format('HH:mm'),
            [activityResponseFields.DATE_TO]: dayjs(modifiedDate).format('YYYY-MM-DD'),
        })

        toggleActivityModal()
    }

    return (
        <>
            <UncontrolledAccordion defaultOpen={['1']} stayOpen>
                <AccordionItem className="mb-4">
                    <div className="position-relative">
                        <AccordionHeader targetId="1">
                            <h5 className="card-title my-2">{t('app.common.activities')}</h5>
                        </AccordionHeader>
                        <div className="accordion-header-actions activities-actions">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleNewActivityButton()
                                }}
                                color="add"
                                className="btn-label"
                            >
                                <i className="mdi mdi-plus label-icon align-middle fs-16 me-2"></i>
                                {t('app.common.addActivity')}
                            </Button>
                            <DropDownWithCheckBox
                                firstItemName={t('accordion.activities.dropdown.placeholder')}
                                activityTypes={newActivityTypes}
                                selectedActivityTypes={selectedActivityTypes}
                                setSelectedActivityTypes={setSelectedActivityTypes}
                            />
                        </div>
                    </div>
                    <AccordionBody accordionId="1">
                        <div className="acitivity-timeline">
                            {fetchingActivities ? (
                                <Loader />
                            ) : (
                                activities
                                    ?.sort((a, b) => b.start - a.start)
                                    .map((activitiyItem) => {
                                        let activityTypeLetter = 'A'
                                        let activityStyle = { background: activitiyItem?.activityType?.color, color: activitiyItem.textColor }
                                        if (activitiyItem.activityType) {
                                            activityTypeLetter = activitiyItem.activityType?.name?.charAt(0)?.toUpperCase()
                                        }

                                        if (activitiyItem.source && activitiyItem.source === 'system') {
                                            activityStyle = { background: '#727A80', color: '#fff' }
                                        }

                                        return (
                                            (selectedActivityTypes.includes(activitiyItem.source) ||
                                                selectedActivityTypes.includes(activitiyItem.activityType.id.toString())) && (
                                                <div className="acitivity-item py-3 d-flex" key={activitiyItem.id}>
                                                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                        <div
                                                            className={`avatar-title ${
                                                                activitiyItem.source && activitiyItem.source !== 'system' ? 'text-primary' : ''
                                                            } rounded-circle`}
                                                            style={activityStyle}
                                                        >
                                                            {' '}
                                                            {activityTypeLetter}{' '}
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                                            <div>
                                                                {activitiyItem.title && <h6 className="mb-1">{activitiyItem.title}</h6>}
                                                                {activitiyItem.dateFrom && (
                                                                    <small className="mb-0 text-secondary">
                                                                        {formatDateTime(activitiyItem.dateFrom)}
                                                                    </small>
                                                                )}
                                                                {activitiyItem.timeFrom && (
                                                                    <small className="mb-0 text-secondary"> | {activitiyItem.timeFrom}</small>
                                                                )}
                                                            </div>
                                                            {activitiyItem.source && activitiyItem.source === 'user' ? (
                                                                <ul className="list-inline hstack justify-content-center">
                                                                    <AppPermissionsSwitcher
                                                                        permission="ModulePermissions"
                                                                        permissionName={'module.activity.edit'}
                                                                        action={action.edit}
                                                                        module={module}
                                                                    >
                                                                        {({ hasModulePermission }) =>
                                                                            hasModulePermission && (
                                                                                <>
                                                                                    <li>
                                                                                        <Button
                                                                                            title={t('button.edit')}
                                                                                            color="link"
                                                                                            className="text-edit"
                                                                                            onClick={() => onActivityClickUpdate(activitiyItem)}
                                                                                        >
                                                                                            <i className="mdi mdi-pencil-outline fs-16"></i>
                                                                                        </Button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Button
                                                                                            title={t('button.delete')}
                                                                                            color="link"
                                                                                            className="text-danger"
                                                                                            onClick={() => {
                                                                                                onActivityClickDelete(activitiyItem)
                                                                                            }}
                                                                                        >
                                                                                            <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                                                        </Button>
                                                                                    </li>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </AppPermissionsSwitcher>
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                        {activitiyItem.project || activitiyItem.property ? (
                                                            <div className="mb-3">
                                                                {activitiyItem.project && activitiyItem.project?.length
                                                                    ? activitiyItem.project.map((projectItem) => (
                                                                          <Link
                                                                              key={projectItem.id}
                                                                              to={`/projects/${projectItem.id}`}
                                                                              className="btn btn-sm btn-outline-warning btn-contact-activity me-2"
                                                                          >
                                                                              ID-{projectItem?.id}
                                                                          </Link>
                                                                      ))
                                                                    : ''}
                                                                {activitiyItem.property && activitiyItem.property?.length
                                                                    ? activitiyItem.property.map((propertyItem) => (
                                                                          <Link
                                                                              key={propertyItem.id}
                                                                              to={`/properties/${propertyItem?.id}`}
                                                                              className="btn btn-sm btn-outline-warning btn-contact-activity me-2"
                                                                          >
                                                                              ID-{propertyItem?.id}
                                                                          </Link>
                                                                      ))
                                                                    : ''}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {activitiyItem.notes && <p className="text-secondary mb-2">{activitiyItem.notes}</p>}
                                                        <div className="mt-3 d-flex align-items-center">
                                                            {activitiyItem.source && activitiyItem.source === 'user' ? (
                                                                <div className="form-check me-3">
                                                                    <Input
                                                                        type="checkbox"
                                                                        className="form-check-input activity-status-check-input"
                                                                        id={activityFields.COMPLETED + activitiyItem.id}
                                                                        name={activityFields.COMPLETED + activitiyItem.id}
                                                                        value={activitiyItem.completed || ''}
                                                                        onChange={(e) => {
                                                                            dispatch(
                                                                                updateActivity({
                                                                                    id: activitiyItem.id,
                                                                                    completed: e.target.checked ? 1 : 0,
                                                                                })
                                                                            )
                                                                        }}
                                                                        checked={activitiyItem?.completed === 1 ? true : false}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        for={activityFields.COMPLETED + activitiyItem.id}
                                                                    >
                                                                        {activitiyItem?.completed ? 'Završeno' : 'Planirano'}{' '}
                                                                    </Label>
                                                                </div>
                                                            ) : null}

                                                            {activitiyItem.activityAgent?.length
                                                                ? activitiyItem.activityAgent.map((acAgent, index) => (
                                                                      <small className="text-secondary" key={acAgent.id}>
                                                                          {acAgent.agent?.firstName ?? ''} {acAgent.agent?.lastName ?? ''}
                                                                          {activitiyItem.activityAgent?.length - 1 !== index ? ' ' : ''}
                                                                      </small>
                                                                  ))
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    })
                            )}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>

            <ActivityModal
                isEdit={activityIsEdit}
                modal={activityModal}
                event={selectedActivity}
                toggle={toggleActivityModal}
                showEditForm={showEditForm}
                inputs={inputs}
                validation={validation}
                submitOtherEvent={submitOtherEvent}
                selectOptions={selectOptions}
                toggleDeleteModal={onActivityClickDelete}
                showEditButton={false}
            />
            <DeleteModal show={activityDeleteModal} onDeleteClick={() => handleDeleteActivity()} onCloseClick={() => setActivityDeleteModal(false)} />
        </>
    )
}

export default ActivitiesViewAccordion
